import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import {
  setSelectedOption,
  getClientDetail,
  emptyVenueDetail,
} from "@store/venues/VenueActions"
import { emptyCart, setSelectedAddress } from "@store/cart/cartActions"
import { removeUser } from "@store/user/UserActions"
import { getTable } from "@store/orderFromTable/OrderFromTableActions"
import { CartType } from "@models/Cart"
import { useBrand } from "@hooks/useBrand"
import MenuModal from "@components/modals/MenuModal"
import ScheduleOrderModal from "@components/modals/ScheduleOrderModal"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { RoundedButtonV2 } from "@modules/VirtualBasket/components/roundedButton"
import { theme } from "config/theme"
import { getMenuList, getMenuDetail } from "@store/menu/MenuActions"
import { setProgrammedOrderDate } from "@store/orderFromTable/OrderFromTableActions"
import PropagateLoader from "react-spinners/PropagateLoader"
import { VenueActionTypes } from "@store/redux/actionTypes"
import {
  getNextAvailableTime,
  getRangesByDays,
} from "@helpers/utilsDateTimeHelpers"
import { DEFAULT_NUMBER_OF_FUTURE_DAYS } from "@helpers/constants"
import { languagesListData } from "@modules/venueDetails/languageData"
import { setPopupCheck } from "@store/venuePopup/VenuePopupActions"
interface Props {
  match?: any
}

const HomePage = ({ match }: Props) => {
  const { primaryColor, restaurantImage } = useBrand()
  const { languageStrings, changeLanguage } = useLanguageHelper()
  const dispatch = useDispatch()
  const history = useHistory()
  const loc = useLocation()
  const payload: any = loc && loc.state
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const restaurantLanguage = restaurant.defaultLanguage || "Spanish"
  const { permalink, tableId } = match.params
  const [open, setOpen] = React.useState(false)
  const [closeArrow, setCloseArrow] = React.useState(true)
  const [menusFiltered, setMenusFiltered] = React.useState([])
  const [openScheduleModal, setOpenScheduleModal] = useState(false)
  const venueList = useSelector(({ venues }: RootState) => venues.venueList)
  const { menus, loading: menuLoading } = useSelector(({ menu }: any) => menu)
  const { visibilityRanges, visibility } = useSelector(
    ({ menu }: RootState) => menu.menuList,
  )
  const { menuList } = useSelector(({ menu }: RootState) => menu)
  const orderFromTable = useSelector(
    ({ orderFromTable }: any) => orderFromTable.orderFromTable,
  )
  const { programmedOrderDate = "" } = useSelector(
    ({ orderFromTable }: any) => orderFromTable,
  )
  const venueSelected = orderFromTable.venueId

  const storedLocale = localStorage.getItem("locale")
  const storedMenuLanguage = localStorage.getItem("menuLanguage")
  const clientId = localStorage.getItem("clientId")
  const storedVenueChange = localStorage.getItem("venueChange")
  // const availableDays = dayOptions(visibility, visibilityTime) || []

  const menuExtrnalUrl = (_menu) => {
    if (_menu && _menu.length === 1 && _menu[0] && _menu[0].menuUrl) {
      window.location.href = `${_menu[0].menuUrl}`
    } else {
      const url = `/${permalink}/menu/${orderFromTable.venueId}/${_menu[0]._id}/${tableId}`

      history.push(
        `/${permalink}/menu/${orderFromTable.venueId}/${_menu[0]._id}/${tableId}`,
      )
    }
  }
  // working on this for selected menu
  useEffect(() => {
    let params = new URLSearchParams(loc.search)
    if (params.get("openmenu")) {
      const menuFiltered = menus.filter((menu) => {
        return (
          menu.venues.includes(orderFromTable.venueId) &&
          menu.availableOnOrderFromTable === true
        )
      })

      if (menuFiltered && menuFiltered.length > 1) {
        setCloseArrow(false)
        setMenusFiltered(menuFiltered)
        setOpen(true)
      }
    }
  }, [menus])
  useEffect(() => {
    const brandLanguage = localStorage.getItem("brandLanguage")
    const defaultLanguage = languagesListData.find(
      (language) => language.name === restaurantLanguage,
    )
    if (defaultLanguage && !brandLanguage && restaurant?._id) {
      localStorage.setItem("brandLanguage", defaultLanguage.locale)
      changeLanguage(defaultLanguage.locale, true)
    } else {
      brandLanguage && changeLanguage(brandLanguage, true)
    }
    const handleBeforeUnload = () => {
      localStorage.removeItem("brandLanguage")
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [restaurant, restaurantLanguage])

  useEffect(() => {
    dispatch(setSelectedOption(""))

    if (tableId) {
      dispatch(setSelectedOption(CartType.ORDER_FROM_TABLE))
      dispatch(getTable(tableId))
    }

    if (
      (storedVenueChange && clientId && storedVenueChange !== clientId) ||
      !storedVenueChange
    ) {
      if (storedLocale) {
        localStorage.removeItem("locale")
      }
      if (storedMenuLanguage) {
        localStorage.removeItem("menuLanguage")
      }
    }

    if (!localStorage.getItem("userId")) {
      dispatch(removeUser())
    }
    dispatch(emptyCart())
    dispatch(emptyVenueDetail())
    dispatch(setSelectedAddress({}))
    dispatch(getClientDetail(permalink))
  }, [permalink, tableId, clientId])
  useEffect(() => {
    if (payload?.isVirtualbasketMenu) {
      navigationPath()
    }
  }, [payload?.isVirtualbasketMenu])
  useEffect(() => {
    if (
      tableId &&
      orderFromTable &&
      Object.keys(orderFromTable).length > 0 &&
      venueList &&
      venueList.length > 0 &&
      menus &&
      menus.length > 0
    ) {
      let type = "orderFromTable"
      const menuFiltered = menus.filter((menu) => {
        return (
          menu.venues.includes(orderFromTable.venueId) &&
          menu.availableOnOrderFromTable === true
        )
      })
      if (menuFiltered && menuFiltered.length === 1) {
        dispatch(
          getMenuDetail(
            menuFiltered?.[0]?._id,
            type,
            programmedOrderDate,
            tableId,
          ),
        )
      }
    }
  }, [menus])
  useEffect(() => {
    dispatch(setPopupCheck(false))
  }, [])
  const venueDetail = useMemo(() => {
    return venueList?.find((item) => item._id === orderFromTable.venueId)
  }, [venueList, orderFromTable?.venueId])
  const showViewAccount =
    venueDetail?.orderType === "4" || venueDetail?.orderType === "5"

  const isNextAvailableDatePresent = useMemo(() => {
    if (Object.keys(menuList).length <= 0) {
      return undefined
    }
    const isMenuVisibilityShowOnly = visibility === "3"
    const daysWithPossibleRanges = getRangesByDays(
      isMenuVisibilityShowOnly ? visibilityRanges : [],
    )
    // to check next available date is present or not if not then take away button should not be display
    return getNextAvailableTime(
      daysWithPossibleRanges,
      venueDetail?.numberOfFutureDays ?? DEFAULT_NUMBER_OF_FUTURE_DAYS,
    )
  }, [visibility, visibilityRanges])
  const showScheduleOrder =
    venueDetail?.orderType === "3" &&
    venueDetail?.isScheduleOrdersEnabled &&
    visibility !== "2" &&
    Object.keys(menuList).length > 0 &&
    isNextAvailableDatePresent

  const navigationPath = () => {
    if (programmedOrderDate) {
      dispatch(setProgrammedOrderDate(""))
    }

    if (
      tableId &&
      orderFromTable &&
      Object.keys(orderFromTable).length > 0 &&
      venueList &&
      venueList.length > 0 &&
      menus &&
      menus.length > 0
    ) {
      const menuFiltered = menus.filter((menu) => {
        return (
          menu.venues.includes(orderFromTable.venueId) &&
          menu.availableOnOrderFromTable === true
        )
      })
      if (menuFiltered && menuFiltered.length === 1) {
        menuExtrnalUrl(menuFiltered)
      } else if (menuFiltered && menuFiltered.length > 1) {
        setCloseArrow(false)
        setMenusFiltered(menuFiltered)
        setOpen(true)
      }
    }
  }

  const handleScheduleOrder = () => {
    setOpenScheduleModal(false)
  }

  // to selected venueDetail from orderFromTable data venueId
  useEffect(() => {
    if (venueDetail && orderFromTable?.venueId) {
      const venueDetail = venueList?.find(
        (item) => item._id === orderFromTable.venueId,
      )
      dispatch({
        type: VenueActionTypes.GET_VENUE_DETAIL_SUCCESS,
        payload: venueDetail,
      })
    }
  }, [venueList, orderFromTable?.venueId])

  // initial component load dispatch which help to reset programmable order date if already set
  useEffect(() => {
    dispatch(setProgrammedOrderDate(null))
  }, [])
  useEffect(() => {
    if (venueDetail && venueDetail._id) {
      dispatch(getMenuList(venueDetail._id, "", true))
    }
  }, [dispatch, venueDetail])

  const isLoading = !venueDetail || menuLoading
  const getTakeAwayButton = () => {
    if (venueDetail?.hideOrderButton === true) {
      return (
        <RoundedButtonV2
          leftIcon={undefined}
          title={`${languageStrings["HOME_PAGE.TAKE_AWAY"]}`}
          style={{
            backgroundColor: primaryColor,
            borderColor: primaryColor,
            color: theme.main.primary,
            textTransform: undefined,
            marginTop: "1.2rem",
          }}
          rightIcon={<></>}
          onClick={() => setOpenScheduleModal(true)}
        />
      )
    }
    return (
      <RoundedButtonV2
        leftIcon={undefined}
        title={`${languageStrings["HOME_PAGE.TAKE_AWAY_ORDER"]}`}
        style={{
          backgroundColor: "transparent",
          borderColor: "white",
          color: "white",
          textTransform: undefined,
          marginTop: "1.2rem",
        }}
        rightIcon={<></>}
        onClick={() => setOpenScheduleModal(true)}
      />
    )
  }
  return (
    <>
      <div
        className={`flex flex-col h-full-screen bg-cover w-full overflow-hidden`}
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(3, 28, 29, 0.06) 0%, rgba(3, 28, 29, 0.03) 11.52%, rgba(3, 28, 29, 0.07) 50.83%, rgba(3, 28, 29, 0.65) 82.71%, #031C1D 100%), ${restaurantImage}`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <PropagateLoader
          color={"white"}
          loading={isLoading}
          cssOverride={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            width: "100%",
            marginBottom: "5rem",
          }}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        {!isLoading && (
          <div className="flex flex-col self-center w-11/12 h-full items-center justify-end pb-10 py-10">
            {showViewAccount ? (
              <RoundedButtonV2
                leftIcon={undefined}
                rightIcon={undefined}
                title={languageStrings["HOME_PAGE.PAY_ORDER"]}
                style={{
                  backgroundColor: primaryColor,
                  borderColor: primaryColor,
                  color: theme.main.primary,
                  textTransform: undefined,
                  marginTop: "1.2rem",
                }}
                onClick={() => {
                  history.push(
                    `/${permalink}/virtualbasketdetails/${tableId}/${venueSelected}`,
                  )
                }}
              />
            ) : null}
            {!venueDetail?.hideOrderButton ? (
              <RoundedButtonV2
                leftIcon={undefined}
                title={
                  showViewAccount || venueDetail?.orderType == "6"
                    ? `${languageStrings["HOME_PAGE.SEE_THE_MENU_VB"]}`
                    : `${languageStrings["HOME_PAGE.SEE_THE_MENU"]}`
                }
                style={{
                  backgroundColor: showViewAccount
                    ? "transparent"
                    : primaryColor,
                  borderColor: showViewAccount ? "white" : primaryColor,
                  color: showViewAccount ? "white" : theme.main.primary,
                  marginTop: "1.2rem",
                  textTransform: undefined,
                }}
                rightIcon={<></>}
                onClick={navigationPath}
              />
            ) : null}
            {showScheduleOrder ? getTakeAwayButton() : null}
          </div>
        )}
      </div>
      <MenuModal
        title={languageStrings["HOME.SELECT_MENU"]}
        open={open}
        menus={menusFiltered}
        close={closeArrow}
        onClose={() => setOpen(!open)}
        onButtonClick={(menuId) => {
          history.push(
            `/${permalink}/menu/${venueSelected}/${menuId}/${tableId}`,
          )
        }}
      />
      {showScheduleOrder ? (
        <ScheduleOrderModal
          open={openScheduleModal}
          close={handleScheduleOrder}
          confirm={navigationPath}
        />
      ) : null}
    </>
  )
}

export default HomePage
