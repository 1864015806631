import React from "react"

import MenuItem from "@modules/menuitem/desktop/MenuItem"
import { Body14, Heading5 } from "@components/Text/Text"
import classes from "../tableCenter.module.css"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { getMultiLanguageTitle } from "@helpers/utilsHelper"
interface Props {
  category: any
  handleMenuItemClick: (item: any) => void
  tabValue: number
  scrollToRef: (ref: any) => void
}

const DesktopCenter = ({
  category,
  handleMenuItemClick,
  tabValue,
  scrollToRef,
}: Props) => {
  const [expanded, setExpanded] = React.useState(null)
  const { languageStrings, menuLanguage } = useLanguageHelper()
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  let content
  if (category) {
    content = category.map((val, idx) => {
      const _multiTitle = val?.multiTitle || []
      const _multiDescription = val?.multiDescription || []
      const catLang = getMultiLanguageTitle(_multiTitle, menuLanguage)
      const catLangDescription = getMultiLanguageTitle(
        _multiDescription,
        menuLanguage,
      )

      return val && val.menuList && val.menuList.length ? (
        <Accordion
          key={idx} // Added a key for each Accordion
          expanded={expanded === val.title}
          onChange={handleChange(val.title)}
        >
          <AccordionSummary
            style={{ backgroundColor: "#f9f9f9" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${idx}-content`} // Make aria-controls dynamic
            id={`panel${idx}-header`} // Make id dynamic
          >
            <div
              className={[classes.header_title, "mt-0"].join(" ")}
              ref={(ref) => {
                if (tabValue === idx && ref) {
                  scrollToRef(ref)
                }
              }}
            >
              <Heading5>{catLang?.title || val.title}</Heading5>
              {val.description && val.title === expanded && (
                <Body14>
                  {catLangDescription?.description || val.description}
                </Body14>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.item_list}>
              <ul className={"m-0"}>
                {val.menuList.map((item, itemIdx) => {
                  const _multiTitle = item?.multiTitle || []
                  const _multiDescription = item?.multiDescription || []
                  const catLang = getMultiLanguageTitle(
                    _multiTitle,
                    menuLanguage,
                  )
                  const catLangDescription = getMultiLanguageTitle(
                    _multiDescription,
                    menuLanguage,
                  )
                  return (
                    <li className={"mb-1"} key={itemIdx}>
                      {" "}
                      {/* Added a key for each item */}
                      <MenuItem
                        item={item}
                        title={catLang?.title || item.title}
                        subtitle={
                          catLangDescription?.description || item.description
                        }
                        price={item.price}
                        image={item.image}
                        extraCategories={item.extraCategories}
                        onClick={() => handleMenuItemClick(item)}
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null
    })
  } else {
    content = null
  }

  return content
}

export default DesktopCenter
