import { axiosInstance } from "@api/axios"
import { SocialPaymentEnum } from "@helpers/utilsHelper"
import { LocationState } from "@models/Location"
import { socialPayVirtualBasket } from "@store/orderFromTable/OrderFromTableActions"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"

const FREEDOMPAY_URL = process.env.REACT_APP_FREEDOMPAY_URL
const useFreedompay = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation<LocationState>()
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const {
    orderFromTable,
    programmedOrderDate = "",
    orderClientName = "",
    orderClientAddress = "",
  } = useSelector(({ orderFromTable }: RootState) => orderFromTable)
  const { loyaltyClient } = useSelector(({ user }: RootState) => user)
  const isScheduledOrder =
    programmedOrderDate && venueDetail && venueDetail.isScheduleOrdersEnabled

  const routeState = location.state ? location.state : {}
  const { payload = null, tableId = "", amount = 0, venueId = "" } = routeState

  const createPaymentKeyData = useCallback(() => {
    return {
      paymentKeys: [],
      attributes: {},
      paymentType: "",
    }
  }, [])
  const [freedompayLoading, setFreedompayLoading] = useState(false)
  const [paymentKeyData, setPaymentKeyData] = useState(createPaymentKeyData())
  const [freedomPayValidation, setFreedomPayValidation] = useState({
    CardNumber: true,
    PostalCode: true,
    ExpirationDate: true,
    SecurityCode: true,
  })
  const [isApplePaySupported, setIsApplePaySupported] = useState(false)

  useEffect(() => {
    const addHpcScript = () => {
      if (
        !document.querySelector(
          `script[src="${FREEDOMPAY_URL}/api/v1.5/cdn/hpc_min.js"]`,
        )
      ) {
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.src = `${FREEDOMPAY_URL}/api/v1.5/cdn/hpc_min.js`
        script.onload = () => {
          const wind = window as any
          if (
            wind?.FreedomPay?.Apm.ApplePay.isSupported() &&
            wind?.FreedomPay?.Apm.ApplePay.canMakePayments()
          ) {
            setIsApplePaySupported(true)
          }
        }
        document.body.appendChild(script)
      }
    }

    addHpcScript()
  }, [])

  const getFreedompayPaymentKeys = () => {
    const iframe: any = document.getElementById("hpc--card-frame")
    if (iframe && iframe.contentWindow) {
      // Send postMessage to the freedompay iframe
      iframe.contentWindow.postMessage("getPaymentKeys", FREEDOMPAY_URL)
    }
  }

  const freedomPaySocialPayment = async (
    tip,
    freedompayPaymentKey,
    paymentType,
  ) => {
    const clientId = restaurant && restaurant._id ? restaurant._id : ""
    const frontRestOrderId =
      orderFromTable &&
      orderFromTable.frontRestData &&
      orderFromTable.frontRestData.orderId
        ? orderFromTable.frontRestData.orderId
        : ""

    const freedomPaymentGoogleSessionKey = localStorage.getItem(
      "freedomPaymentGoogleSessionKey",
    )
    const freedomPaymentAppleSessionKey = localStorage.getItem(
      "freedomPaymentAppleSessionKey",
    )
    // freedompay social payment here
    const body = {
      tableId,
      amount,
      clientId,
      cartItems: payload ? payload : null,
      frontRestOrderId,
      tip,
      squarePaymentToken: null,
      squareVerificationToken: null,
      freedompayPaymentKey,
      freedompaySessionKey:
        paymentType === SocialPaymentEnum.APPLE
          ? freedomPaymentAppleSessionKey
          : freedomPaymentGoogleSessionKey,
    }
    try {
      setFreedompayLoading(true)
      const url = "order/table/socialPayment"
      const { data } = await axiosInstance.post(url, body)
      if (data && data.data) {
        dispatch(
          socialPayVirtualBasket(
            tableId,
            amount,
            venueId,
            routeState,
            history,
            null, // stripe null as this is for freedompay
            null, // stripe client_secret null
            undefined, // event null
            clientId,
            payload,
            data.data.paymentIntent,
            frontRestOrderId,
            tip,
            loyaltyClient,
            orderClientName,
            orderClientAddress,
            isScheduledOrder,
            false, // isSquarePayment
            true, // isFreedompay
          ),
        )
      }
    } catch (error) {
      let _msg = "Algo salió mal"
      if (
        typeof error === "object" &&
        error !== null &&
        "response" in error &&
        typeof (error as any).response === "object" &&
        "data" in (error as any).response &&
        typeof (error as any).response.data === "object" &&
        "message" in (error as any).response.data
      ) {
        _msg = (error as any).response.data.message
      }

      toast.error(_msg)
    } finally {
      setFreedompayLoading(false)
    }
  }

  const handleErrors = (data, setGeneralLoading) => {
    setGeneralLoading(false)
    data.errors.forEach(function (error) {
      if (
        error.message ===
          "Unable to generate payment key: Reached maximum attempts." ||
        error.emittedBy === "RequestPaymentKey"
      ) {
        toast.error("Payment key already used. Requesting new one")
        // reloading the payment key as the previous one is used
        window.location.reload()
      }
    })
  }

  function handleValidityChange(data) {
    setFreedomPayValidation((prev) => ({
      ...prev,
      [data?.emittedBy]: !data?.isValid,
    }))
  }

  function getAttributeValue(data, key) {
    const attribute = data.find((attr) => attr.Key === key)
    return attribute ? attribute.Value : null
  }

  const submitPayment = (data, setState, onPay) => {
    const updatedPaymentKeyData = {
      ...paymentKeyData,
      attributes: data.attributes,
      paymentKeys: data.paymentKeys,
      paymentType: data.paymentType,
    }
    if (data.paymentType === "Card") {
      const expiry = getAttributeValue(data.attributes, "ExpirationDate")
      setState((prev) => ({
        ...prev,
        number: getAttributeValue(data.attributes, "MaskedCardNumber"),
        month: expiry?.slice(0, 2),
        year: expiry?.slice(3, 5),
      }))
    }

    setPaymentKeyData(updatedPaymentKeyData)
    onPay(updatedPaymentKeyData.paymentKeys.pop(), data.paymentType)
  }

  function handleMessage(
    e,
    setGeneralLoading,
    setState,
    onPay,
    isFreedompay3dRef,
  ) {
    if (e.origin !== FREEDOMPAY_URL && e.origin.indexOf("10.10") < 0) {
      return
    }

    var message = e.data,
      data = message.data

    switch (message.type) {
      case 1:
        handleErrors(data, setGeneralLoading)
        break
      case 3:
        submitPayment(data, setState, onPay)
        break
      case 4:
        handleValidityChange(data)
        break
      case 16:
        isFreedompay3dRef.current = data.Validated
        break
    }
  }

  return {
    freedompayLoading,
    setFreedompayLoading,
    paymentKeyData,
    setPaymentKeyData,
    freedomPayValidation,
    setFreedomPayValidation,
    isApplePaySupported,
    setIsApplePaySupported,
    getFreedompayPaymentKeys,
    freedomPaySocialPayment,
    handleErrors,
    handleValidityChange,
    submitPayment,
    handleMessage,
  }
}

export default useFreedompay
