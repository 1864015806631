import React, { useEffect } from "react"
import { toast } from "react-toastify"
import { axiosInstance } from "@api/axios"
// 4000 0000 0000 2503
const FreedomPay = ({ setFreedompayLoading, amount }) => {
  const cardPaymentRef = React.useRef(null)

  const getPaymentFrame = async () => {
    const cardPayment = cardPaymentRef.current
    cardPayment.innerHTML = ""
    try {
      setFreedompayLoading(true)
      const response = await axiosInstance.get(
        `freedompay/iframe?paymentType=Card&amount=${amount}`,
      )
      cardPayment.innerHTML += response.data?.data?.iframe
      const sessionKeyHeader = response?.data?.data?.sessionKey
      localStorage.setItem("freedomPaymentSessionKey", sessionKeyHeader)
      const iframe = cardPayment.querySelector("iframe")

      if (iframe && iframe?.id === "hpc--card-frame") {
        iframe.onload = () => {
          setFreedompayLoading(false)
        }
      } else {
        toast.error("Failed to load payment frame. Reload the page")
        setFreedompayLoading(false)
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("401 Unauthorized: Credentials are invalid.")
      } else if (error.response?.data?.Message) {
        toast.error(JSON.parse(error.response.data.Message)[0])
      } else {
        toast.error("An unknown error occurred. Please try again later.")
      }
    }
  }

  useEffect(() => {
    getPaymentFrame()
  }, [])

  return <div ref={cardPaymentRef} id="placeholderId" />
}

export default FreedomPay
