export default {
  "ADDRESS.CHOOSE_SHOPPING_ADDRESS": "选择送货地址",
  "ADDRESS.ADD_ADDRESS": "添加地址",
  "ADDRESS.CHOOSE_PAYMENT_METHOD": "选择支付方式",
  "ADDRESS.ADD_CARD": "添加卡片",
  "ADDRESS.PAY_BY_CARD": "银行卡支付",
  "BASKET.ENTER_YOUR_ADDRESS": "输入你的地址",
  "BASKET.ADDRESS": "地址",
  "BASKET.POSTAL_CODE": "邮编",
  "BASKET.CITY": "城市",
  "BASKET.SPECIAL_DETAIL": "详情（可选）",
  "BASKET.SELECT_ADDRESS": "选择地址",
  "BASKET.CREATE": "创建地址",
  "BASKET.NAME": "名称",
  "BASKET.MY_ORDER": "我的订单",
  "BASKET.DO_YOU_HAVE_A_DISCOUNT_CODE": "你有折扣码吗？",
  "BASKET.DISCOUNT_CODE": "折扣码",
  "BASKET.APPLY": "应用",
  "BASKET.THE_DISCOUNT_CODE_IS_CURRECT_BUT_MINIMUM_AMOUNT_TO_REQUEST_IS_IT":
    " 折扣代码是正确的，但最低金额必须是",
  "BASKET.COUPON_NOT_AVAILABLE_FOR_THIS_TYPE_OF_ORDER":
    "此类型订单不可用优惠券。",
  "BASKET.THE_DISCOUNT_CODE_DOES_NOT_EXIST": "折扣码不存在",
  "BASKET.DISCOUNT_CODE_ALREADY_USER": "折扣码已使用",
  "BASKET.HOME_DELIVERY": "送货上门",
  "BASKET.SHOPPING_ADDRESS": "送货地址",
  "BASKET.CHOOSE_ADDRESS": "选择地址",
  "BASKET.ADD_NEW_ADDRESS": "添加新地址",
  "BASKET.MINIMUM_ORDER": "最低订单",
  "BASKET.MAKE_AN_ORDER": "下订单",
  "BASKET.MY_ADDRESS": "我的地址",
  "BASKET.PAYMENT_METHOD": "支付方式",
  "BASKET.ADD_NEW_CARD": "添加新卡",
  "BASKET.N_ORDER": "订单号",
  "BASKET.EARRING": "待处理",
  "FORGET_PASSWORD.PASSWORD_MUST_BE_MORE_THEN_6_CHARACTERS":
    "密码必须超过6个字符",
  "FORGET_PASSWORD.CONFIRM_PASSWORD_MUST_BE_MORE_THEN_6_CHARACTERS":
    "确认密码必须超过6个字符",
  "FORGET_PASSWORD.PASSWORD_AND_CONFIRM_PASSWORD_MUST_BE_THE_SAME":
    "密码和确认密码必须相同",

  "FORGET_PASSWORD.RESET_YOUR_PASSWORD": "重置你的密码",
  "FORGET_PASSWORD.THE_TOKEN_HAS_EXPIRED": "令牌已过期",
  "FORGET_PASSWORD.NEW_PASSWORD": "新密码",
  "FORGET_PASSWORD.CONFIRM_PASSWORD": "确认密码",
  "FORGET_PASSWORD.LOGIN": "登录",
  "HOME.THERE_ARE_NO_MENUES_FOR_THIS_RESTAURANT": "该餐厅没有菜单",
  "HOME.SELECT_MENU": "选择菜单",
  "LOGIN.PLEASE_ENTER_A_VALID_EMAIL": "请输入有效的电子邮件",
  "LOGIN.EMAIL": "电子邮件",
  "LOGIN.PASSWORD": "密码",
  "LOGIN.RECOVER_PASSWORD": "找回密码?",
  "LOGIN.YOU_DON'T_HAVE_AN_ACCOUNT_EASILY_CREATE_IT":
    "没有账户？轻松创建一个吧",
  "LOGIN.SIGN_IN_WITH_GOOGLE": "用谷歌登录",
  "LOGIN.ARE_YOU_SURE_YOU_WANT_TO_RECOVER_YOUR_PASSWORD":
    "你确定要找回密码吗？",
  "MENU_ITEM.ADD_TO_ORDER": "添加到订单",
  "PAYMENT.PAYMENT_METHOD": "支付方式",
  "PAYMENT.NAME": "姓名",
  "PAYMENT.NUMBER": "号码",
  "PAYMENT.CVC": "CVC",
  "PAYMENT.MAKE_PAYMENT": "进行支付",
  "PAYMENT.TIP_ERROR": "小费金额不能小于0",
  "PROFILE.PROFILE": "个人资料",
  "PROFILE.EDIT_PROFILE": "编辑资料",
  "PROFILE.CHANGE_PASSWORD": "更改密码",
  "PROFILE.MY_ORDERS": "我的订单",
  "PROFILE.LEAVE": "退出",
  "PROFILE.TO_CALCEL_AN_ORDER": "取消订单",
  "PROFILE.PASSWORD": "密码",
  "PROFILE.PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS": "密码必须超过5个字符",
  "PROFILE.CONFIRM_PASSWORD": "确认密码",
  "PROFILE.CONFIRM_PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS":
    "确认密码必须超过5个字符",
  "PROFILE.MY_ORDER": "我的订单",
  "PROFILE.GRAND_TOTAL": "总计",
  "PROFILE.SHIPPING_ADDRESS": "送货地址",
  "PROFILE.ORDER_PLACED": "订单已下",
  "PROFILE.CONFIRMED": "已确认",
  "PROFILE.IN_KITCHEN": "在厨房中",

  "PROFILE.IN_SHOPPING": "正在送货",
  "PROFILE.PICK_UP_LIST": "待取清单",
  "PROFILE.COMPLETED": "已完成",
  "PROFILE.TO_CANCEL_AN_ORDER": "取消订单",
  "PROFILE.NAME": "名字",
  "PROFILE.SURNAME": "姓氏",
  "PROFILE.DATE_OF_BIRTH": "出生日期",
  "PROFILE.PHONE": "电话",
  "PROFILE.SAVE_CHANGES": "保存更改",
  "SIGNUP.PLEASE_ENTER_A_VALID_EMAIL": "请输入有效的电子邮件",
  "SIGNUP.THE_PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS": "密码必须超过5个字符",
  "SIGNUP.CONFIRM": "确认密码必须超过5个字符",
  "SIGNUP.PASSWORD_AND_CONFIRM_PASSWORD_MUST_BE_THE_SAME":
    "密码和确认密码必须相同",
  "SIGNUP.NAME": "名字",
  "SIGNUP.SURNAME": "姓氏",
  "SIGNUP.EMAIL": "电子邮件",
  "SIGNUP.PASSWORD": "密码",
  "SIGNUP.CONFIRM_PASSWORD": "确认密码",
  "SIGNUP.I_ACCEPT_THE_TERMS_AND_CONDITIN": "我接受条款和条件",
  "SIGNUP.DO_YOU_ALREADY_HAVE_AN_ACCOUNT_TRY_TO_ACCESS_WITH_IT":
    "已有账户？试着登录",
  "SOCUAL_PAYMENT.ENTER_EMAIL_ADDRESS": "输入电子邮件地址",
  "VENUE_DETAIL.THERE_ARE_NO_MENUES_FOR_THIS_RESTAURANT": "该餐厅没有菜单",
  "VENUE_DETAIL.HOW_DO_YOU_WANT_TO_ORDER": "你想如何下单？",
  "VENUE_DETAIL.SELECT_MENU": "选择菜单",
  "VIRTUAL_BASKET.YOUR_GONNA_PAY": "你将支付",
  "VIRTUAL_BASKET.NAME": "名字",
  "VIRTUAL_BASKET.FULL_NAME": "姓名",
  "VIRTUAL_BASKET.NUMBER": "号码",
  "VIRTUAL_BASKET.CVC": "CVC",
  "VIRTUAL_BASKET.SUCCESS_PAYMENT_RECEIVED": "付款成功！",

  "VIRTUAL_BASKET.PAYMENT_PROCESSING_WE'LL_UPDATE_YOU_WHEN_PAYMENT_IS_RECEIVED":
    "正在处理支付。当支付成功时我们会通知你。",
  "VIRTUAL_BASKET.PAYMENT_FAIL.PLEASETRY_ANOTHER_PAYMENT_METHOD":
    "支付失败。请尝试另一种支付方式。",
  "VIRTUAL_BASKET.SOMETHING_WENT_WRONG": "出现错误",
  "VIRTUAL_BASKET.VIRTUAL_BASKET_PAYMENT": "虚拟购物车支付",
  "VIRTUAL_BASKET.SEE_THE_MENU": "查看菜单",
  "VIRTUAL_BASKET.PAY_THE_BILL": "支付账单",
  "VIRTUAL_BASKET.PAYMENT_METHOD": "支付方式",
  "VIRTUAL_BASKET.CHOOSE_CARD": "选择银行卡",
  "VIRTUAL_BASKET.ADD_NEW_CARD": "添加新卡",
  "VIRTUAL_BASKET.VIEW_THE_ACCOUNT": "查看账单",
  "VIRTUAL_BASKET.MY_ORDER": "我的订单",
  "VIRTUAL_BASKET.BASE_PRICE": "基础价格",
  "VIRTUAL_BASKET.AMOUNT_PAID": "已付金额",
  "VIRTUAL_BASKET.PENDING_AMOUNT": "待付金额",
  "VIRTUAL_BASKET.DISCOUNT": "折扣",
  "GENERAL.COMMENTS": "评论",
  "GENERAL.ADD": "添加",
  "GENERAL.TOTAL": "总计",
  "GENERAL.SAVE": "保存",
  "GENERAL.ACCESS": "访问",
  "GENERAL.EITHER": "或者",
  "GENERAL.CONFIRM": "确认",
  "GENERAL.CANCEL": "取消",
  "GENERAL.SEND": "发送",
  "GENERAL.PAY": "支付",
  "GENERAL.DISCOUNT": "折扣",
  "BASKET.ENTER_NAME": "输入名字",
  "BASKET.FOR_EXAMPLE_HOME_WORK": " Por Ejemplo: Casa Trabajo ",
  "BASKET.DISCOUNT_CODE_ALREADY_USED": "折扣码已使用",
  "BASKET.DISCOUNT": "折扣",
  "BASKET.BASE_PRICE": "基础价格",
  "BASKET.CHOOSE_CARD": "选择卡片",
  "PROFILE.DISCOUNT": "折扣",
  "PROFILE.SORRY_YOUR_ORDER_HAS_BEEN_CANCELED": "抱歉，你的订单已被取消",
  "PROFILE.N_ORDER": "订单号",
  "PROFILE.ORDER_DETAIL": "订单详情",
  "SIGN_UP.SIGN_UP": "注册",
  "SOCIAL_PAYMENTS.PAYMENT_SUCCEEDED": "支付成功",

  "SOCIAL_PAYMENTS.YOUR_PAYMENT_IS_PROCESSING": "您的支付正在处理",
  "SOCIAL_PAYMENTS.YOUR_PAYMENTS_ARE_NOT_SUCCESFUL_PLEASE_TRY_AGAIN":
    "您的支付未成功，请重试",
  "SOCIAL_PAYMENTS.SOMETHING_WENT_WRONG": "出了点问题",
  "SOCIAL_PAYMENTS.UNEXPETED_ERROR_OCCURED": "发生了意外错误",
  "SOCIAL_PAYMENTS.ENTER_EMAIL_ADDRESS": "请输入您的电子邮件",
  "SOCIAL_PAYMENTS.PAY_NOW": "立即支付",
  "VIRTUAL_BASKET.GRAND_TOTAL": "总计",
  "ADDRESS_CARD.OUT_OF_RANGE": "超出范围",
  "CUSTOM_LOADER.CHARGING": "加载中...",
  "MOBILE_DETAIL_CARD.OUT_OF_RANGE": "超出范围",
  "ADD_CARD_MODEL.NAME": "姓名",
  "ADD_CARD_MODEL.NUMBER": "号码",
  "ADD_CARD_MODEL.CVC": "CVC",
  "ADD_PHONE_NUMBER_MODEL.PLEASE_ADD_YOUR_PHONE": "请添加您的电话！",
  "ADD_PHONE_NUMBER_MODEL.LEAVE_YOUR_TELEPHONE_NUMBER_TO_CONTACT_YOU_REGARDING_THE ORDER":
    "留下您的电话号码以便我们联系您",
  "ADD_PHONE_NUMBER_MODEL.PHOME ORDER": "电话",
  "ADDRESS_DETAIL_MODEL.SHOPPING_ADDRESS": "送货地址",
  "ADDRESS_DETAIL_MODEL.NAME": "姓名",
  "ADDRESS_DETAIL_MODEL.HOME_OFFICE": "家，办公室...",
  "ADDRESS_DETAIL_MODEL.STREET": "街道",
  "ADDRESS_DETAIL_MODEL.POSTAL_CADE": "邮编",
  "ADDRESS_DETAIL_MODEL.CITY": "城市",
  "ADDRESS_DETAIL_MODEL.TO_ACCESS_PRESS_THE_RED_BELL": "要进入，请按红色门铃",
  "ADDRESS_DETAIL_MODEL.FOLLOWING": "下一步",
  "ADDRESS_MAP_MODEL.CREATE_ADDRESS": "创建地址",
  "INVOICE_MODEL.DO_YOU_NEED_AN_INVOICE_ENTER_EMAIL_TO_RECEIVE_IT":
    "需要发票吗？输入您的邮箱",
  "INVOICE_MODEL.EMAIL": "电子邮件",
  "INVOICE_MODEL.REQUEST_INVOICE": "请求发票",
  "SPLIT_BILL_MODEL.AMONT_IS_MANDATORY": "金额是必须的",
  "SPLIT_BILL_MODEL.THE_AMOUNT_EXCEEDS_THE_AMOUNT_TO_BE_PAID":
    "金额超过应支付的金额",
  "SPLIT_BILL_MODEL.PAYMENT": "支付",
  "SPLIT_BILL_MODEL.PAY_FULL_AMOUNT": "支付全额",

  "SPLIT_BILL_MODEL.PARTIAL_PAYMENY": "支付部分金额",
  "SPLIT_BILL_MODEL.ENTER_AMOUNT_TO_PAY": "输入支付金额",
  "SPLIT_BILL_MODEL.DECIMALS": "最多输入两位小数",
  "HEADER.ORDER_COMPLETED": "订单完成！",
  "ITEMS_DETAILS.ORDER": "订单",
  "ITEMS_DETAILS.BASIC_PRICE": "基础价格",
  "ITEMS_DETAILS.SHOPPING_PRICE": "运费",
  "ITEMS_DETAILS.DISCOUNT": "折扣",
  "ITEMS_DETAILS.GRAND_TOTAL": "总计",
  "MOBILE_ORDER_CONFIRMED.CONGRATULATION": "恭喜！",
  "MOBILE_ORDER_CONFIRMED.YOUR_ORDER_HAS_BEEN_SENT": "您的订单已发送",
  "MOBILE_ORDER_CONFIRMED.CONTINUE": "继续",
  "MOBILE_ORDER_CONFIRMED.POWERED_BY": "power by",
  "ORDER_CONFRMED_PAGE.THANK_YOU": "谢谢！",
  "ORDER_CONFRMED_PAGE.CONTINUE": "继续",
  "RADIO_GROUP_CARD.CASH": "现金",
  "TABLE_CENTER.MENU": "菜单",
  "TABLE_LEFT.CATEGORIES": "分类",
  "TABLE_LEFT.THIS_MENU_IS_A_DEMO_GET_YOURS_FOR_FREE":
    "此菜单为演示，免费获取您的菜单",
  "TABLE_LEFT.SIGN_UP_FOR_FREE": "免费注册",
  "TABLE_RIGHT.YOUR_ORDER": "您的订单",
  "TABLE_RIGHT.MAKE_AN_ORDER": "下订单",
  "TABLE_RIGHT.DO_YOU_A_DISCOUNT_CODE": "您有折扣码吗？",
  "TABLE_RIGHT.DISCOUNT_CODE": "折扣码",
  "TABLE_RIGHT.APPLY_TEXT": "应用",
  "TABLE_RIGHT.THE_DISCOUNT_CODE_DOSE_NOT_EXIST": "折扣码不存在",
  "TABLE_RIGHT.THE_DISCOUNT_CODE_ALREADY_USE": "折扣码已使用",
  "TABLE_RIGHT.MINIMUM_ORDER": "最低订单",
  "TABLE_RIGHT.HOME_DELIVERY": "送货上门",
  "TABLE_RIGHT.TOTAL": "总计",
  "TABLE_RIGHT.SUB_TOTAL": "小计",
  "TABLE_RIGHT.THE_DISCOUNT_CODE_IS_CORRECT_BUT_THE_MINIMUM_AMOUNT_TO_REQUEST_IS_IT":
    "折扣码正确，但最低申请金额为",
  "TABLE_RIGHT.COUPONS_NOT_AVAILABLE_FOR_THIS_ORDER_TYPE":
    "此订单类型不可用优惠券",
  "TABLE_RIGHT.DISCOUNT": "折扣",

  "VENUE_ORDER.HOW_DO_WANT_YOUR_ORDER": "您想要如何下单？",
  "NOT_FOUND_PAGE.RESTORENT_NOT_FOUND": "未找到餐厅",
  SEE_ORDERS: "查看订单",
  ORDER_PREPARATION_TIMING_HEADER_MODEL: "请输入邮箱以查看订单准备时间",
  ORDER_PREPARATION_BUTTON: "收据",
  HOW_DO_YOU_WANT_TO_BE_NOTIFIED: "您希望如何收到通知？",
  GENERAL_AUTOSERVICE: "自助服务",
  I_DONT_WANT_TO_BE_NOTIFIED: "我不想被通知",
  ENTER_YOUR_EMAIL_ADDRESS: "请输入您的邮箱地址",
  EMAIL_ADDRESS: "邮箱地址",
  NOTIFY_ME: "通知我",
  ENTER_YOUR_PHONE_NUMBER: "请输入您的电话号码",
  PHONE_NUMBER: "电话号码",
  ORDER_REF: "订单参考号",
  TOTAL_AMOUNT: "总金额",
  CREATING_TIME: "创建时间",
  "GENERAL:ORDER_IN_PROGRESS": "订单进行中",
  "GENERAL:ORDER_IN_PROGRESS_PARGRAPH": "点击此处查看待处理订单详情",
  RESTAURANT_BUSY: "餐厅现在很忙",
  STATUS: "状态",
  PARTIAL_DELIVERY: "部分交付",
  IN_PROGRESS: "进行中",
  "ORDER_STATUS.DELIVERED": "已完成",
  NO_TABLE_ID_ERROR: "我们无法识别您的桌子。请再次扫描二维码",
  TICKET_NO: "票号",
  MINIMUM_ORDER: "最低订单金额",
  SELECT_PAYMENT_METHOD: "选择支付方式",
  EMPTY_NAME_ERROR: "请输入您的姓名",
  CONTINUE_TO_PAY: "继续支付",
  SECURE_PAYMENTS: "您的支付已加密",
  INVALID_CARD_NUMBER: "卡号无效",
  INVALID_EXPIRY_DATE: "到期日期无效",
  MONTH_RANGE: "到期月份必须在01到12之间",
  YEAR_RANGE: "到期年份不能是过去的年份",
  DATE_RANGE: "到期日期不能是过去的日期",
  INVALID_CVV: "安全码无效",
  CREDIT_CARD: "信用卡",
  ADD_TIP: "添加小费",
  TIP: "小费",

  TIP_TITLE: "用小费表达感谢",
  OTHER: "其他",
  TIP_REMOVE: "移除小费",
  ENTER_AMOUNT: "输入金额",
  TERMS_CONDITION: "继续即表示我接受",
  TERMS_CONDITION2: "条款和条件及隐私政策。",
  BUSY_TABLE: "此时此桌无法接受订单。",
  BUSY_VENUE: "餐厅暂时不接受订单。",
  INVALID_EMAIL_ERROR: "请检查提供的信息",
  GENERAL_CLOSE: "关闭",
  PAY_IN_CASH: "现金支付",
  CASH_MAKE_ORDER: "下订单",
  TOTAL_WITHOUT_PROMO: "无促销总计",
  EMPTY_CART: "购物车中没有订单",
  DOWNLOAD_QR: "下载二维码",
  MENU_HEADER1: " Este es un Menú para el ",
  MENU_HEADER2: " que es el mejor de la ciudad。",
  GENERAL_ITEMS: "产品",
  GENERAL_ITEM: "产品",
  GENERAL_CASH: "现金",
  GENERAL_VENUE_MESSAGE: "此门店将在订单准备好后通知您",
  NOTIFIED_VIA_EMAIL: "通过电子邮件通知",
  SEND_EMAIL_NOTIFICATION: "我们会将通知发送到此邮箱",
  SEND_WHATSAPP_NOTIFICATION: "我们会将通知发送到此 WhatsApp",
  SEND_SMS_NOTIFICATION: "我们会将通知发送到此电话号码",
  NOTIFIED_VIA_WHATSAPP: "通过 WhatsApp 通知",
  NOTIFIED_VIA_SMS: "通过短信通知",
  GENERAL_WHATSAPP: "WhatsApp",
  GENERAL_SMS: "SMS",
  NOTIFICATION_METHOD: "通知方式",
  CHOOSE_PAYMENT_METHOD: "选择支付方式",
  PAY_WITH: "支付方式",
  CARD_NUMBER: "卡号",
  EXPIRY_DATE: "到期日期",
  CVC_CODE: "CVC",
  NEED_RECEIPT: "需要收据吗？",
  NEED_RECEIPT_SUB: "请输入您的邮箱以接收收据",

  POST_REVIEW: "留下评价",
  ORDER_LIST: "订单列表",
  PAY_ORDER: "支付订单",
  GENERAL_POPULAR: "流行",
  GENERAL_FOOD: "食物",
  GENERAL_DRINK: "饮料",
  SELECT_RATING: "您的意见对我们很重要",
  WRITE_REVIEW: "写评论",
  WITHOUT_REVIEW: "继续不写评论",
  ORDER_CONFIRMATION: "订单确认",
  GENERAL_PEDIR: "点单",
  GENERAL_PAGAR: "支付",
  RATING_SUBHEADING: "为我们的场所评分",
  "HOME_PAGE.SEE_THE_MENU": "点单",
  "HOME_PAGE.SEE_THE_MENU_VB": "查看菜单",
  "HOME_PAGE.PAY_ORDER": "支付",
  "HOME_PAGE.SCHEDULE_ORDER": "安排订单",
  "HOME_PAGE.TAKE_AWAY": "外带",
  "HOME_PAGE.TAKE_AWAY_ORDER": "安排外带订单",
  SECURE_STORE_CARD: "加密并安全地存储我的卡信息以便将来使用。",
  VIEW_MENU: "查看菜单",
  USE_CARD: "使用银行卡",
  PICKUP_TIME: "取货时间",
  PICKUP_TIME_TODAY: "今天",
  PICKUP_TIME_TOMORROW: "明天",
  PICKUP_TIME_CHANGE: "修改",
  PICKUP_TIME_CONFIRM: "确认",
  PICKUP_TIME_DAY: "天",
  PICKUP_TIME_HOUR: "小时",
  PICKUP_TIME_CHANGE_MESSAGE: "取货时间已更新。如果需要，您可以修改。",
  MENU_HIDE_MESSAGE_PART_1: "餐厅的厨房",
  MENU_HIDE_MESSAGE_PART_2: "目前未营业。请咨询我们的工作人员了解营业时间。",
  ADD_NEW_CARD: "使用其他银行卡",
  CLOSED_KITCHEN_MESSAGE: "抱歉，我们目前不接受新订单。请稍后再试",
  AUTOSERVICE_NAME: "名字",
  AUTOSERVICE_ADD_NAME: "请输入您的名字",
  NOT_AVAILABLE: "不可用",
  FOR_FREE: "免费",
  ITEMS_NOT_AVAILABLE: "您的购物车中有一件或多件商品不再可用",
  VIEW_CART: "查看购物车",
  CONTINUE: "继续",
  ANOTHER_PRODUCT_ERROR: "请选择其他产品",
  PRODUCT_NOT_AVAILABLE: "不可用",
  ERROR_DATE_PAST: "所选日期已过。请选择未来的日期",
  SELECT_LANGUAGE: "选择语言",
  AUTOSERVICE_ADDRESS: "地址",
  AUTOSERVICE_ADD_ADDRESS: "请添加您的地址",
  "LANGUAGE.CHINESE": "中文",
  ITEM: "物品",
  ITEMS: "物品",
  NO_MENU_ID_ERROR: "未找到菜单ID",
  "LANGUAGE.NEDERLANDS": "荷兰语",
  "LANGUAGE.SVENSKA": "瑞典语",
  "LANGUAGE.DANSK": "丹麦语",
  "LANGUAGE.SUOMI": "芬兰语",
  FILL_CARD_DETAILS: "您必须填写卡片详细信息",
  CARD_MANDATORY: "卡片是必需的",
  CARD_NUMERIC: "卡片必须是数字",
  CARD_NON_NEGATIVE: "卡片不能是负数",
  EXPIRATION_MONTH_MANDATORY: "卡片过期月份是必需的",
  EXPIRATION_MONTH_NUMERIC: "卡片过期月份必须是数字",
  EXPIRATION_MONTH_INCORRECT: "卡片过期月份不正确",
  EXPIRATION_YEAR_MANDATORY: "卡片过期年份是必需的",
  EXPIRATION_YEAR_NUMERIC: "卡片过期年份必须是数字",
  EXPIRATION_YEAR_NON_NEGATIVE: "卡片过期年份不能是负数",
  SECURITY_CODE_LENGTH: "卡片的安全代码长度不正确",
  SECURITY_CODE_NUMERIC: "卡片安全代码必须是数字",
  SECURITY_CODE_NON_NEGATIVE: "卡片安全代码不能是负数",
  SECURITY_CODE_NOT_REQUIRED: "您的卡不需要安全代码",
  CARD_LENGTH_INCORRECT: "卡片长度不正确",
  CARD_NUMBER_VALID: "您必须输入有效的卡号（无空格或连字符）",
  MERCHANT_VALIDATION_INCORRECT: "商户验证不正确",
}
