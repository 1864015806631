import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import classes from "./venuedetails.module.css"
import TableLeft from "@components/tables/tableleft/TableLeft"
import TableRight from "@components/tables/tableright/TableRight"
import TableCenter from "@components/tables/tablecenter/TableCenter"
import VenueModal from "@components/modals/VenueModal"
import CustomLoader from "@components/Loaders/CustomLoader"
import {
  getMenuList,
  getAllergensList,
  getMenuDetail,
} from "@store/menu/MenuActions"
import {
  setSelectedOption,
  getClientDetail,
  getVenueDetail,
  checkCollectionAvailability,
  checkDeliveryAvailability,
} from "@store/venues/VenueActions"
import { Menu } from "@models/Menu"
import LoginModal from "@modules/login/LoginPage"
import SignUpModal from "@modules/signup/SignUpPage"
import { CartType } from "@models/Cart"
import { toast } from "react-toastify"
import MenuModal from "@components/modals/MenuModal"
import { clientSetting } from "config"
import { login } from "@store/auth/AuthActions"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { SingleOrderAlert } from "@components/modals/SingleModelAlert"
import PendingOrderDetailsModel from "@components/modals/PendingOrderDetailsModel"
import {
  getMinimunOrderPrice,
  venueOrderType,
  checkClosedKitchenTime,
  excludeOrderType,
} from "@helpers/utilsHelper"
import {
  getTable,
  showPendingOrderDetailsModel,
} from "@store/orderFromTable/OrderFromTableActions"
import {
  addDevice,
  getVenuePopup,
  setPopupCheck,
} from "@store/venuePopup/VenuePopupActions"
import { getDeviceToken } from "@helpers/utilsHelper"
import VenuePopupModal from "@components/modals/VenuePopupModal"
import { MenuHeader } from "./components/MenuHeader"
import BackButton from "@components/backButton/BackButton"
import { useBrand } from "@hooks/useBrand"
import { MobileFooter } from "@components/footer/MobileFooter"
import { HideMenuModal } from "@components/modals/HideMenuModal"
import LanguagePopupModal from "@components/modals/LanguagePopupModal"
import { languagesListData } from "@modules/venueDetails/languageData"

const scrollToRef = (ref) =>
  window.scrollTo({ top: ref.offsetTop, behavior: "smooth" })

const VenueDetails = (props) => {
  const { primaryColor } = useBrand()
  const { permalink, id, menuId, tableId } = props.match.params
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    languageStrings,
    changeMenuLang,
    menuLanguage,
    locale,
    changeLangVenue,
    changeLanguage,
  } = useLanguageHelper()
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  const selectedOption = useSelector(({ venues }: any) => venues.selectedOption)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const menuList: Menu = useSelector(({ menu }: RootState) => menu.menuList)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const menuLoading = useSelector(({ menu }: RootState) => menu.loading)
  const venueLoading = useSelector(({ venues }: RootState) => venues.loading)
  const venueList = useSelector(({ venues }: RootState) => venues.venueList)
  const menus = useSelector(({ menu }: any) => menu.menus)
  const cart = useSelector(({ cart }: RootState) => cart.cart)
  const venuePopup = useSelector(({ venuePopup }: RootState) => venuePopup)
  const venuePopupLoading = useSelector(
    ({ venuePopup }: RootState) => venuePopup.loading,
  )
  const popupCheck = useSelector(
    ({ venuePopup }: RootState) => venuePopup.popupCheck,
  )
  const { user } = useSelector(({ user }: RootState) => user)
  const { error, signUpError } = useSelector(({ auth }: RootState) => auth)
  const {
    showPendingOrderTimingDetailsModel = false,
    currentDevicePendingList = [],
    programmedOrderDate = "",
  } = useSelector(({ orderFromTable }: RootState) => orderFromTable)
  const { autoServices } = venueOrderType(venueDetail)

  const cartItems = cart.items
  const { _id: userId } = user
  const loading = menuLoading || venueLoading || venuePopupLoading
  const venues = useSelector(({ venues }: RootState) => venues)
  const typography = primaryColor
  const [menuIdState, setMenuIdState] = useState(
    menuId && menuId !== "null" ? menuId : "",
  )
  const [width, setWidth] = useState(window.innerWidth)
  const [showModal, setShowModal] = useState(false)
  const [signUpModal, setSignUpModal] = useState(false)
  const [isBasket, setIsBasket] = useState(false)
  const [open, setOpen] = useState(false)
  const [openMenu, setOpenMenu] = React.useState(false)
  const [menusFiltered, setMenusFiltered] = React.useState([])
  const [showPopup, setShowPopup] = React.useState(false)
  const [showLanguagePopup, setShowLanguagePopup] = useState(false)
  const [showSecondModal, setShowSecondModal] = useState(false)
  const [showMenuVisible, setShowMenuVisible] = React.useState<boolean>(false)
  const isMobile = width <= 600
  const [tabValue, setTabValue] = useState(isMobile ? 0 : -1)
  const { settings, permission } = restaurant
  const { delivery, collection, orderFromTable } = settings || {}
  const languages = menuList?.selectedLanguages || []
  const isMultiLanguage = languages.length > 1
  const showRestaurantLanguageModal =
    (restaurant && restaurant.showLanguageModal) || false
  const [popupsCheck, setPopupsCheck] = useState(false)

  const handleLanguageModalClose = () => {
    setShowLanguagePopup(false)
    setShowSecondModal(true)
  }
  useEffect(() => {
    const foundLanguage = languagesListData.find(
      (item) => item.locale === locale,
    )
    if (foundLanguage) {
      const clientId = localStorage.getItem("clientId")
      changeLanguage(foundLanguage.locale, true)
      changeMenuLang(foundLanguage.code)
      if (clientId) {
        changeLangVenue(clientId)
      }
    }
  }, [])
  useEffect(() => {
    if (!popupCheck) {
      let deviceId = getDeviceToken()
      if (languages && isMultiLanguage && showRestaurantLanguageModal) {
        setShowLanguagePopup(true)
        dispatch(setPopupCheck(true))
      } else {
        if (deviceId && venuePopup && venuePopup.venuePopupActive) {
          const needToShowImageModal =
            !venuePopup.visitedUser.includes(deviceId)
          if (needToShowImageModal) {
            setShowSecondModal(true)
            setShowPopup(true)
          }
        }
      }
    }
  }, [languages])

  useEffect(() => {
    let deviceId = getDeviceToken()
    if (deviceId && venuePopup && venuePopup.venuePopupActive) {
      const needToShowImageModal = !venuePopup.visitedUser.includes(deviceId)
      if (needToShowImageModal && showSecondModal) {
        setShowPopup(true)
      }
    }
  }, [venuePopup, showSecondModal])

  const checkForDetails = () => {
    let isOnlyOneVenue = false

    if (open) {
      return false
    } else if (!delivery || !collection || !orderFromTable) {
      return false
    } else if (
      (delivery?.payByCard === true || delivery?.payByCash === true) &&
      !permission?.hasCollection
    ) {
      isOnlyOneVenue = true
    } else if (
      (collection?.payByCard === true || collection?.payByCash === true) &&
      !permission?.hasDelivery
    ) {
      isOnlyOneVenue = true
    } else if (
      (orderFromTable?.payByCard === true ||
        orderFromTable?.payByCash === true) &&
      !permission?.hasDelivery &&
      !permission?.hasCollection
    ) {
      isOnlyOneVenue = true
    }

    const menuFiltered = menus.filter((menu) => {
      if (selectedOption == CartType.COLLECT) {
        return menu.venues.includes(id) && menu.availableOnCollection === true
      } else if (selectedOption == CartType.DELIVERY) {
        return menu.venues.includes(id) && menu.availableOnDelivery === true
      }
    })
    if (menuFiltered && menuFiltered.length === 1) {
      // history.push(`/${permalink}/menu/${val._id}`)
      setMenuIdState(menuFiltered[0]._id)
    }
    setMenusFiltered(menuFiltered || [])
    return menuFiltered && menuFiltered.length > 1 ? isOnlyOneVenue : false
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    if (permalink) {
      dispatch(getClientDetail(permalink))
    }
    dispatch(getAllergensList())

    dispatch(getVenueDetail(id))
  }, [])
  useEffect(() => {
    if (tableId) {
      dispatch(setSelectedOption(CartType.ORDER_FROM_TABLE))
      dispatch(getTable(tableId))
    }
  }, [tableId])
  useEffect(() => {
    let type = "orderFromTable"
    if (selectedOption === CartType.COLLECT) {
      type = "collection"
    }

    if (selectedOption === CartType.DELIVERY) {
      type = "delivery"
    }

    if (selectedOption === CartType.ORDER_FROM_TABLE) {
      type = "orderFromTable"
    }
    if (selectedOption !== "" && menuIdState) {
      dispatch(getMenuDetail(menuIdState, type, programmedOrderDate, tableId))
    } else if (menuIdState === undefined) {
      setOpen(true)
      // history.push(`/${permalink}`)
    }
  }, [menuIdState, selectedOption])

  useEffect(() => {
    if (venueList && venueList.length === 1 && checkForDetails()) {
      setOpenMenu(true)
    }
  }, [venueList, settings, open, menus])

  useEffect(() => {
    if (
      !venueDetail.collectionAvailable &&
      !venueDetail.deliveryAvailable &&
      !menuIdState
    ) {
      dispatch(getMenuList(id, "", false))
    }
  }, [venueDetail])

  useEffect(() => {
    if (userId) {
      setShowModal(false)
      setSignUpModal(false)
    }
  }, [userId])

  useEffect(() => {
    const { collectionAvailable, deliveryAvailable } = venueDetail
    const check = collectionAvailable && deliveryAvailable
    if (selectedOption === "" && check) {
      setOpen(true)
    } else if (!check) {
      setOpen(false)
    }
  }, [selectedOption, venueDetail])

  useEffect(() => {
    if (venueDetail._id) {
      dispatch(getVenuePopup(venueDetail._id))
    }
  }, [dispatch, venueDetail._id])

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  const handleButtonClick = (value) => {
    dispatch(setSelectedOption(value))
    setOpen(false)
    const menuFiltered = menus.filter((menu) => {
      if (value == CartType.COLLECT) {
        return menu.venues.includes(id) && menu.availableOnCollection === true
      } else if (value == CartType.DELIVERY) {
        return menu.venues.includes(id) && menu.availableOnDelivery === true
      }
    })

    if (menuFiltered && menuFiltered.length === 1) {
      // history.push(`/${permalink}/menu/${val._id}`)
      setMenuIdState(menuFiltered[0]._id)
    } else if (menuFiltered && menuFiltered.length > 1) {
      setOpenMenu(true)
      setMenusFiltered(menuFiltered)
    } else {
      toast.error(
        languageStrings["VENUE_DETAIL.THERE_ARE_NO_MENUES_FOR_THIS_RESTAURANT"],
      )
    }
  }

  const executeScroll = (index) => {
    setTabValue(index)
  }

  const toggleModal = () => {
    setOpen(!open)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSignUpCloseModal = () => {
    setSignUpModal(false)
  }

  const handleLinkClick = () => {
    if (!userId) {
      setShowModal(!showModal)
      setSignUpModal(false)
    }
  }
  const handleClick = () => {
    if (!userId) {
      setIsBasket(true)
      if (clientSetting.registrationRequired) {
        setShowModal(!showModal)
      } else {
        dispatch(
          login(
            id,
            {
              email: process.env.REACT_APP_USER_EMAIL,
              password: process.env.REACT_APP_USER_PASSWORD,
            },
            isBasket,
            selectedOption == "2" && true,
            selectedOption == "1" && true,
            history,
            selectedOption == "3" && true,
          ),
        )
      }
    } else {
      if (selectedOption == "1") {
        dispatch(checkCollectionAvailability(id, true, history, autoServices))
      } else if (selectedOption == "2") {
        dispatch(checkDeliveryAvailability(id, true, history, autoServices))
      } else {
        history.push(`/menu/basket/${id}`)
      }
    }
  }

  const handleSignUpClick = (value) => {
    setShowModal(false)
    if (value === "isAddress") {
      setIsBasket(false)
    }
    setTimeout(() => {
      setSignUpModal(true)
    }, 100)
  }
  const handlePopupClose = () => {
    setShowPopup(false)
    if (venueDetail._id) {
      dispatch(addDevice(venueDetail._id))
    }
    setShowSecondModal(false)
  }
  const headerStyle = {
    transition: "opacity 0.5s ease-in-out",
    opacity: isScrolled ? 1 : 0,
  }
  let isClosedKitchen =
    restaurant?.closeKitchenTime && !programmedOrderDate
      ? checkClosedKitchenTime(restaurant?.closeKitchenTime)
      : false

  const isMenuHide =
    (menuList?.isVisible === false || isClosedKitchen) &&
    showMenuVisible === false
  const isMenuVisible = !(menuList?.isVisible === false)

  return (
    <CustomLoader loading={loading}>
      <BackButton
        backTitleStyle={headerStyle}
        showTranslate={isMultiLanguage}
        isMobile={isMobile}
        backTitle={menuList?.title}
        availableLanguages={languages}
      >
        {autoServices && (
          <SingleOrderAlert
            venueId={id}
            onClick={() => {
              if (currentDevicePendingList.length === 1) {
                const id = currentDevicePendingList[0]._id
                history.push(`/pending-order-details/${id}`)
              } else {
                showPendingOrderDetailsModel(dispatch, true)
              }
            }}
            typography={typography}
          />
        )}
        <div className={classes.container}>
          {menuList.categories && menuList.categories.length > 0 ? (
            <div className={[classes.main, "px-48"].join(" ")}>
              <TableLeft
                isMobile={isMobile}
                items={menuList.categories}
                tabValue={tabValue}
                setTabValue={setTabValue}
                executeScroll={executeScroll}
                typography={typography}
              />
              <div className={classes.center}>
                <MenuHeader isScrolled={isScrolled} />

                <TableCenter
                  isMobile={isMobile}
                  scrollToRef={scrollToRef}
                  tabValue={tabValue}
                  category={menuList.categories}
                  onClick={handleClick}
                />
              </div>
              {!excludeOrderType.includes(venueDetail?.orderType || "") &&
              isMenuVisible ? (
                <TableRight
                  showDeliveryPrice={false}
                  deliveryPrice={0}
                  minOrderPrice={getMinimunOrderPrice(venueDetail, 0)}
                  items={cartItems}
                  onClick={handleClick}
                />
              ) : null}
            </div>
          ) : null}

          <LoginModal
            typography={typography}
            showModal={showModal}
            onCloseModal={handleCloseModal}
            onSignUpClick={() => handleSignUpClick("isBasket")}
            error={error}
            isBasket={isBasket}
            venueId={id}
            checkCollection={selectedOption == "1" && isBasket}
            checkDelivery={selectedOption == "2" && isBasket}
            orderFromTable={selectedOption == "3" && isBasket}
          />
          <SignUpModal
            typography={typography}
            showModal={signUpModal}
            onCloseModal={handleSignUpCloseModal}
            error={signUpError}
            onLinkClick={handleLinkClick}
            venueId={id}
            isBasket={isBasket}
            checkCollection={selectedOption == "1" && isBasket}
            checkDelivery={selectedOption == "2" && isBasket}
            orderFromTable={selectedOption == "3" && isBasket}
          />
          <VenueModal
            title={languageStrings["VENUE_DETAIL.HOW_DO_YOU_WANT_TO_ORDER"]}
            open={open}
            onClose={toggleModal}
            onButtonClick={handleButtonClick}
          />
          <MenuModal
            title={languageStrings["VENUE_DETAIL.SELECT_MENU"]}
            open={openMenu}
            close={false}
            menus={menusFiltered}
            onClose={() => setOpenMenu(!openMenu)}
            onButtonClick={(menu) => {
              setOpenMenu(!openMenu)
              setMenuIdState(menu)
              // history.push(`/${permalink}/menu/${id}/${menu}`)
            }}
          />
          <PendingOrderDetailsModel
            open={showPendingOrderTimingDetailsModel}
            items={currentDevicePendingList}
          />
          <HideMenuModal
            onClose={() => {
              setShowMenuVisible(true)
            }}
            onViewMenu={() => {
              setShowMenuVisible(true)
            }}
            open={isMenuHide}
            isClosedKitchen={isClosedKitchen}
            isMenuVisible={menuList?.isVisible}
            isCheckoutScreen={false}
          />
        </div>
        {showSecondModal &&
          !showLanguagePopup &&
          venuePopup &&
          !venuePopup.loading &&
          showPopup && (
            <VenuePopupModal
              open={showPopup}
              close={handlePopupClose}
              image={venuePopup.popupImage}
            />
          )}
        {isMultiLanguage && showLanguagePopup && (
          <LanguagePopupModal
            open={showLanguagePopup}
            close={handleLanguageModalClose}
            languages={languages}
          />
        )}
        {!loading && menuList.categories && <MobileFooter isMenu={true} />}
      </BackButton>
    </CustomLoader>
  )
}

export default VenueDetails
