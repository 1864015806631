import { Cart, CartType, ItemOrderMenu } from "@models/Cart"
import { Tax } from "@models/Tax"
import { store } from "@store/index"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { v1 as uuidv1, validate as uuidValidate } from "uuid"
import moment from "moment"
import { axiosInstance } from "@api/axios"
import { POS_TABLE, VENUE, LOYALTY_PROGRAM } from "@api/endPoints"
import { Venue, VenueService } from "@models/Venue"
import { clientSetting } from "config"
import { History as route } from "history"
import { Category, Menu } from "@models/Menu"
import History from "@history"
import isEqual from "lodash/isEqual"
import {
  checkTimeInsideVisibilityRange,
  getNextAvailableTime,
  getRangesByDays,
} from "./utilsDateTimeHelpers"
import { DATE_TIME_FORMATS } from "./Formates/dateTime"

export type paymentTypes = {
  APPLE: "APPLE_PAY"
  GOOGLE: "GOOGLE_PAY"
  CARD: "CARD_PAYMENTS"
  NONE: "NONE"
}
export enum PaymentType {
  APPLE = "APPLE_PAY",
  GOOGLE = "GOOGLE_PAY",
  CARD = "CARD_PAYMENTS",
  CASH = "CASH",
  NONE = "NONE",
}

export const PaymentMethods = {
  STRIPE: "STRIPE",
  SQUARE: "SQUARE",
  REDSYS: "REDSYS",
  FREEDOMPAY: "FREEDOMPAY",
}

export const SocialPaymentTypes = ["GooglePay", "ApplePay"]
export enum SocialPaymentEnum {
  APPLE = "ApplePay",
  GOOGLE = "GooglePay",
}

export enum CategoryType {
  FOOD = "1",
  DRINK = "2",
}
export function validateEmail(email: string) {
  const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  const invalidEndings = [
    ".con",
    ".coms",
    "gnail.com",
    "gamil.com",
    "gmal.com",
    "homail.com",
    "htmail.com",
    "hotmal.com",
  ]
  if (
    invalidEndings.some((ending) => {
      return email.endsWith(ending)
    })
  ) {
    return false
  }
  return re.test(email)
}

export function convertHex(hexCode, opacity) {
  var hex = hexCode.replace("#", "")

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }

  var r = parseInt(hex.substring(0, 2), 16),
    g = parseInt(hex.substring(2, 4), 16),
    b = parseInt(hex.substring(4, 6), 16)

  return "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")"
}
export function getAllergens(allergens) {
  let allergenList = []
  if (allergens) {
    let temp = allergens.split(",")
    if (temp && temp.length > 0)
      allergenList = temp.filter((item) => item != "")
  }
  return allergenList
}
export function formatImageUrl(imageUrl) {
  if (imageUrl) {
    let image = imageUrl
    image =
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? imageUrl.replace(
            "http://server.banzzu.com:8003/",
            "https://server.banzzu.com:8009/",
          )
        : imageUrl.replace(
            "http://server.banzzu.com:8002/",
            "https://server.banzzu.com:8008/",
          )

    return image
  }
  return imageUrl
}

export const randomString = (length) => {
  let result = ""
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWYXZ1234567890"
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
export const getSelectedOptionData = (value = "") => {
  const _storeData = store.getState()
  const {
    delivery = {},
    collection = {},
    orderFromTable = {},
  } = _storeData.venues.restaurant.settings || {}

  let paymentData = {}
  if (
    value === CartType.ORDER_FROM_TABLE &&
    orderFromTable &&
    orderFromTable.payByCard !== undefined &&
    orderFromTable.payByCash !== undefined
  ) {
    paymentData = orderFromTable
  } else if (
    value === CartType.COLLECT &&
    collection &&
    collection.payByCard !== undefined &&
    collection.payByCash !== undefined
  ) {
    paymentData = collection
  } else if (
    value === CartType.DELIVERY &&
    delivery &&
    delivery.payByCard !== undefined &&
    delivery.payByCash !== undefined
  ) {
    paymentData = delivery
  }
  return paymentData
}
export const detectLanguage = (index = 0) => {
  const _storeData = store.getState()
  const { selectedLanguages = [] } = _storeData.menu.menuList || {}
  let lang = "es"

  if (selectedLanguages[index] === "English") lang = "en"
  else if (selectedLanguages[index] === "Italian") lang = "it"
  else if (selectedLanguages[index] === "French") lang = "fr"
  else if (selectedLanguages[index] === "German") lang = "de"
  else if (selectedLanguages[index] === "Catalan") lang = "ca"
  else if (selectedLanguages[index] === "Chinese") lang = "zh"
  return lang
}
export const detectFlag = (countryName = "Spanish") => {
  let lang = "es"

  if (countryName === "English") lang = "en"
  else if (countryName === "Italian") lang = "it"
  else if (countryName === "French") lang = "fr"
  else if (countryName === "German") lang = "de"
  else if (countryName === "Catalan") lang = "ca"
  else if (countryName === "Chinese") lang = "zh"
  return lang
}

interface Props {
  index: number
  defaultTxt: string
}

export const useTranslatedTextV2 = (props: Props) => {
  const { index = 0, defaultTxt = "" } = props
  const [transaltedText, setTranslatedText] = useState("")
  const { menuTranslation = [] } = useSelector(({ menu }: RootState) => menu)

  useEffect(() => {
    if (
      menuTranslation &&
      menuTranslation.length &&
      menuTranslation[index] &&
      menuTranslation[index].translatedText
    ) {
      setTranslatedText(menuTranslation[index].translatedText)
    } else {
      setTranslatedText(defaultTxt)
    }
  }, [menuTranslation, defaultTxt])

  return transaltedText
}
export const getTaxes = (
  taxDetail?: Tax,
): {
  taxType: string
  totalTax: number
  taxPercentage: number
  serviceWithTax: number
  serviceWithoutTax: number
} => {
  const taxData = {
    taxType: "No-TAX",
    totalTax: 0,
    taxPercentage: 0,
    serviceWithTax: 0,
    serviceWithoutTax: 0,
  }

  if (taxDetail && taxDetail.name) {
    taxData.taxType = taxDetail.name
  }
  if (taxDetail && taxDetail.percentage) {
    taxData.totalTax = taxDetail.percentage / 100
    taxData.taxPercentage = taxDetail.percentage
  }
  return taxData
}
export const venueOrderType = (venueDetail?: Venue) => {
  let isVirtualBasket = false
  let autoServices = false
  let isPayAtTable = false
  if (
    venueDetail &&
    (venueDetail.hasVirtualBasket || venueDetail.orderType === "4")
  )
    isVirtualBasket = true
  if (venueDetail && venueDetail.orderType === "5") {
    isPayAtTable = true
  }
  if (
    venueDetail &&
    (venueDetail.autoServices || venueDetail.orderType === "3")
  )
    autoServices = true
  return { isVirtualBasket, isPayAtTable, autoServices }
}
export const getVenueProperties = (venueDetail?: Venue) => {
  let venueProperties = {
    venueHasDiscountVoucher: false,
  }
  const { isVirtualBasket } = venueOrderType(venueDetail)
  if (venueDetail && venueDetail.hasDiscountVoucher && !isVirtualBasket)
    venueProperties.venueHasDiscountVoucher = venueDetail.hasDiscountVoucher

  return venueProperties
}
export const arrangePrice = (amount = 0) => {
  const price = Number(amount || 0) || 0
  let _price = `${price.toFixed(2)}`
  let sign = "+"
  if (price < 0) {
    _price = `${Math.abs(price)}`
    sign = "-"
  }
  return { _price, sign }
}
export const getDeviceToken = () => {
  let uuid = localStorage.getItem("DeviceToken")
  if (!uuid) {
    uuid = uuidv1()
    if (uuid) {
      localStorage.setItem("DeviceToken", uuid)
    }
  }
  return uuid
}

export const formatDate = (date) => {
  try {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [day, month, year].join("/")
  } catch (ex) {}
}

export const getTotalVenuesPrice = (orderList) => {
  let total = 0
  orderList.map((item) => {
    total += item.price
  })
  return total.toFixed(2)
}

export function validateNumber(number: string) {
  const re = /^\+(?:[0-9] ?){6,14}[0-9]$/
  return re.test(number)
}
export const venueIsBusy = async (venueId: string) => {
  try {
    const url = VENUE + "/" + venueId
    const res = await axiosInstance.get(url)

    if (
      res.data &&
      res.data.data &&
      res.data.data.deliverectSettings &&
      res.data.data.deliverectSettings.isBusy === true
    ) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export const getExistingLoyaltyName = async (email: string) => {
  try {
    if (email && email.length) {
      const url = `${LOYALTY_PROGRAM}/clientEmail/${email}`
      const res = await axiosInstance.get(url)
      if (res && res.data && res.data.data && res.data.data.name)
        return res.data.data.name
      else return null
    }
  } catch (error) {
    console.log(error)
  }
}

export const calculatePrices = (
  total: number,
  totalTax: number,
  venueService?: VenueService,
  venueDetail?: Venue,
  discountAmount?: number,
  cartPrice?: number,
) => {
  const discountTotal =
    discountAmount && discountAmount > 0 ? discountAmount : 0
  const _cartPrice = cartPrice && Number(cartPrice) ? Number(cartPrice) : 0
  let _total = total
  let netServiceFee = 0
  let netPrice = (_cartPrice ? _cartPrice : _total) / (1 + totalTax)
  let taxApplied = netPrice * totalTax
  const { isVirtualBasket } = venueOrderType(venueDetail)
  if (
    venueService &&
    venueService.serviceFee &&
    total > 0 &&
    !isVirtualBasket
  ) {
    netServiceFee = venueService.serviceFee / (1 + totalTax)
    _total += venueService.serviceFee
    taxApplied += netServiceFee * totalTax
  }
  if (_total <= 0 && discountTotal > 0) {
    const _service =
      venueService && Number(venueService.serviceFee)
        ? Number(venueService.serviceFee)
        : 0
    const fullPrice = _total + _service

    if (fullPrice < 0) {
      _total = 0
      netServiceFee = _service / (1 + totalTax)
      taxApplied += netServiceFee * totalTax
    } else {
      _total = fullPrice
      netServiceFee = _service / (1 + totalTax)
      taxApplied += netServiceFee * totalTax
    }
  }
  return { netPrice, taxApplied, _total, netServiceFee }
}

export const priceWithService = (
  total: number = 0,
  venueService?: VenueService,
  venueDetail?: Venue,
) => {
  let orderPrice = total
  let serviceFee = 0
  const { isVirtualBasket } = venueOrderType(venueDetail)
  if (venueService && venueService.serviceFee && !isVirtualBasket) {
    orderPrice += venueService.serviceFee
    serviceFee += venueService.serviceFee
  }

  return { orderPrice, serviceFee }
}
export const getMinimunOrderPrice = (
  venueDetail?: Venue,
  minOrderPrice?: number,
) => {
  let minimumOrderPrice = 0
  if (clientSetting.isCheck && venueDetail?.minimumOrderPrice) {
    minimumOrderPrice = Number(venueDetail?.minimumOrderPrice)
  } else {
    minimumOrderPrice = minOrderPrice || 0
  }
  return minimumOrderPrice
}
export const getPaymentMethodType = (paymentMehtod?: any) => {
  let paymentMethod = PaymentType.CARD
  if (paymentMehtod && paymentMehtod.applePay) paymentMethod = PaymentType.APPLE
  else if (paymentMehtod && paymentMehtod.googlePay)
    paymentMethod = PaymentType.GOOGLE

  return paymentMethod
}
export const handleRouting = (data, notifications = []) => {
  let routeData = {
    pathname: `/NotificationType`,
    state: data,
  }
  if (notifications && notifications.length == 1) {
    const [type] = notifications || []
    if (type === "1") {
      routeData = {
        pathname: `/order-notified/${"by-whatsapp"}`,
        state: data,
      }
    } else if (type === "2") {
      routeData = {
        pathname: `/order-notified/${"by-email"}`,
        state: data,
      }
    } else if (type === "3") {
      routeData = {
        pathname: `/order-notified/${"by-sms"}`,
        state: data,
      }
    }
  }
  return { routeData }
}
export const goToBasketPayment = (
  history: route<route.PoorMansUnknown>,
  state: any,
) => {
  history.push({
    pathname: `/BasketPayment/false`,
    state,
  })
}
export const getAmount = (amount = 0) => {
  let amountInNumber = 0
  let amountInString = `0.0`
  if (amount && Number(amount) && Number(amount) > 0) {
    amountInNumber = Number(amount)
    amountInString = amountInNumber.toFixed(2)
  }
  return { amountInNumber, amountInString }
}
export const convertCommaToDecimal = (amount: string) => {
  let _price = 0
  if (amount && amount.includes(",")) {
    _price = Number(amount.replace(/,/g, "."))
  } else {
    _price = Number(amount)
  }
  return _price
}
export const getTaxObject = (tax, table) => {
  const taxObj = tax && tax.percentage ? tax : { name: "No-TAX", percentage: 0 }
  if (table && table.taxes !== undefined) taxObj.percentage = table.taxes
  return { taxObj }
}
export const checkPosTableStatus = async (tableId: string) => {
  try {
    const url = POS_TABLE + "/" + tableId
    const res = await axiosInstance.get(url)
    if (res.data && res.data.data && res.data.data.isBusy === true) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}
export const truncateText = (text: string, maxLength: number = 20): string => {
  if (text && text.length > maxLength) {
    return text.substring(0, maxLength) + "..."
  }
  return text
}
export const getPopularItems = (category = []): MenuItem[] | [] => {
  const menuItems: MenuItem[] = []

  for (let i = 0; i < category?.length; i++) {
    const { menuList = [] } = category?.[i] || {}
    if (menuList?.length) {
      for (let j = 0; j < menuList.length; j++) {
        const item = menuList?.[j] as MenuItem
        if (item?.isPopular === true) {
          menuItems.push(item)
        }
      }
    }
  }
  return menuItems
}
export const arrangeFoodAndDrink = (
  isCategoryDivide = false,
  category = [],
  val,
): {
  categories: Category[]
  popularItems: MenuItem[]
  isCategoryDivide: boolean
} => {
  const data: {
    foodCategories: Category[]
    drinkCategories: Category[]
    popularItems: MenuItem[]
    isCategoryDivide: boolean
  } = {
    foodCategories: [],
    drinkCategories: [],
    popularItems: [],
    isCategoryDivide: false,
  }
  let isBothExist = false
  if (isCategoryDivide) {
    for (let i = 0; i < category?.length; i++) {
      const item = category?.[i] as Category
      if (item && item?.type === CategoryType.DRINK) {
        data.drinkCategories.push(item)
      } else {
        data.foodCategories.push(item)
      }
    }
    isBothExist =
      data.drinkCategories.length > 0 && data.foodCategories.length > 0
  }

  data.popularItems = getPopularItems((category || []) as never[])
  data.isCategoryDivide = !!(isCategoryDivide && isBothExist)
  const _cat =
    val === CategoryType.DRINK && isCategoryDivide && isBothExist
      ? data.drinkCategories
      : val === CategoryType.FOOD && isCategoryDivide && isBothExist
      ? data.foodCategories
      : category

  const _newData = {
    categories: _cat,

    popularItems: data.popularItems,
    isCategoryDivide: data.isCategoryDivide,
  }
  return _newData
}
export const redirectFromRating = ({
  _orderType,
  permalink,
  tableId,
  venueId,
}) => {
  if (_orderType === "1" || _orderType === "2" || _orderType === "3") {
    History.push("/orderConfirmed")
  } else {
    History.push(
      `${permalink || ""}/virtualbasketdetails/${tableId}/${venueId || ""}`,
    )
  }
}

export const getMultiLanguageTitle = (multiItem: any, language: number = 1) => {
  const multiLangTitle = multiItem?.find((lang) => lang.language === language)
  return multiLangTitle
}
export const addSpacesToCreditCardNumber = (creditCardNumber: string) => {
  // Remove any non-digit characters from the credit card number
  const digitsOnly = creditCardNumber.replace(/\D/g, "")

  // Define the length of each group
  const groupLength = 4

  // Initialize the separated number string
  let separatedNumber = ""

  // Iterate through the credit card number and add spaces
  for (let i = 0; i < digitsOnly.length; i += groupLength) {
    const group = digitsOnly.substr(i, groupLength) // Extract the current group of digits
    separatedNumber += group + " " // Add the group with a space to the separated number string
  }

  // Remove trailing space if exists
  separatedNumber = separatedNumber.trim()

  return separatedNumber
}

export const isSellingFormatProduct = (item: any = {}) => {
  let isSellingFormat = false
  let basePrice = 0
  if (item?.extraCategories?.length) {
    for (let i = 0; i < item.extraCategories.length; i++) {
      const category = item.extraCategories[i]
      if (category?.isSellingFormat) {
        isSellingFormat = true
        basePrice = getFormatBasePrice(category)
      }
    }
  }
  return { isSellingFormat, basePrice }
}

export const getFormatBasePrice = (category: any = {}) => {
  let basePrice = 0
  if (category?.items?.length > 0) {
    basePrice = category?.items?.[0]?.price ?? 0
    for (let i = 0; i < category.items.length; i++) {
      const item = category.items[i]
      if (item?.price < basePrice) {
        basePrice = item.price
      }
    }
  }
  return basePrice
}

export const getAvailableTimes = (menuAvailability, date) => {
  const timeSlots: string[] = []

  const day = moment(date).format("dddd").toLocaleLowerCase()

  // to check day present
  if (!menuAvailability || !menuAvailability[day]) {
    return timeSlots
  }
  if (!menuAvailability?.visibilityRanges?.length) {
    return timeSlots
  }

  for (let i = 0; i < menuAvailability.visibilityRanges.length; i++) {
    const { start, end } = menuAvailability.visibilityRanges[i] || {}
    const [startHour, startMinute] = start.split(":").map(Number)
    const [endHour, endMinute] = end.split(":").map(Number)
    const _startDate = moment()
      .startOf("day")
      .set({
        hours: startHour,
        minutes: startMinute,
        seconds: 0,
        milliseconds: 0,
      })
      .toDate()
    const _endDate = moment()
      .startOf("day")
      .set({
        hours: endHour,
        minutes: endMinute,
        seconds: 0,
        milliseconds: 0,
      })
      .toDate()
    let nextAvailableTime = _startDate

    let currentTimeSlot = new Date(nextAvailableTime)
    while (moment(currentTimeSlot).isSameOrBefore(_endDate)) {
      const formattedTime = moment(currentTimeSlot).format("HH:mm")
      if (moment(currentTimeSlot).isAfter(_startDate)) {
        timeSlots.push(formattedTime)
      }
      currentTimeSlot.setMinutes(currentTimeSlot.getMinutes() + 15)
    }
  }
  return timeSlots
}

export const checkAvailableTimeCheckout = (
  scheduledTime: string = "",
  menuAvailability = {},
  visibility = "1",
) => {
  const selectedDay = moment(scheduledTime).format("dddd").toLowerCase()
  const programmedDate = new Date(scheduledTime)
  const tomorrow = moment(scheduledTime).add(1, "days")
  const dayTomorrow = tomorrow.format("dddd").toLowerCase()
  const now = new Date()
  now.setMinutes(now.getMinutes() + 30)
  let nextAvailableTime = new Date(
    Math.ceil(now.getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000),
  )
  let verifiedTime = moment(nextAvailableTime).format("HH:mm")
  if (menuAvailability && visibility === "3") {
    const availableTimes = getAvailableTimes(menuAvailability, selectedDay)
    if (availableTimes?.length > 0 && programmedDate) {
      const earliestTime = availableTimes[0]
      const currentDate = moment()
      currentDate.day(selectedDay)
      const [hours, minutes] = earliestTime.split(":")
      currentDate.set({
        hour: Number(hours),
        minute: Number(minutes),
        second: 0,
        millisecond: 0,
      })
      const toISOString = currentDate.toISOString()
      const earliestTimeDate = new Date(toISOString)
      nextAvailableTime = earliestTimeDate
    } else if (!availableTimes?.length) {
      const availableTimesNext = getAvailableTimes(
        menuAvailability,
        dayTomorrow,
      )
      if (availableTimesNext?.length > 0 && programmedDate) {
        const earliestTime = availableTimesNext[0]
        const currentDate = moment()
        currentDate.day(dayTomorrow)
        const [hours, minutes] = earliestTime.split(":")
        currentDate.set({
          hour: Number(hours),
          minute: Number(minutes),
          second: 0,
          millisecond: 0,
        })
        const toISOString = currentDate.toISOString()
        const earliestTimeDate = new Date(toISOString)
        nextAvailableTime = earliestTimeDate
      } else {
        return "unavailable"
      }
    }
  }
  if (programmedDate < nextAvailableTime) {
    return nextAvailableTime
  } else return programmedDate
}

export const checkAvailableTimeCheckoutV2 = (
  scheduledTime: string = "",
  menuAvailability,
  visibility = "1",
  maxFutureDay,
) => {
  const isMenuVisibilityShowOnly = visibility === "3"
  const programmedDate = moment(scheduledTime)
  const daysConditions = getRangesByDays(
    isMenuVisibilityShowOnly ? menuAvailability : [],
  )
  const nextAvailableTime = getNextAvailableTime(daysConditions, maxFutureDay)
  const day = programmedDate
    .format(DATE_TIME_FORMATS.DAY.DEFAULT)
    .toLocaleLowerCase()
  const [hour, minute] = programmedDate
    .format(DATE_TIME_FORMATS.TIME.DEFAULT)
    .split(":")
  const isNextAvailableTimePresentInRange = checkTimeInsideVisibilityRange(
    daysConditions,
    day,
    hour,
    minute,
  )
  if (!isNextAvailableTimePresentInRange) {
    return "unavailable"
  }

  if (programmedDate.isBefore(moment(nextAvailableTime))) {
    return nextAvailableTime
  } else {
    return programmedDate.toDate()
  }
}

export const maskCardNumber = (cardNumber: string): string => {
  // Extract the last four digits
  const lastFourDigits: string = cardNumber.slice(-4)

  // Mask the rest of the digits with "*"
  const maskedDigits: string = "*".repeat(cardNumber.length - 4)

  // Add spaces after every four characters
  let formattedMaskedDigits: string = ""
  for (let i = 0; i < maskedDigits.length; i++) {
    if (i % 4 === 0 && i !== 0) {
      formattedMaskedDigits += " "
    }
    formattedMaskedDigits += maskedDigits[i]
  }

  // Concatenate the masked digits with the last four digits
  const maskedCardNumber: string = formattedMaskedDigits + " " + lastFourDigits

  return maskedCardNumber
}

export const getDefaultHour = (firstAvailableTime) => {
  const currentDate = new Date()
  const [hours, minutes] =
    firstAvailableTime === "24:00"
      ? [0, 0]
      : firstAvailableTime.split(":").map(Number)
  currentDate.setHours(hours)
  currentDate.setMinutes(minutes)
  return currentDate
}

export const checkClosedKitchenTime = (time: string = "") => {
  if (time === "closed") return true
  const currentTime = new Date()
  const closedTime = new Date(time)
  return currentTime < closedTime
}

export const checkClosedKitchen = async (clientId: string = "") => {
  try {
    if (!clientId) return false
    const url = `web/close-kitchen/${clientId}`
    const res = await axiosInstance.get(url)
    if (res && res.data && res.data.data && res.data.data.isClosed) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}
export const excludeOrderType = ["5", "6"]
export const findComboIndex = (
  array: ItemOrderMenu[],
  itemToFind: MenuItem,
): number => {
  return array.findIndex((item) => {
    const { quantity: _, price: __, ...itemWithoutQuantity } = item
    const {
      quantity: _itemQuantity,
      price: _itemPrice,
      ...itemToFindWithoutQuantity
    } = itemToFind

    return isEqual(itemWithoutQuantity, itemToFindWithoutQuantity)
  })
}

export const checkVisibilityStatus = (
  visibilityRanges: VisibilityRange[],
  visibility?: string,
) => {
  let show = true
  if (!visibility || visibility === "1") {
    return show
  }

  if (visibility === "2") {
    show = false
  } else if (visibility === "3" && visibilityRanges?.length) {
    const currentDay = moment().format("dddd").toLowerCase()
    const current = moment().format("HH:mm")
    const isCurrentTimeInRange = visibilityRanges.some((range) => {
      if (range[currentDay]) {
        const { start, end } = range
        const startTime = moment(`${start}`, "dddd HH:mm:ss")
        const endTime = moment(`${end}`, "dddd HH:mm:ss")
        const currentTime = moment(`${current}`, "dddd HH:mm:ss")
        return currentTime.isBetween(startTime, endTime, null, "[]")
      }
      return false
    })
    show = isCurrentTimeInRange
  }

  return show
}

export const checkVisibilitiesOfCartItems = (
  cartItems: ItemOrderMenu[],
  NOT_AVAILABLE: string,
): { visible: boolean; message: string } => {
  let visible = true
  let message = ""

  for (const cartItem of cartItems) {
    const isVisible = checkVisibilityStatus(
      cartItem?.visibilityRanges?.length ? cartItem.visibilityRanges : [],
      cartItem.visibility,
    )
    if (!isVisible) {
      visible = false
      message = `${cartItem.title} ${NOT_AVAILABLE}`
      break
    }

    if (cartItem.isCombo && cartItem.comboCategories?.length) {
      for (const comboCategory of cartItem.comboCategories) {
        if (comboCategory.products?.length) {
          for (const product of comboCategory.products.filter(
            (p) => p.selected,
          )) {
            const productVisible = checkVisibilityStatus(
              product.visibilityRanges,
              product.visibility,
            )
            if (!productVisible) {
              visible = false
              message = `${product.title} ${NOT_AVAILABLE}`
              break
            }

            if (product.extraCategories?.length) {
              for (const extraCategory of product.extraCategories) {
                const extraCategoryVisible = checkVisibilityStatus(
                  extraCategory.visibilityRanges,
                  extraCategory.visibility,
                )
                if (!extraCategoryVisible) {
                  visible = false
                  message = `${extraCategory.title} ${NOT_AVAILABLE}`
                  break
                }

                if (extraCategory.items?.length) {
                  for (const item of extraCategory.items.filter(
                    (i) => i.selected,
                  )) {
                    const itemVisible = checkVisibilityStatus(
                      item.visibilityRanges,
                      item.visibility,
                    )
                    if (!itemVisible) {
                      visible = false
                      message = `${item.title} ${NOT_AVAILABLE}`
                      break
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else if (cartItem.extraCategories?.length) {
      for (const extraCategory of cartItem.extraCategories) {
        const extraCategoryVisible = checkVisibilityStatus(
          extraCategory.visibilityRanges,
          extraCategory.visibility,
        )
        if (!extraCategoryVisible) {
          visible = false
          message = `${extraCategory.title} ${NOT_AVAILABLE}`
          break
        }

        if (extraCategory.items?.length) {
          for (const item of extraCategory.items.filter((i) => i.selected)) {
            const itemVisible = checkVisibilityStatus(
              item.visibilityRanges,
              item.visibility,
            )
            if (!itemVisible) {
              visible = false
              message = `${item.title} ${NOT_AVAILABLE}`
              break
            }
          }
        }
      }
    }
  }

  return { visible, message }
}
export const arrangeCartForPromotions = ({
  cartItems,
}: {
  cartItems: ItemOrderMenu[]
}) => {
  const groupedPromotions: {
    [key: string]: { items: ItemOrderMenu[]; totalCount: number }
  } = {}

  // Clone the cart items array to avoid mutating the original array from the cart
  const products = JSON.parse(JSON.stringify(cartItems))

  // Track groupedPromotions and quantities for each product
  products.forEach((product) => {
    product?.promotions?.forEach((promotion) => {
      if (!groupedPromotions[promotion._id]) {
        groupedPromotions[promotion._id] = {
          items: [product],
          totalCount: product.quantity,
        }
      } else {
        groupedPromotions[promotion._id].items.push(product)
        groupedPromotions[promotion._id].totalCount += product.quantity
      }
    })
  })

  const mappedDiscountedQuantityProducts: { [key: string]: number } = {}

  // add discounted quantity with products which have the lowest price among the grouped promotions
  Object.keys(groupedPromotions).forEach((promotionId) => {
    const promotion = groupedPromotions[promotionId]
    const products = promotion.items

    //check how many products can be discounted based on the quantity of each product
    //half of them can be discounted
    let discountedQuantity = Math.floor(promotion.totalCount / 2)

    // sort products by price
    products.sort((a, b) => a.price - b.price)

    // add discounted quantity with the product which has the lowest price
    for (let i = 0; i < products.length && discountedQuantity > 0; i++) {
      const currentProduct: ItemOrderMenu = products[i]
      let currentDiscountedQuantity
      if (currentProduct.quantity < discountedQuantity) {
        currentDiscountedQuantity = discountedQuantity - currentProduct.quantity
      } else {
        currentDiscountedQuantity = discountedQuantity
      }

      mappedDiscountedQuantityProducts[currentProduct._id] =
        currentDiscountedQuantity
      discountedQuantity = discountedQuantity - currentDiscountedQuantity
    }
  })

  const finalProducts = products.map((product) => {
    return {
      ...product,
      discountedQuantity: mappedDiscountedQuantityProducts[product._id] || 0,
    }
  })

  return JSON.parse(JSON.stringify(finalProducts))
}
export const addExtras = ({
  menu,
  id = "",
  categoryId,
  key = "extraCategories",
  apiData,
}: {
  menu: Menu
  id: string
  categoryId: string
  key: string
  apiData: []
}) => {
  const updatedData = JSON.parse(JSON.stringify(menu))
  if (apiData?.length && categoryId && id) {
    const newUpdatedCategories = menu?.categories?.map((item) => {
      let payload = JSON.parse(JSON.stringify(item))
      if (item?._id === categoryId) {
        const newMenuList = payload.menuList.map((menuItem) => {
          let updatedMenuItem = JSON.parse(JSON.stringify(menuItem))
          if (menuItem?._id === id && apiData) {
            updatedMenuItem[`${key}`] = apiData
            updatedMenuItem.fetchedByApi = true
          }
          return updatedMenuItem
        })
        payload.menuList = newMenuList
      }
      return payload
    })
    updatedData.categories = newUpdatedCategories
  }
  return updatedData
}
