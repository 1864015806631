import React, { useEffect, useState } from "react"
import { Box } from "@material-ui/core"
import CustomModal from "@components/modals/CustomModal"
import { useDispatch, useSelector } from "react-redux"
import { Heading3 } from "@components/Text/Text"
import {
  getTicketBai,
  sendInvoiceEmail,
  showInvoiceModel,
} from "@store/orderFromTable/OrderFromTableActions"
import { useHistory } from "react-router-dom"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { LocationState, TipState } from "@models/Location"
import { validateEmail } from "@helpers/utilsHelper"
import { TextInput } from "@components/textInput/TextInput"
import { ScreenHeading } from "@components/screenHeading/ScreenHeading"
import { useBrand } from "@hooks/useBrand"
import { ButtonIcon } from "@components/buttons/ButtonIcon"
import { theme } from "config/theme"
import { Venue } from "@models/Venue"
import { OrderFromTableActionTypes } from "@store/redux/actionTypes"
import { Close } from "@material-ui/icons"
import QRCode from "react-qr-code"
import { AppParagraph5 } from "@components/Text/AppText"
interface Props {
  open: boolean
  routeState: LocationState
  venueId?: string
  tableId?: string
  typography?: string
  totalAmount?: number
  payload?: any
  tip?: TipState
  venueDetail?: Venue
  isSquareSecurePayment: boolean
  isFreedompay3d: boolean
}

const InvoiceModel = ({
  open = false,
  venueId = "",
  tableId = "",
  totalAmount = 0,
  payload = null,
  routeState,
  tip,
  venueDetail,
  isSquareSecurePayment,
  isFreedompay3d,
}: Props) => {
  const isRatingEnabled = venueDetail?.ratings?.isRatingEnabled
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    receiptId,
    createdOrder,
    tbai,
    qrUrl,
    programmedOrderDate = "",
  } = useSelector(({ orderFromTable }: RootState) => orderFromTable)
  const [form, setForm] = useState({ email: "" })
  const userLocale = localStorage.getItem("locale") || "es"
  const menuLanguage = Number(localStorage.getItem("menuLanguage")) || 1
  const isScheduledOrder =
    programmedOrderDate && venueDetail && venueDetail.isScheduleOrdersEnabled

  useEffect(() => {
    if (
      !venueDetail?.isTicketBaiEnabled ||
      !venueDetail?.ticketBai ||
      !createdOrder?.createdOrder ||
      isScheduledOrder
    )
      return
    dispatch(
      getTicketBai({
        orderId: createdOrder?.createdOrder?._id,
      }),
    )
  }, [createdOrder, venueDetail])

  const handleChange = (e) => {
    const { target: input } = e
    let newForm = { ...form }
    newForm[input.name] = input.value
    setForm(newForm)
  }
  const { languageStrings } = useLanguageHelper()

  const handleSubmit = () => {
    const _payload = {
      ...payload,
      tip,
    }
    dispatch(
      sendInvoiceEmail(
        form.email,
        history,
        tableId,
        venueId,
        routeState,
        totalAmount,
        _payload,
        receiptId,
        createdOrder,
        venueDetail,
        userLocale,
        menuLanguage,
        isSquareSecurePayment,
        isFreedompay3d,
      ),
    )
  }
  const validate = validateEmail(form.email)
  const closeModal = () => {
    if (isRatingEnabled) {
      dispatch({
        type: OrderFromTableActionTypes.SET_SHOW_INVOICE_MODE_SUCCESS,
        payload: {
          showModel: false,
          isPosPayment: false,
          receiptId,
          data: null,
        },
      })
      history.push("/rating")
    } else {
      showInvoiceModel(
        dispatch,
        history,
        false,
        payload,
        false,
        false,
        "",
        null,
        isSquareSecurePayment,
        isFreedompay3d,
      )
    }
  }
  return (
    <CustomModal showModal={open} onCloseModal={closeModal}>
      <Box
        className={
          "flex flex-col items-center w-11/12 md:w-2/5 bg-white rounded px-8 pb-8 pt-2 md:pb-16 md:pt-2"
        }
      >
        <Close
          onClick={closeModal}
          className={"m-0 self-end cursor-pointer"}
          style={{ color: theme.main.primary }}
        />

        <Box className={"w-full flex flex-col"}>
          <ScreenHeading
            containerStyle={styles.container}
            heading={languageStrings["NEED_RECEIPT"]}
            style={styles.heading}
            subHeading={languageStrings["NEED_RECEIPT_SUB"]}
            textStyle={{
              width: "100%",
              alignSelf: "flex-start",
            }}
          />
          {tbai && qrUrl && (
            <div className="flex flex-col items-center justify-center gap-2">
              <AppParagraph5
                style={{
                  color: theme.main.blackLight,
                  wordBreak: "break-all",
                  textAlign: "center",
                }}
              >
                {tbai}
              </AppParagraph5>
              <QRCode
                size={256}
                style={{ height: "150px", maxWidth: "100%", width: "100%" }}
                value={qrUrl}
                viewBox={`0 0 256 256`}
              />
            </div>
          )}
          <div className={"flex flex-col"}>
            <TextInput
              inputName={"email"}
              label={languageStrings["INVOICE_MODEL.EMAIL"]}
              inputType="email"
              onValueChange={handleChange}
              style={{
                marginTop: 10,
              }}
              value={form?.email || ""}
            />

            {!validate && form.email.length !== 0 && (
              <div className="flex flex-col px-12 -ml-4 w-full items-start self-center md:w-6/12">
                <p
                  className="text-left -ml-8"
                  style={{ color: theme.main.newOrange }}
                >
                  {languageStrings["INVALID_EMAIL_ERROR"]}
                </p>
              </div>
            )}
            <div className={"flex flex-col mt-20 sm:flex-row"}>
              <ButtonIcon
                title={languageStrings["INVOICE_MODEL.REQUEST_INVOICE"]}
                onClick={handleSubmit}
                disabled={!validate}
                style={{
                  borderRadius: "25px",
                  fontSize: "12px",
                  backgroundColor: validate
                    ? theme.main.newOrange
                    : theme.main.grey,
                  border: "0px",
                  marginBottom: "0.8rem",
                }}
                textStyle={{
                  color: validate ? theme.main.white : theme.main.darkBlack,
                }}
              />
              <ButtonIcon
                title={languageStrings["GENERAL.CANCEL"]}
                style={{
                  display: "flex",
                  borderRadius: "25px",
                  fontSize: "12px",
                  backgroundColor: "transparent",
                  border: `1px solid ${theme.main.newOrange}`,
                }}
                textStyle={{
                  color: theme.main.newOrange,
                }}
                onClick={closeModal}
              />
            </div>
          </div>
        </Box>
      </Box>
    </CustomModal>
  )
}

export default InvoiceModel
const styles = {
  heading: {
    width: "100%",
  },
  container: {
    marginTop: 0,
  },
}
