import React, { useState, useEffect, useRef, useCallback } from "react"
import "react-credit-cards/es/styles-compiled.css"
import { useHistory, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash"
import { Body13 } from "@components/Text/Text"
import CustomLoader from "@components/Loaders/CustomLoader"
import { useStripe } from "@stripe/react-stripe-js"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { clientSetting } from "config"
import History from "@history"
import {
  getAmount,
  PaymentMethods,
  PaymentType,
  SocialPaymentEnum,
  SocialPaymentTypes,
  venueOrderType,
} from "@helpers/utilsHelper"
import { PaymentSelection } from "./PaymentSelection"
import LockIcon from "@material-ui/icons/Lock"
import { TipModal } from "@components/modals/TipModal"
import { LocationState, PaymentMethodState, TipState } from "@models/Location"
import InvoiceModel from "@components/modals/InvoiceModel"
import { toast } from "react-toastify"
import {
  payVirtualBasket,
  payByCashOrder,
  getUserCards,
  createUserCards,
  socialPayVirtualBasket,
} from "@store/orderFromTable/OrderFromTableActions"
import SocialPayment from "./SocialPay"
import { useSocialButtons } from "./useSocialButtons"
import BackButton from "@components/backButton/BackButton"
import { theme } from "config/theme"
import { ScreenHeading } from "@components/screenHeading/ScreenHeading"
import { PriceButtonV2 } from "@components/buttons/PriceButton"
import { useBrand } from "@hooks/useBrand"
import { AppHeading4 } from "@components/Text/AppText"
import { getVenueDetail } from "@store/venues/VenueActions"
import { validateCartItemsAvailability } from "@store/cart/cartActions"
import moment from "moment"
import FreedomPaySocial from "./FreedomPaySocial"
import useFreedompay from "./useFreedompay"
import useRedsys from "./useRedsys"

const BasketPayment = (props) => {
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const restaurantPermalink = useSelector(
    ({ venues }: any) => venues?.restaurant?.settings?.permalink,
  )
  const dispatch = useDispatch()
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const { isVirtualBasket, isPayAtTable, autoServices } =
    venueOrderType(venueDetail)
  const userCart = useSelector(({ cart }: RootState) => cart)

  const {
    loading = false,
    showInvoiceModel,
    orderFromTable,
    isPosPayment,
    programmedOrderDate = "",
    orderClientName = "",
    orderClientAddress = "",
  } = useSelector(({ orderFromTable }: RootState) => orderFromTable)
  const isLoading = loading || userCart.loading
  const isScheduledOrder =
    programmedOrderDate && venueDetail && venueDetail.isScheduleOrdersEnabled

  const { loyaltyClient } = useSelector(({ user }: RootState) => user)
  const { primaryColor, secondaryColor } = useBrand()

  const isTipEnabled = venueDetail && venueDetail.tip === true ? true : false
  const { languageStrings } = useLanguageHelper()
  const [showSaveCard, setShowSaveCard] = useState<boolean>(false)

  const typography = primaryColor

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodState>({
    payments: [PaymentType.CARD],
    selectedPayment: PaymentType.NONE,
  })

  const [showPaymentSocial, setPaymentSocial] = useState<boolean>(false)
  const [generalLoading, setGeneralLoading] = useState<boolean>(false)
  const stripe = useStripe()
  const [state, setState] = useState({
    cvc: "",
    expiry: "",
    name: "",
    number: "",
    spaces_number: "",
    month: "01",
    year: "2",
  })
  const { isPaid = false } = props.match.params || {}
  const cart = useSelector(({ cart }: RootState) => cart.cart)
  const cartItems = cart.items
  const isPaidRef = useRef(isPaid)
  const cardButtonRef = useRef<any>(null)
  const history = useHistory()
  const location = useLocation<LocationState>()
  const [tip, setTip] = useState<TipState>({
    type: 1,
    amount: 0,
    percantage: 0,
    title: "",
  })
  const [tipForInvoice, setTipForInovice] = useState<TipState>({
    type: 1,
    amount: 0,
    percantage: 0,
    title: "",
  })
  const routeState = location.state ? location.state : {}
  const [check, setCheck] = useState<boolean>(false)
  const [card, setCard] = useState(null)
  const [payments, setPayments] = useState<any>(null)
  const [squarePaymentLoading, setSquarePaymentLoading] =
    useState<boolean>(false)
  const [squareSocialPayLoading, setSquareSocialPayLoading] =
    useState<boolean>(false)
  const [isSquareSecurePayment, setIsSquareSecurePayment] =
    useState<boolean>(false)
  const cartLoading = useSelector(({ cart }: RootState) => cart.loading)
  const {
    payload = null,
    tableId = "",
    amount = 0,
    venueId = "",
    total = 0,
  } = routeState
  const [invoiceTotal] = useState(total)
  const isFreedompay3dRef: any = useRef()
  const isLoyaltyClient = venueDetail && venueDetail.isLoyaltyEnabled

  const {
    freedompayLoading,
    setFreedompayLoading,
    freedomPayValidation,
    isApplePaySupported,
    getFreedompayPaymentKeys,
    freedomPaySocialPayment,
    handleMessage,
  } = useFreedompay()

  const { receiveMessage, generateOrderId } = useRedsys()
  const merchantOrder = useRef(generateOrderId())

  const setSelectedPayment = (method) => {
    if (showTipModal) return null
    const _payemntMethod = { ...paymentMethod }
    if (!paymentMethod.payments.includes(method)) {
      _payemntMethod.payments.push(method)
    }

    _payemntMethod.selectedPayment = method
    setPaymentMethod(_payemntMethod)
  }
  const _tip = isTipEnabled ? tip : undefined

  const { amountInString = "0.0", amountInNumber = 0 } = getAmount(amount)
  const { paymentRequest } = useSocialButtons(
    amount,
    tableId,
    venueId,
    setSelectedPayment,
    routeState,
    payload,
    _tip,
    loyaltyClient,
    orderClientName,
    orderClientAddress,
    isScheduledOrder,
  )

  const [showTipModal, setShowTipModal] = useState(false)
  const menuDetail = useSelector(({ menu }: RootState) => menu.menuList)
  const payByCash = paymentMethod.selectedPayment === PaymentType.CASH

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (isPosPayment === true) {
      history.goBack()
    }
  }, [isPosPayment])

  useEffect(() => {
    if (isPaid === "true" && !isVirtualBasket && !isPayAtTable) {
      History.push("/orderConfirmed")
    } else if (
      isPaid === "true" &&
      (isVirtualBasket || isPayAtTable) &&
      orderFromTable?._id &&
      restaurantPermalink &&
      clientSetting.isCheck === true
    ) {
      History.push(
        `/${restaurantPermalink}/virtualbasketdetails/${orderFromTable._id}/${venueDetail._id}`,
      )
    }
  }, [])

  useEffect(() => {
    isPaidRef.current = props.match.params?.isPaid || false
  }, [isPaid])
  useEffect(() => {
    if (
      (autoServices && routeState?.payload?.email) ||
      routeState?.payload?.whatsapp
    ) {
      const payload = {
        email: routeState?.payload?.email || "",
        phoneNumber: routeState?.payload?.whatsapp || "",
      }
      dispatch(getUserCards(payload))
    }
  }, [autoServices, routeState?.payload?.email, routeState?.payload?.whatsapp])
  useEffect(() => {
    return () => {
      if (
        history &&
        history.action === "POP" &&
        isPaidRef.current === "true" &&
        !isVirtualBasket &&
        !isPayAtTable
      ) {
        History.push("/orderConfirmed")
      }
    }
  }, [history])
  useEffect(() => {
    dispatch(getVenueDetail(venueId))
  }, [venueId])

  const checkCardError = () => {
    if (
      venueDetail?.squareAccessToken &&
      venueDetail?.paymentProvider === PaymentMethods.SQUARE
    ) {
      if (!card) return true
    } else if (venueDetail?.paymentProvider === PaymentMethods.FREEDOMPAY) {
      return Object.values(freedomPayValidation).some((value) => value === true)
    } else if (
      state.number.length !== 16 ||
      !state.month ||
      !state.year ||
      state.cvc.length !== 3
    ) {
      return true
    }
    return false
  }

  const canBeSubmited = () => {
    let _tipAMount = 0
    if (tip && tip.amount) _tipAMount = tip.amount
    if (payByCash && !generalLoading) return true
    return (
      !checkCardError() &&
      isPaid != "true" &&
      amountInNumber > 0 &&
      _tipAMount >= 0 &&
      !squarePaymentLoading &&
      !freedompayLoading &&
      !cartLoading &&
      !generalLoading
    )
  }

  const handleCardChange = (value, key) => {
    const _card = { ...state }
    _card[key] = value
    if (key === "expiry") {
      const [month = "", year = ""] = value.split("/")
      _card["month"] = month
      _card["year"] = year
    }
    if (key === "number") {
      const _number = value.split(" ").join("")
      _card["number"] = _number
      _card["spaces_number"] = value
    }
    setState(_card)
  }

  async function verifySquareBuyer(token: string) {
    const verificationDetails = {
      amount: amountInString,
      billingContact: {},
      currencyCode: "EUR",
      intent: "CHARGE",
    }
    const verificationResults = await payments.verifyBuyer(
      token,
      verificationDetails,
    )
    setIsSquareSecurePayment(verificationResults?.userChallenged)
    return verificationResults?.token
  }

  const tokenize = async (paymentMethod: any) => {
    const tokenResult = await paymentMethod.tokenize()
    if (tokenResult.status === "OK") {
      return tokenResult
    }
  }

  const handlePaymentMethodSubmission = async () => {
    try {
      setSquarePaymentLoading(true)
      const token = await tokenize(card)
      const verificationToken = await verifySquareBuyer(token?.token)
      return { token, verificationToken }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(
          error.message ||
            languageStrings["SOCIAL_PAYMENTS.SOMETHING_WENT_WRONG"],
        )
      } else {
        toast.error(languageStrings["SOCIAL_PAYMENTS.SOMETHING_WENT_WRONG"])
      }
      return null
    } finally {
      setSquarePaymentLoading(false)
    }
  }

  const onPay = async (
    freedompayPaymentKey?: string,
    paymentType?: string,
    redsysIdOper?: string,
    redsysOrderId?: string,
  ) => {
    if (
      venueDetail?.paymentProvider === PaymentMethods.FREEDOMPAY &&
      !freedompayPaymentKey
    ) {
      getFreedompayPaymentKeys()
      return
    }
    if (venueDetail.orderType === "2" || venueDetail.orderType === "3") {
      const menuId = cartItems[0].menuId
      if (!menuId) {
        setGeneralLoading(false)
        toast.error(languageStrings["NO_MENU_ID_ERROR"])
        return
      }
      dispatch(
        validateCartItemsAvailability(
          cartItems,
          orderFromTable?.clientId,
          programmedOrderDate,
          menuId,
          ({ isAvailable, message }) => {
            if (!isAvailable) {
              toast.error(message)
              setGeneralLoading(false)
              return
            }
            continuePayVirtualBasket(
              freedompayPaymentKey,
              paymentType,
              redsysIdOper,
              redsysOrderId,
            )
          },
        ),
      )
    } else {
      continuePayVirtualBasket(
        freedompayPaymentKey,
        paymentType,
        redsysIdOper,
        redsysOrderId,
      )
    }
  }

  const continuePayVirtualBasket = async (
    freedompayPaymentKey?: string,
    paymentType?: string,
    redsysIdOper?: string,
    redsysOrderId?: string,
  ) => {
    const clientId = restaurant && restaurant._id ? restaurant._id : ""
    if ((!restaurant._id || !venueId || !tableId) && !payload) {
      toast.error(languageStrings["NO_TABLE_ID_ERROR"])
      setGeneralLoading(false)
      return
    } else if (tip && tip.amount && tip.amount < 0) {
      toast.error(languageStrings["PAYMENT.TIP_ERROR"])
      setGeneralLoading(false)
      return
    } else if (payload) {
      if (
        !payload.clientId ||
        !payload.venueId ||
        !payload.orderTable ||
        !payload.userId
      ) {
        toast.error(languageStrings["NO_TABLE_ID_ERROR"])
        setGeneralLoading(false)
        return
      }
    }

    const frontRestOrderId =
      orderFromTable &&
      orderFromTable.frontRestData &&
      orderFromTable.frontRestData.orderId
        ? orderFromTable.frontRestData.orderId
        : ""

    const freedompaySessionKey = localStorage.getItem(
      "freedomPaymentSessionKey",
    )

    if (
      venueDetail?.paymentProvider === PaymentMethods.FREEDOMPAY &&
      freedompayPaymentKey &&
      SocialPaymentTypes.includes(paymentType ?? "")
    ) {
      await freedomPaySocialPayment(tip, freedompayPaymentKey, paymentType)
    } else {
      let cardDetails = {
        cardNumber: state.number,
        expMonth: `${Number(state.month)}`,
        expYear: `${Number(state.year)}`,
        cvc: state.cvc,
      }
      let squarePaymentToken: any = null
      if (venueDetail?.squareAccessToken) {
        squarePaymentToken = await handlePaymentMethodSubmission()
        if (squarePaymentToken?.token) {
          cardDetails = {
            cardNumber: squarePaymentToken?.token?.details?.card?.last4,
            expMonth: squarePaymentToken?.token?.details?.card?.expMonth,
            expYear: squarePaymentToken?.token?.details?.card?.expYear,
            cvc: "",
          }
        }
      } else {
        cardDetails = {
          cardNumber: state.number,
          expMonth: `${Number(state.month)}`,
          expYear: `${Number(state.year)}`,
          cvc: state.cvc,
        }
      }
      if (venueDetail?.squareAccessToken && !squarePaymentToken?.token) {
        setGeneralLoading(false)
        return
      }
      setGeneralLoading(false)

      dispatch(
        payVirtualBasket(
          tableId,
          amount,
          venueId,
          cardDetails,
          routeState,
          history,
          restaurant._id,
          payload,
          stripe,
          frontRestOrderId,
          _tip,
          loyaltyClient,
          orderClientName,
          orderClientAddress,
          isScheduledOrder,
          squarePaymentToken?.token?.token ?? "",
          squarePaymentToken?.verificationToken ?? "",
          freedompaySessionKey ?? "",
          freedompayPaymentKey ?? "",
          redsysIdOper ?? "",
          redsysOrderId ?? "",
        ),
      )
      if (autoServices && check) {
        const payload = {
          email: routeState?.payload?.email || "",
          phoneNumber: routeState?.payload?.whatsapp || "",
          cardNumber: cardDetails.cardNumber,
        }
        dispatch(createUserCards(payload))
      }
    }
  }
  const onPayCash = () => {
    if (venueDetail.orderType === "2" || venueDetail.orderType === "3") {
      const menuId = cartItems[0].menuId
      if (!menuId) {
        setGeneralLoading(false)
        toast.error(languageStrings["NO_MENU_ID_ERROR"])
        return
      }
      dispatch(
        validateCartItemsAvailability(
          cartItems,
          orderFromTable?.clientId,
          programmedOrderDate,
          menuId,
          ({ isAvailable, message }) => {
            if (!isAvailable) {
              toast.error(message)
              setGeneralLoading(false)
              return
            }
            continueOnPayCash()
          },
        ),
      )
    } else {
      continueOnPayCash()
    }
  }
  const continueOnPayCash = () => {
    if ((!restaurant._id || !venueId || !tableId) && !payload) {
      toast.error(languageStrings["NO_TABLE_ID_ERROR"])
      setGeneralLoading(false)
      return
    } else if (payload) {
      if (
        !payload.clientId ||
        !payload.venueId ||
        !payload.orderTable ||
        !payload.userId
      ) {
        toast.error(languageStrings["NO_TABLE_ID_ERROR"])
        setGeneralLoading(false)
        return
      }
    }
    const frontRestOrderId =
      orderFromTable &&
      orderFromTable.frontRestData &&
      orderFromTable.frontRestData.orderId
        ? orderFromTable.frontRestData.orderId
        : ""
    setGeneralLoading(false)
    dispatch(
      payByCashOrder(
        tableId,
        amount,
        venueId,
        routeState,
        history,
        restaurant._id,
        payload,
        frontRestOrderId,
        _tip,
        loyaltyClient,
        orderClientName,
        orderClientAddress,
        isScheduledOrder,
      ),
    )
  }

  const oncloseTipModal = () => {
    setShowTipModal(false)
    setTipForInovice(
      isTipEnabled
        ? tip
        : {
            amount: 0,
            percantage: 0,
            title: "",
            type: 1,
          },
    )

    setTip({
      type: 1,
      amount: 0,
      percantage: 0,
      title: "",
    })
  }

  const SocialPayButton = ({
    isPaymentOnTipModal = false,
  }: {
    isPaymentOnTipModal?: boolean
  }) => {
    return (
      <SocialPayment
        paymentMethod={paymentMethod}
        paymentRequest={paymentRequest as any}
        show={true}
        routeState={routeState}
        payload={payload}
        venueId={venueId}
        tableId={tableId}
        amount={amountInNumber}
        isPaid={isPaid}
        tip={tip}
        isTipEnabled={isTipEnabled && !showTipModal}
        onOpenTipModal={(val) => {
          setPaymentSocial(true)
          setTip({
            type: 1,
            amount: Number(amountInNumber) * 0.05,
            percantage: 5,
            title: "5",
          })
          setShowTipModal(val)
        }}
        setSelectedPayment={setSelectedPayment}
        isPaymentOnTipModal={isPaymentOnTipModal}
        setSquareSocialPayLoading={setSquareSocialPayLoading}
        isApplePaySupported={isApplePaySupported}
      />
    )
  }
  const _isDisabled = !canBeSubmited()

  const onCheckTipProcess = () => {
    onPay()
    oncloseTipModal()
  }

  const messageHandler = (e) => {
    if (venueDetail.paymentProvider === PaymentMethods.FREEDOMPAY) {
      handleMessage(e, setGeneralLoading, setState, onPay, isFreedompay3dRef)
    } else if (venueDetail.paymentProvider === PaymentMethods.REDSYS) {
      receiveMessage(e, merchantOrder.current, onPay)
    }
  }

  useEffect(() => {
    window.addEventListener("message", messageHandler)
    return () => {
      window.removeEventListener("message", messageHandler)
    }
  }, [])

  return (
    <>
      <CustomLoader
        loading={
          loading ||
          (paymentMethod?.selectedPayment === "CARD_PAYMENTS" &&
            venueDetail?.squareAccessToken &&
            !card) ||
          squareSocialPayLoading ||
          freedompayLoading
        }
      >
        <BackButton isMobile={true}>
          <ScreenHeading
            heading={languageStrings["SELECT_PAYMENT_METHOD"]}
            style={styles.heading}
            subHeading={languageStrings["CHOOSE_PAYMENT_METHOD"]}
          />
          {venueDetail?.paymentProvider === PaymentMethods.FREEDOMPAY &&
            isPaid != "true" && (
              <div className="w-screen px-4">
                <FreedomPaySocial
                  amount={amountInString}
                  paymentType={SocialPaymentEnum.GOOGLE}
                />
              </div>
            )}
          <div className="flex flex-col py-16 w-full">
            <PaymentSelection
              socialButtons={<SocialPayButton />}
              paymentMethod={paymentMethod}
              handleCardChange={handleCardChange}
              state={state}
              availableMethods={setSelectedPayment}
              autoServices={autoServices}
              setShowSaveCard={setShowSaveCard}
              showSaveCard={showSaveCard}
              setCheck={setCheck}
              check={check}
              setCard={setCard}
              setPayments={setPayments}
              setFreedompayLoading={setFreedompayLoading}
              amount={amountInString}
              merchantOrder={merchantOrder.current}
            />
          </div>
          <div>
            <Body13
              style={{
                color: theme.main.black,
                marginLeft: "0.6rem",
                marginTop: "1.6rem",
              }}
            >
              {`${languageStrings["TERMS_CONDITION"]} `}
              <a
                style={{ color: theme.main.blackLight }}
                href="https://checkqrpay.com/legal/aviso-legal/"
                target="_blank"
              >
                <u> {languageStrings["TERMS_CONDITION2"]}</u>
              </a>
            </Body13>
          </div>
          <div className="flex flex-col mt-10 bottom-0 pb-44 w-11/12 self-center">
            {venueDetail?.paymentProvider === PaymentMethods.REDSYS ? (
              <div id="pay-button"></div>
            ) : (
              <PriceButtonV2
                // ref={cardButtonRef}
                quantity={languageStrings["GENERAL.TOTAL"]}
                price={amountInString}
                // disabled={_isDisabled}
                titleStyle={{
                  color: _isDisabled ? theme.main.black : theme.main.white,
                  textTransform: "none",
                }}
                priceStyle={{
                  color: theme.main.white,
                  textTransform: "none",
                }}
                quantityStyle={{
                  color: theme.main.white,
                  textTransform: "none",
                }}
                title={languageStrings["GENERAL_PAGAR"]}
                backgroundColor={
                  _isDisabled ? theme.main.grey : theme.main.newOrange
                }
                classes={"w-full self-center h-44  px-8 rounded-lg"}
                onClick={(e) => {
                  setGeneralLoading(true)
                  if (isScheduledOrder) {
                    const isValidDate = moment(
                      programmedOrderDate,
                      moment.ISO_8601,
                      true,
                    ).isValid()

                    if (isValidDate) {
                      const storedDate = moment(programmedOrderDate)

                      // Current date and time
                      const currentDate = moment()

                      // Check if the stored date is in the past
                      if (storedDate.isBefore(currentDate)) {
                        toast.error(languageStrings["ERROR_DATE_PAST"])
                        return
                      }
                    }
                  }
                  if (canBeSubmited()) {
                    setPaymentSocial(false)
                    if (isTipEnabled && !payByCash) {
                      setTip({
                        type: 1,
                        amount: Number(amountInNumber) * 0.05,
                        percantage: 5,
                        title: "5",
                      })
                      setShowTipModal(true)
                    } else if (payByCash) {
                      onPayCash()
                    } else {
                      onPay()
                    }
                  } else {
                    setGeneralLoading(false)
                  }
                }}
              />
            )}

            <div className="flex justify-center my-12 items-center">
              <LockIcon className="text-grey-dark text-center mr-6" />
              <AppHeading4
                style={{
                  color: theme.main.blackLight,
                }}
              >
                {languageStrings["SECURE_PAYMENTS"]}
              </AppHeading4>
            </div>
          </div>
        </BackButton>
      </CustomLoader>

      {showInvoiceModel ? (
        <InvoiceModel
          routeState={routeState}
          totalAmount={invoiceTotal}
          venueId={venueId}
          tableId={tableId}
          open={showInvoiceModel || false}
          typography={typography}
          payload={payload}
          tip={tipForInvoice}
          venueDetail={venueDetail}
          isSquareSecurePayment={isSquareSecurePayment}
          isFreedompay3d={isFreedompay3dRef.current}
        />
      ) : null}

      <TipModal
        routeState={routeState}
        paymentMethod={paymentMethod}
        isPaid={isPaid}
        tipDetail={tip}
        showModal={showTipModal}
        onTipSelect={(e) => setTip(e)}
        onCloseModal={oncloseTipModal}
        typography={typography}
        onCheckTipProcess={onCheckTipProcess}
      >
        {showPaymentSocial ? <SocialPayButton isPaymentOnTipModal /> : null}
      </TipModal>
    </>
  )
}

export default BasketPayment
const styles = {
  heading: {
    marginLeft: "0.6rem",
  },
}
