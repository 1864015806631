import React, { useState } from "react"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { PaymentType } from "@helpers/utilsHelper"
import { PaymentMethodState } from "@models/Location"
import { useBrand } from "@hooks/useBrand"
import { theme } from "config/theme"
import { CardSelectionModal } from "@components/modals/CardSelectionModal"
import { useSelector } from "react-redux"
import CardInputs from "./CardInputs"
interface Props {
  paymentMethod: PaymentMethodState
  socialButtons?: React.ReactNode
  autoServices?: boolean
  state: {
    cvc: string
    expiry: string
    name: string
    number: string
    spaces_number: string
    month: string
    year: string
  }
  handleCardChange: (event: any, key: string) => any
  availableMethods: (val: PaymentType) => any
  setShowSaveCard?: (val: boolean) => any
  showSaveCard?: boolean
  check?: boolean
  setCheck?: (val: boolean) => any
  setCard?: any
  setPayments?: any
  setFreedompayLoading?: (val: boolean) => any
  amount?: string
  merchantOrder?: string
}

export const PaymentSelection = ({
  state,
  paymentMethod,
  handleCardChange,
  availableMethods = (method = PaymentType.CARD) => {},
  socialButtons = null,
  autoServices,
  setShowSaveCard,
  showSaveCard,
  check,
  setCheck,
  setCard,
  setPayments,
  setFreedompayLoading,
  amount,
  merchantOrder,
}: Props) => {
  const { languageStrings } = useLanguageHelper()
  const userCards = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable.userCards,
  )
  const data = userCards && userCards.length ? userCards : []
  const { selectedPayment } = paymentMethod
  const { isCashAvailable } = useBrand()
  const isCard = selectedPayment === PaymentType.CARD
  const isCash = selectedPayment === PaymentType.CASH
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isSetNewCard, setIsSetNewCard] = useState<boolean>(false)

  return (
    <>
      <div className="flex flex-row justify-center items-center px-6 w-full self-center">
        {socialButtons}
        {isCashAvailable && (
          <div
            style={{
              height: "15vh",
            }}
            className="border border-solid border-custom-grey w-full rounded-lg mx-1"
          >
            <button
              type="button"
              className="w-full h-full p-0 border-0 rounded-lg"
              onClick={() => availableMethods(PaymentType.CASH)}
            >
              {isCash ? (
                <div
                  style={{
                    backgroundColor: theme.main.newOrange,
                  }}
                  className="flex flex-col h-full rounded-lg justify-center items-center text-white"
                >
                  <img
                    src={require("@assets/selected_payment.png")}
                    className="h-1/3 max-h-10vh"
                  />
                  <h3>{languageStrings["PAY_IN_CASH"]}</h3>
                </div>
              ) : (
                <>
                  <div className="h-3/5 justify-center items-center flex">
                    <img
                      src={require("@assets/cash_payment_icon.png")}
                      className="h-3/4 max-h-10vh"
                    />
                  </div>
                  <div className="h-2/5 border-solid border-0 border-t border-custom-grey rounded-b-lg bg-white justify-center items-center flex">
                    <h3>{languageStrings["PAY_IN_CASH"]}</h3>
                  </div>
                </>
              )}
            </button>
          </div>
        )}
        <div
          style={{
            height: "15vh",
          }}
          className="border border-solid border-custom-grey w-full rounded-lg mx-1"
        >
          <button
            type="button"
            className="w-full h-full p-0 border-0 rounded-lg"
            onClick={() => {
              if (data && data.length) {
                setShowModal(true)
              }
              availableMethods(PaymentType.CARD)
            }}
          >
            {isCard ? (
              <div
                style={{
                  backgroundColor: theme.main.newOrange,
                }}
                className="flex flex-col h-full rounded-lg justify-center items-center text-white"
              >
                <img
                  src={require("@assets/selected_payment.png")}
                  className="h-1/3 max-h-10vh"
                />
                <h3>{languageStrings["CREDIT_CARD"]}</h3>
              </div>
            ) : (
              <>
                <div className="h-3/5 justify-center items-center flex">
                  <img
                    src={require("@assets/credit_card_icon.png")}
                    className="h-3/4 max-h-10vh"
                  />
                </div>
                <div className="h-2/5 border-solid border-0 border-t border-custom-grey rounded-b-lg bg-white justify-center items-center flex">
                  <h3>{languageStrings["CREDIT_CARD"]}</h3>
                </div>
              </>
            )}
          </button>
        </div>
      </div>
      {isCard ? (
        <CardInputs
          state={state}
          paymentMethod={paymentMethod}
          handleCardChange={handleCardChange}
          autoServices={autoServices}
          showSaveCard={showSaveCard}
          check={check}
          setCheck={setCheck}
          setCard={setCard}
          setPayments={setPayments}
          setFreedompayLoading={setFreedompayLoading}
          amount={amount}
          isSetNewCard={isSetNewCard}
          merchantOrder={merchantOrder}
        />
      ) : null}
      <CardSelectionModal
        onCardSelect={(value: any) => {
          if (value) {
            handleCardChange(value, "number")
            setCheck?.(true)
            setIsSetNewCard(true)
          }
          setShowModal(false)
        }}
        onNewCardPress={() => {
          if (setShowSaveCard) {
            setCheck?.(false)
            handleCardChange("", "number")
            setShowSaveCard(true)
            setIsSetNewCard(false)
          }
        }}
        showModal={showModal}
        onCloseModal={() => {
          setShowModal(false)
        }}
      />
    </>
  )
}
