export default {
  "ADDRESS.CHOOSE_SHOPPING_ADDRESS": "Choisissez l’adresse de livraison ",
  "ADDRESS.ADD_ADDRESS": "Ajouter une adresse ",
  "ADDRESS.CHOOSE_PAYMENT_METHOD": "Choisir le mode de paiement",
  "ADDRESS.ADD_CARD": "Ajouter une carte",
  "ADDRESS.PAY_BY_CARD": "Paiement par carte ",

  "BASKET.ENTER_YOUR_ADDRESS": "Entrez votre adresse",
  "BASKET.ADDRESS": "Adresse",
  "BASKET.POSTAL_CODE": "Code Postal",
  "BASKET.CITY": "Ville",
  "BASKET.SPECIAL_DETAIL": "Détails spéciaux (facultatif)",
  "BASKET.SELECT_ADDRESS": "Sélectionner l’adresse",
  "BASKET.CREATE": "Créer une adresse ",
  "BASKET.NAME": "Nom",
  "BASKET.MY_ORDER": "Ma commande ",
  "BASKET.DO_YOU_HAVE_A_DISCOUNT_CODE": "Avez-vous un code de réduction?",
  "BASKET.DISCOUNT_CODE": "Code de réduction",
  "BASKET.APPLY": "Postuler",
  "BASKET.THE_DISCOUNT_CODE_IS_CURRECT_BUT_MINIMUM_AMOUNT_TO_REQUEST_IS_IT":
    "Le code de réduction est correct mais le montant minimum pour le demander l’est ",
  "BASKET.COUPON_NOT_AVAILABLE_FOR_THIS_TYPE_OF_ORDER":
    "Coupon non disponible pour ce type de commande.",
  "BASKET.THE_DISCOUNT_CODE_DOES_NOT_EXIST":
    "Le code de réduction n’existe pas",
  "BASKET.DISCOUNT_CODE_ALREADY_USER": "Code de réduction appliqué",
  "BASKET.HOME_DELIVERY": "Livraison à domicile",
  "BASKET.SHOPPING_ADDRESS": "Adresse de livraison",
  "BASKET.CHOOSE_ADDRESS": "Choisir l’adresse",
  "BASKET.ADD_NEW_ADDRESS": "Ajouter une nouvelle adresse ",
  "BASKET.MINIMUM_ORDER": "Commande minimum",
  "BASKET.MAKE_AN_ORDER": "Passer commande",
  "BASKET.MY_ADDRESS": "Mes adresses",
  "BASKET.PAYMENT_METHOD": "Mode de paiement",
  "BASKET.ADD_NEW_CARD": "Ajouter une nouvelle carte",
  "BASKET.N_ORDER": "Commande N",
  "BASKET.EARRING": "En attente",

  "FORGET_PASSWORD.PASSWORD_MUST_BE_MORE_THEN_6_CHARACTERS":
    "Le mot de passe doit comporter plus de 6 caractères",
  "FORGET_PASSWORD.CONFIRM_PASSWORD_MUST_BE_MORE_THEN_6_CHARACTERS":
    "Confirmer que le mot de passe doit comporter plus de 6 caractères",
  "FORGET_PASSWORD.PASSWORD_AND_CONFIRM_PASSWORD_MUST_BE_THE_SAME":
    "Le mot de passe et le mot de passe de confirmation doivent être identiques",
  "FORGET_PASSWORD.RESET_YOUR_PASSWORD": "Réinitialiser votre mot de passe",
  "FORGET_PASSWORD.THE_TOKEN_HAS_EXPIRED": "Le jeton a expiré",
  "FORGET_PASSWORD.NEW_PASSWORD": "Nouveau mot de passe",
  "FORGET_PASSWORD.CONFIRM_PASSWORD": "Confirmer le mot de passe",
  "FORGET_PASSWORD.LOGIN": "SE CONNECTER",

  "HOME.THERE_ARE_NO_MENUES_FOR_THIS_RESTAURANT":
    "Il n’y a pas de menus pour ce restaurant",
  "HOME.SELECT_MENU": "Sélectionner le menu",

  "LOGIN.PLEASE_ENTER_A_VALID_EMAIL": "Veuillez saisir un e-mail valide",
  "LOGIN.EMAIL": "Email",
  "LOGIN.PASSWORD": "Mot de passe",
  "LOGIN.RECOVER_PASSWORD": "Récupérer le mot de passe?",
  "LOGIN.YOU_DON'T_HAVE_AN_ACCOUNT_EASILY_CREATE_IT":
    "Vous n’avez pas de compte, créez-en un facilement",
  "LOGIN.SIGN_IN_WITH_GOOGLE": "Se connecter avec Google",
  "LOGIN.ARE_YOU_SURE_YOU_WANT_TO_RECOVER_YOUR_PASSWORD":
    "Êtes-vous sûr de vouloir récupérer votre mot de passe?",

  "MENU_ITEM.ADD_TO_ORDER": "Ajouter à la commande",

  "PAYMENT.PAYMENT_METHOD": "Mode de paiement ",
  "PAYMENT.NAME": "Nom",
  "PAYMENT.NUMBER": "Nombre",
  "PAYMENT.CVC": "CVC",
  "PAYMENT.MAKE_PAYMENT": "Effectuer le paiement ",
  "PAYMENT.TIP_ERROR": "Le montant du pourboire ne peut pas être inférieur à 0",

  "PROFILE.PROFILE": "Profil",
  "PROFILE.EDIT_PROFILE": "Modification du profil",
  "PROFILE.CHANGE_PASSWORD": "Modifier le mot de passe",
  "PROFILE.MY_ORDERS": "Mes commandes",
  "PROFILE.LEAVE": "Sortir",
  "PROFILE.TO_CALCEL_AN_ORDER": "Annuler la commande",
  "PROFILE.PASSWORD": "Mot de passe",
  "PROFILE.PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS":
    "Le mot de passe doit comporter plus de 5 caractères",
  "PROFILE.CONFIRM_PASSWORD": "Confirmer le mot de passe",
  "PROFILE.CONFIRM_PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS":
    "Confirmer que le mot de passe doit comporter plus de 5 caractères",
  "PROFILE.MY_ORDER": "Ma commande",
  "PROFILE.GRAND_TOTAL": "Grand Total",
  "PROFILE.SHIPPING_ADDRESS": "Adresse de livraison ",
  "PROFILE.ORDER_PLACED": "Commande réalisé",
  "PROFILE.CONFIRMED": "Confirmé",
  "PROFILE.IN_KITCHEN": "En cuisine",
  "PROFILE.IN_SHOPPING": "Expédition",
  "PROFILE.PICK_UP_LIST": "Prêt à ramasser ",
  "PROFILE.COMPLETED": "Terminé",
  "PROFILE.TO_CANCEL_AN_ORDER": "Annuler la commande",
  "PROFILE.NAME": "Nom",
  "PROFILE.SURNAME": "Noms de famille",
  "PROFILE.DATE_OF_BIRTH": "Date de naissance",
  "PROFILE.PHONE": "Teléphone",
  "PROFILE.SAVE_CHANGES": "Enregistrer les modifications",

  "SIGNUP.PLEASE_ENTER_A_VALID_EMAIL": "Veuillez saisir un e-mail valide",
  "SIGNUP.THE_PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS":
    "Le mot de passe doit comporter plus de 5 caractères.",
  "SIGNUP.CONFIRM":
    "Confirmer que le mot de passe doit comporter plus de 5 caractères",
  "SIGNUP.PASSWORD_AND_CONFIRM_PASSWORD_MUST_BE_THE_SAME":
    "Le mot de passe et le mot de passe de confirmation doivent être identiques",
  "SIGNUP.NAME": "Nom",
  "SIGNUP.SURNAME": "Noms de famille",
  "SIGNUP.EMAIL": "Email",
  "SIGNUP.PASSWORD": "Mot de passe",
  "SIGNUP.CONFIRM_PASSWORD": "Confirmer le mot de passe",
  "SIGNUP.I_ACCEPT_THE_TERMS_AND_CONDITIN":
    "J’accepte les termes et conditions",
  "SIGNUP.DO_YOU_ALREADY_HAVE_AN_ACCOUNT_TRY_TO_ACCESS_WITH_IT":
    "Avez-vous déjà un compte ? Essayez d’y accéder avec",

  "SOCUAL_PAYMENT.ENTER_EMAIL_ADDRESS": "Entrez l’e-mail",

  "VENUE_DETAIL.THERE_ARE_NO_MENUES_FOR_THIS_RESTAURANT":
    "Il n’y a pas de menus pour ce restaurant",
  "VENUE_DETAIL.HOW_DO_YOU_WANT_TO_ORDER":
    "Comment voulez-vous votre commande?",
  "VENUE_DETAIL.SELECT_MENU": "Sélectionner le menu",

  "VIRTUAL_BASKET.YOUR_GONNA_PAY": "Vous paierez",
  "VIRTUAL_BASKET.NAME": "Nom",
  "VIRTUAL_BASKET.FULL_NAME": "Nom et Prénom",
  "VIRTUAL_BASKET.NUMBER": "Numéro",
  "VIRTUAL_BASKET.CVC": "CVC",
  "VIRTUAL_BASKET.SUCCESS_PAYMENT_RECEIVED": "Paiement reçu avec succès!.",
  "VIRTUAL_BASKET.PAYMENT_PROCESSING_WE'LL_UPDATE_YOU_WHEN_PAYMENT_IS_RECEIVED":
    "Traitement du paiement. Nous vous informerons lorsque le paiement aura été reçu.",
  "VIRTUAL_BASKET.PAYMENT_FAIL.PLEASETRY_ANOTHER_PAYMENT_METHOD":
    "Échec du paiement. Veuillez utiliser un autre mode de paiement.",
  "VIRTUAL_BASKET.SOMETHING_WENT_WRONG": "Quelque chose a mal tourné",
  "VIRTUAL_BASKET.VIRTUAL_BASKET_PAYMENT": "Paiement du panier virtuel",
  "VIRTUAL_BASKET.SEE_THE_MENU": "Consulter le menu",
  "VIRTUAL_BASKET.PAY_THE_BILL": "Payer la facture",
  "VIRTUAL_BASKET.PAYMENT_METHOD": "Mode de paiement",
  "VIRTUAL_BASKET.CHOOSE_CARD": "Choisir la carte",
  "VIRTUAL_BASKET.ADD_NEW_CARD": "Ajouter une nouvelle carte",
  "VIRTUAL_BASKET.VIEW_THE_ACCOUNT": "Consulter le compte",
  "VIRTUAL_BASKET.MY_ORDER": "Ma commande",
  "VIRTUAL_BASKET.BASE_PRICE": "Prix de base",
  "VIRTUAL_BASKET.AMOUNT_PAID": "Montant payé",
  "VIRTUAL_BASKET.PENDING_AMOUNT": "Encours",
  "VIRTUAL_BASKET.DISCOUNT": "Remise",

  "GENERAL.COMMENTS": "Commentaires",
  "GENERAL.ADD": "Ajouter",
  "GENERAL.TOTAL": "Total",
  "GENERAL.SAVE": "Sauvegarder",
  "GENERAL.ACCESS": "Accès",
  "GENERAL.EITHER": "ÓU",
  "GENERAL.CONFIRM": "Confirmer",
  "GENERAL.CANCEL": "Annuler",
  "GENERAL.SEND": "Envoyer",
  "GENERAL.PAY": "Payer",
  "GENERAL.DISCOUNT": "Remise",

  "BASKET.ENTER_NAME": "Entrez le nom",
  "BASKET.FOR_EXAMPLE_HOME_WORK": "Par exemple : Travail Domicile",
  "BASKET.DISCOUNT_CODE_ALREADY_USED": "La remise a déjà été utilisée",
  "BASKET.DISCOUNT": "Rabais",
  "BASKET.BASE_PRICE": "Prix de base",
  "BASKET.CHOOSE_CARD": "Sélectionnez la carte",

  "PROFILE.DISCOUNT": "RABAIS",
  "PROFILE.SORRY_YOUR_ORDER_HAS_BEEN_CANCELED":
    "Désolé, votre commande a été annulée",
  "PROFILE.N_ORDER": "N° de commande",
  "PROFILE.ORDER_DETAIL": "Détails de la commande",

  "SIGN_UP.SIGN_UP": "S'inscrire",

  "SOCIAL_PAYMENTS.PAYMENT_SUCCEEDED": "Paiement réussi",
  "SOCIAL_PAYMENTS.YOUR_PAYMENT_IS_PROCESSING":
    "Votre paiement est en cours de traitement",
  "SOCIAL_PAYMENTS.YOUR_PAYMENTS_ARE_NOT_SUCCESFUL_PLEASE_TRY_AGAIN":
    "Votre paiement n'a pas été effectué avec succès, veuillez réessayer.",
  "SOCIAL_PAYMENTS.SOMETHING_WENT_WRONG": "Quelque chose a mal tourné",
  "SOCIAL_PAYMENTS.UNEXPETED_ERROR_OCCURED":
    "Une erreur inattendue s'est produite",
  "SOCIAL_PAYMENTS.ENTER_EMAIL_ADDRESS": "Veuillez entrer votre adresse e-mail",
  "SOCIAL_PAYMENTS.PAY_NOW": "Payez maintenant",

  "VIRTUAL_BASKET.GRAND_TOTAL": "Total général",

  "ADDRESS_CARD.OUT_OF_RANGE": "Hors de l'intervalle",
  "CUSTOM_LOADER.CHARGING": "Chargement...",
  "MOBILE_DETAIL_CARD.OUT_OF_RANGE": "Hors gamme",
  "ADD_CARD_MODEL.NAME": "Nom",
  "ADD_CARD_MODEL.NUMBER": "Numéro",
  "ADD_CARD_MODEL.CVC": "CVC",
  "ADD_PHONE_NUMBER_MODEL.PLEASE_ADD_YOUR_PHONE":
    "Veuillez ajouter votre numéro de téléphone !",
  "ADD_PHONE_NUMBER_MODEL.LEAVE_YOUR_TELEPHONE_NUMBER_TO_CONTACT_YOU_REGARDING_THE ORDER":
    "Laissez-nous votre numéro de téléphone pour que nous puissions vous contacter au sujet de votre commande.",
  "ADD_PHONE_NUMBER_MODEL.PHOME ORDER": "Téléphone",
  "ADDRESS_DETAIL_MODEL.SHOPPING_ADDRESS": "Adresse de livraison",
  "ADDRESS_DETAIL_MODEL.NAME": "Nom",
  "ADDRESS_DETAIL_MODEL.HOME_OFFICE": "Domicile, bureau...",
  "ADDRESS_DETAIL_MODEL.STREET": "Rue",
  "ADDRESS_DETAIL_MODEL.POSTAL_CADE": "Code postal",
  "ADDRESS_DETAIL_MODEL.CITY": "Ville",
  "ADDRESS_DETAIL_MODEL.TO_ACCESS_PRESS_THE_RED_BELL":
    "Pour accéder, cliquez sur la cloche rouge",
  "ADDRESS_DETAIL_MODEL.FOLLOWING": "Suivant",
  "ADDRESS_MAP_MODEL.CREATE_ADDRESS": "Créer une adresse",
  "INVOICE_MODEL.DO_YOU_NEED_AN_INVOICE_ENTER_EMAIL_TO_RECEIVE_IT":
    "Vous avez besoin d'un reçu ? Entrez votre adresse électronique pour le recevoir",
  "INVOICE_MODEL.EMAIL": "Adresse e-mail",
  "INVOICE_MODEL.REQUEST_INVOICE": "Demander un Reçu",
  "SPLIT_BILL_MODEL.AMONT_IS_MANDATORY": "Le montant est requis",
  "SPLIT_BILL_MODEL.THE_AMOUNT_EXCEEDS_THE_AMOUNT_TO_BE_PAID":
    "Le montant dépasse le montant à payer",
  "SPLIT_BILL_MODEL.PAYMENT": "Paiement",
  "SPLIT_BILL_MODEL.PAY_FULL_AMOUNT": "Payer la totalité de la facture",
  "SPLIT_BILL_MODEL.PARTIAL_PAYMENY": "Paiement partiel",
  "SPLIT_BILL_MODEL.ENTER_AMOUNT_TO_PAY": "Entrez le montant à payer",
  "SPLIT_BILL_MODEL.DECIMALS": "Saisissez un maximum de deux décimales",
  "HEADER.ORDER_COMPLETED": "Commande terminée !",
  "ITEMS_DETAILS.ORDER": "Commande",
  "ITEMS_DETAILS.BASIC_PRICE": "Prix de base",
  "ITEMS_DETAILS.SHOPPING_PRICE": "Frais de livraison",
  "ITEMS_DETAILS.DISCOUNT": "Rabais",
  "ITEMS_DETAILS.GRAND_TOTAL": "Total",

  "MOBILE_ORDER_CONFIRMED.CONGRATULATION": "Félicitations !",
  "MOBILE_ORDER_CONFIRMED.YOUR_ORDER_HAS_BEEN_SENT":
    "Votre commande a été expédiée",
  "MOBILE_ORDER_CONFIRMED.CONTINUE": "Continuer",
  "MOBILE_ORDER_CONFIRMED.POWERED_BY": "Powered by",
  "ORDER_CONFRMED_PAGE.THANK_YOU": "Merci !",
  "ORDER_CONFRMED_PAGE.CONTINUE": "Continuer",
  "RADIO_GROUP_CARD.CASH": "En espèces",

  "TABLE_CENTER.MENU": "Menu",
  "TABLE_LEFT.CATEGORIES": "Catégories",
  "TABLE_LEFT.THIS_MENU_IS_A_DEMO_GET_YOURS_FOR_FREE":
    "Ce menu est une démo, obtenez le vôtre gratuitement.",
  "TABLE_LEFT.SIGN_UP_FOR_FREE": "S'inscrire gratuitement",

  "TABLE_RIGHT.YOUR_ORDER": "Votre commande",
  "TABLE_RIGHT.MAKE_AN_ORDER": "Passer la commande",
  "TABLE_RIGHT.DO_YOU_A_DISCOUNT_CODE": "Avez-vous un code de réduction ?",
  "TABLE_RIGHT.DISCOUNT_CODE": "Code de réduction",
  "TABLE_RIGHT.APPLY_TEXT": "applyText",
  "TABLE_RIGHT.THE_DISCOUNT_CODE_DOSE_NOT_EXIST":
    "Le code de réduction n'existe pas",
  "TABLE_RIGHT.THE_DISCOUNT_CODE_ALREADY_USE": "Code de réduction déjà utilisé",
  "TABLE_RIGHT.MINIMUM_ORDER": "Commande minimale",
  "TABLE_RIGHT.HOME_DELIVERY": "Livraison",
  "TABLE_RIGHT.TOTAL": "Total",
  "TABLE_RIGHT.SUB_TOTAL": "Sous-total",
  "TABLE_RIGHT.THE_DISCOUNT_CODE_IS_CORRECT_BUT_THE_MINIMUM_AMOUNT_TO_REQUEST_IS_IT":
    "Le code de réduction est correct, mais le montant minimum de la commande est de",
  "TABLE_RIGHT.COUPONS_NOT_AVAILABLE_FOR_THIS_ORDER_TYPE":
    "Coupon non disponible pour ce type de commande",
  "TABLE_RIGHT.DISCOUNT": "Rabais",

  "VENUE_ORDER.HOW_DO_WANT_YOUR_ORDER": "Comment voulez-vous votre commande ?",
  "NOT_FOUND_PAGE.RESTORENT_NOT_FOUND": "Restaurant non trouvé",

  SEE_ORDERS: "Voir les commandes",

  ORDER_PREPARATION_TIMING_HEADER_MODEL:
    "Please enter the email to see order preparation timing",
  ORDER_PREPARATION_BUTTON: "Receipt",

  HOW_DO_YOU_WANT_TO_BE_NOTIFIED: "Comment voulez-vous être informé?",
  GENERAL_AUTOSERVICE: "Self-service",
  I_DONT_WANT_TO_BE_NOTIFIED: "Je ne veux pas être notifié",
  ENTER_YOUR_EMAIL_ADDRESS: "Veuillez entrer votre adresse e-mail",
  EMAIL_ADDRESS: "Votre adresse électronique",
  NOTIFY_ME: "Me prévenir",
  ENTER_YOUR_PHONE_NUMBER: "Veuillez entrer votre numéro de téléphone",
  PHONE_NUMBER: "Votre numéro de téléphone",
  ORDER_REF: "Référence de la commande",
  TOTAL_AMOUNT: "Montant total",
  CREATING_TIME: "Heure de création",
  "GENERAL:ORDER_IN_PROGRESS": "Commande en Cours",
  "GENERAL:ORDER_IN_PROGRESS_PARGRAPH":
    "Cliquez ici pour voir les détails de l'ordre en cours",
  RESTAURANT_BUSY: "Le restaurant est occupé en ce moment",
  STATUS: "Statut",
  PARTIAL_DELIVERY: "Livraison Partielle",
  IN_PROGRESS: "En Cours",
  "ORDER_STATUS.DELIVERED": "Terminé",
  NO_TABLE_ID_ERROR:
    "Il y a un problème avec votre table. Veuillez réessayer de scanner le QR",
  TICKET_NO: "Ticket #",
  MINIMUM_ORDER: "Commande minimum",
  SELECT_PAYMENT_METHOD: "Sélectionnez un mode de paiement",
  CONTINUE_TO_PAY: "Continuer le paiement",
  SECURE_PAYMENTS: "Votre paiement est crypté et sécurisé",
  INVALID_CARD_NUMBER: "Le numéro de la carte n'est pas valide",
  INVALID_EXPIRY_DATE: "La date d'expiration n'est pas valide",
  MONTH_RANGE: "Le mois d'expiration doit être compris entre 01 et 12",
  YEAR_RANGE: "L'année d'expiration ne peut pas être dans l'année passée",
  DATE_RANGE: "La date d'expiration ne peut pas être dans le passé",
  INVALID_CVV: "Le code de sécurité n'est pas valide",
  CREDIT_CARD: "Carte de crédit",
  ADD_TIP: "Ajout d'un pourboire",
  TIP: "Conseil",
  TIP_TITLE: "Dites merci avec un pourboire",
  TIP_REMOVE: "Supprimer un pourboire",
  OTHER: "Autre",
  ENTER_AMOUNT: "Entrez le montant",
  TERMS_CONDITION: "En continuant, j'accepte les",
  TERMS_CONDITION2: "conditions générales et la politique de confidentialité.",
  BUSY_TABLE: "La table n'accepte pas de commandes pour le moment.",
  BUSY_VENUE: "Le restaurant n'accepte pas de commandes pour le moment.",
  INVALID_EMAIL_ERROR: "Vérifier les informations fournies",
  EMPTY_NAME_ERROR: "S'il te plaît, écris ton nom",
  GENERAL_CLOSE: "Fermer",
  TOTAL_WITHOUT_PROMO: "Total sans promo",
  PAY_IN_CASH: "Paiement en espèces",
  CASH_MAKE_ORDER: "Commander",
  EMPTY_CART: "Il n'y a pas de commandes dans ce panier",
  DOWNLOAD_QR: "Télécharger le QR",
  MENU_HEADER1: "Ceci est un menu pour le",
  MENU_HEADER2: "qui est le meilleur de la ville.",
  GENERAL_ITEMS: "Articles",
  GENERAL_ITEM: "Article",
  GENERAL_CASH: "Cash",
  GENERAL_VENUE_MESSAGE:
    "Ce lieu vous informera lorsque votre commande sera prête à être retirée",
  NOTIFIED_VIA_EMAIL: "Notification par e-mai",
  SEND_EMAIL_NOTIFICATION: "Nous enverrons une notification via cet e-mail",
  SEND_WHATSAPP_NOTIFICATION: "Nous enverrons une notification par whatsapp",
  SEND_SMS_NOTIFICATION:
    "Nous enverrons une notification à ce numéro de téléphone",
  NOTIFIED_VIA_WHATSAPP: "Notification via whatsapp",
  NOTIFIED_VIA_SMS: "Notification via SMS",
  GENERAL_WHATSAPP: "Whatsapp",
  GENERAL_SMS: "SMS",
  NOTIFICATION_METHOD: "Notification via",
  CHOOSE_PAYMENT_METHOD:
    "Choisissez votre mode de paiement parmi les options disponibles",
  PAY_WITH: "Payez avec",
  CARD_NUMBER: "Numéro de carte",
  EXPIRY_DATE: "Date d'expiration",
  CVC_CODE: "CVC",
  NEED_RECEIPT: "Besoin d'un reçu ?",
  NEED_RECEIPT_SUB: "Entrez votre adresse e-mail pour le recevoir",
  POST_REVIEW: "Laissez un commentaire",
  ORDER_LIST: "Liste des commandes",
  PAY_ORDER: "Ordre de paiement",
  GENERAL_POPULAR: "Populaires",
  GENERAL_FOOD: "Alimentation",
  GENERAL_DRINK: "Boissons",
  SELECT_RATING: "Votre avis nous intéresse",
  WRITE_REVIEW: "Rédiger un commentaire",
  WITHOUT_REVIEW: "Poursuivre sans révision",
  ORDER_CONFIRMATION: "Confirmation de commande",
  GENERAL_PEDIR: "Demander",
  GENERAL_PAGAR: "Payer",
  RATING_SUBHEADING: "Notez notre établissement",
  "HOME_PAGE.SEE_THE_MENU": "Demander",
  "HOME_PAGE.SEE_THE_MENU_VB": "Voir la lettre",
  "HOME_PAGE.PAY_ORDER": "Payer",
  "HOME_PAGE.SCHEDULE_ORDER": "Planifier une commande",
  "HOME_PAGE.TAKE_AWAY": "Take Away",
  "HOME_PAGE.TAKE_AWAY_ORDER": "Ordre du calendrier",
  SECURE_STORE_CARD:
    "Chiffrer et stocker en toute sécurité les détails de ma carte pour les commandes futures.",
  VIEW_MENU: "Voir le menu",
  USE_CARD: "Utiliser la carte",
  PICKUP_TIME: "Heure ramassage",
  PICKUP_TIME_TODAY: "Aujourd'hui",
  PICKUP_TIME_TOMORROW: "Demain",
  PICKUP_TIME_CHANGE: "Modifier",
  PICKUP_TIME_CONFIRM: "Confirmer",
  PICKUP_TIME_DAY: "Jour",
  PICKUP_TIME_HOUR: "Heure",
  PICKUP_TIME_CHANGE_MESSAGE:
    "L'heure de prise en charge a été mise à jour. Vous pouvez la modifier si vous le souhaitez.",
  MENU_HIDE_MESSAGE_PART_1: "La cuisine du restaurant",
  MENU_HIDE_MESSAGE_PART_2:
    "n'est pas ouverte pour le moment. Veuillez vérifier les heures d'ouverture auprès de notre personnel",
  ADD_NEW_CARD: "Utiliser une autre carte",
  CLOSED_KITCHEN_MESSAGE:
    "Nous sommes désolés, en ce moment, nous n'acceptons pas de nouvelles commandes. Veuillez réessayer plus tard",
  AUTOSERVICE_NAME: "Nom et prénom",
  AUTOSERVICE_ADD_NAME: "Veuillez saisir votre nom",
  NOT_AVAILABLE: "Indisponible",
  FOR_FREE: "Gratuit",
  ITEMS_NOT_AVAILABLE:
    "Un ou plusieurs articles de votre panier ne sont plus disponibles",
  VIEW_CART: "Voir la carte",
  CONTINUE: "Continuer",
  ANOTHER_PRODUCT_ERROR: "Veuillez sélectionner un autre produit",
  PRODUCT_NOT_AVAILABLE: "Non disponible",
  ERROR_DATE_PAST:
    "La date sélectionnée est déjà passée. Veuillez choisir une date future",
  SELECT_LANGUAGE: "Sélectionnez une langue",
  AUTOSERVICE_ADDRESS: "Adresse",
  AUTOSERVICE_ADD_ADDRESS: "Veuillez ajouter votre adresse",
  "LANGUAGE.CHINESE": "Chinois",
  ITEM: "Article",
  ITEMS: "Articles",
  NO_MENU_ID_ERROR: "Aucun identifiant de menu trouvé",
  "LANGUAGE.NEDERLANDS": "Néerlandais",
  "LANGUAGE.SVENSKA": "Suédois",
  "LANGUAGE.DANSK": "Danois",
  "LANGUAGE.SUOMI": "Finnois",
  FILL_CARD_DETAILS: "Vous devez remplir les détails de la carte",
  CARD_MANDATORY: "La carte est obligatoire",
  CARD_NUMERIC: "La carte doit être numérique",
  CARD_NON_NEGATIVE: "La carte ne peut pas être négative",
  EXPIRATION_MONTH_MANDATORY:
    "Le mois d'expiration de la carte est obligatoire",
  EXPIRATION_MONTH_NUMERIC:
    "Le mois d'expiration de la carte doit être numérique",
  EXPIRATION_MONTH_INCORRECT: "Le mois d'expiration de la carte est incorrect",
  EXPIRATION_YEAR_MANDATORY: "L'année d'expiration de la carte est obligatoire",
  EXPIRATION_YEAR_NUMERIC:
    "L'année d'expiration de la carte doit être numérique",
  EXPIRATION_YEAR_NON_NEGATIVE:
    "L'année d'expiration de la carte ne peut pas être négative",
  SECURITY_CODE_LENGTH:
    "Le code de sécurité de la carte n'a pas la bonne longueur",
  SECURITY_CODE_NUMERIC: "Le code de sécurité de la carte doit être numérique",
  SECURITY_CODE_NON_NEGATIVE:
    "Le code de sécurité de la carte ne peut pas être négatif",
  SECURITY_CODE_NOT_REQUIRED:
    "Le code de sécurité n'est pas requis pour votre carte",
  CARD_LENGTH_INCORRECT: "La longueur de la carte n'est pas correcte",
  CARD_NUMBER_VALID:
    "Vous devez entrer un numéro de carte valide (sans espaces ni tirets)",
  MERCHANT_VALIDATION_INCORRECT: "Validation incorrecte par le commerçant",
}
