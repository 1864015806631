import React, { useEffect } from "react"
import useRedsys from "./useRedsys"

const REDSYS_URL = process.env.REACT_APP_REDSYS_URL
const Redsys = ({ merchantOrder, amount }) => {
  const { initializeRedsysFields } = useRedsys()
  useEffect(() => {
    const script = document.createElement("script")
    script.src = `${REDSYS_URL}`
    script.async = true
    document.body.appendChild(script)
    script.onload = () => initializeRedsysFields(merchantOrder, amount)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div className="redsys-form">
      <div className="input-wrapper" id="card-number"></div>
      <div className="flex items-center">
        <div className="input-wrapper" id="card-expiration"></div>
        <div className="input-wrapper" id="cvv"></div>
      </div>
      <input type="hidden" id="token" />
      <input type="hidden" id="errorCode" />
    </div>
  )
}

export default Redsys
