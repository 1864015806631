import { useLanguageHelper } from "@helpers/LanguageHelper"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

const useRedsys = () => {
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const { languageStrings } = useLanguageHelper()

  const generateOrderId = () => {
    const numericChars = "0123456789"
    const alphaNumericChars =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
    let orderId = ""
    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * numericChars.length)
      orderId += numericChars[randomIndex]
    }
    for (let i = 4; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * alphaNumericChars.length)
      orderId += alphaNumericChars[randomIndex]
    }
    return orderId
  }

  const validationMessages = {
    msg1: languageStrings["FILL_CARD_DETAILS"],
    msg2: languageStrings["CARD_MANDATORY"],
    msg3: languageStrings["CARD_NUMERIC"],
    msg4: languageStrings["CARD_NON_NEGATIVE"],
    msg5: languageStrings["EXPIRATION_MONTH_MANDATORY"],
    msg6: languageStrings["EXPIRATION_MONTH_NUMERIC"],
    msg7: languageStrings["EXPIRATION_MONTH_INCORRECT"],
    msg8: languageStrings["EXPIRATION_YEAR_MANDATORY"],
    msg9: languageStrings["EXPIRATION_YEAR_NUMERIC"],
    msg10: languageStrings["EXPIRATION_YEAR_NON_NEGATIVE"],
    msg11: languageStrings["SECURITY_CODE_LENGTH"],
    msg12: languageStrings["SECURITY_CODE_NUMERIC"],
    msg13: languageStrings["SECURITY_CODE_NON_NEGATIVE"],
    msg14: languageStrings["SECURITY_CODE_NOT_REQUIRED"],
    msg15: languageStrings["CARD_LENGTH_INCORRECT"],
    msg16: languageStrings["CARD_NUMBER_VALID"],
    msg17: languageStrings["MERCHANT_VALIDATION_INCORRECT"],
  }

  const receiveMessage = async (event, merchantOrder, onPay) => {
    if (!event.origin.includes("redsys.es")) {
      return
    }
    console.log("EVENT => ", event)
    const wind = window as any
    if (wind.storeIdOper) {
      wind.storeIdOper(event, "token", "errorCode", true)
      const token: any = document.getElementById("token")
      const tokenValue = token && token.value
      const error: any = document.getElementById("errorCode")
      const errorCodeValue = error && error.value
      console.log("Token:", tokenValue)
      console.log("Error Code:", errorCodeValue)
      const errorMessage =
        validationMessages[errorCodeValue] || "Something went wrong"
      if (errorCodeValue && errorMessage && !tokenValue) {
        toast.error(errorMessage, { toastId: "redsys-error" })
        error.value = ""
      } else if (tokenValue) {
        onPay(null, null, tokenValue, merchantOrder)
      }
    }
  }

  const initializeRedsysFields = (merchantOrder, amount) => {
    console.log("initializeRedsysFields")
    const boxStyle =
      "width: 100%; height: 40px; border-radius: 5px; border-color: #e3e3e3"
    const commonStyle =
      "width: 100%; height: 40px; border-radius: 5px; border-color: #e3e3e3"
    const commonStylesInput =
      "width: 94%; height: 40px; border-radius: 5px; padding-left: 10px; border-color: #e3e3e3"
    const buttonStyle =
      "width: 100%; height: 60px; border-radius: 50px; border-color: #e3e3e3; background-color: #FE6D4E; color: #ffffff"
    const wind = window as any
    if (
      wind.getCardInput &&
      wind.getExpirationInput &&
      wind.getCVVInput &&
      wind.getPayButton
    ) {
      console.log("INSIDE")
      wind.getCardInput("card-number", boxStyle, "Card Number", commonStyle)
      wind.getExpirationInput(
        "card-expiration",
        commonStylesInput,
        "Expiration Date",
      )
      wind.getCVVInput("cvv", commonStylesInput, "Cvv")
      wind.getPayButton(
        "pay-button",
        buttonStyle,
        `Pay ${amount}`,
        venueDetail?.redsys?.merchantCode, // merchantCode
        venueDetail?.redsys?.terminal, // terminal
        merchantOrder,
      )
    }
  }

  return { initializeRedsysFields, receiveMessage, generateOrderId }
}

export default useRedsys
