import {
  getComboDetailByIdUrl,
  getOrderMenuByIdUrl,
  getProductDetailByIdUrl,
} from "./../../@api/endPoints"
import { axiosInstance } from "@api/axios"

import { CartActionTypes, MenuActionTypes } from "@store/redux/actionTypes"
import { getAllMenuListUrl, getMenuListUrl } from "@api/endPoints"
import { getOrderMenuList } from "@store/cart/cartActions"

import Axios from "axios"
import { store } from "@store/index"
import { addExtras } from "@helpers/utilsHelper"

export const getAllMenuList = (clientId) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.GET_ALL_MENU_LIST_START,
    })

    const url = getAllMenuListUrl(clientId)

    axiosInstance
      .get(url)
      .then((res) => {
        getAllMenuListSuccess(dispatch, res.data)
        dispatch(getOrderMenuList(res.data))
      })
      .catch(() => {
        getAllMenuListFail(dispatch, "There was an error connection")
      })
  }
}

const getAllMenuListFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.GET_ALL_MENU_LIST_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getAllMenuListSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.GET_ALL_MENU_LIST_SUCCESS,
    payload: data,
  })
}

export const getMenuList = (id, type, skipCategories = false) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.GET_MENU_LIST_START,
    })
    const url = getMenuListUrl(id, type, skipCategories)
    axiosInstance
      .get(url)
      .then((res) => {
        getMenuListSuccess(dispatch, res.data)
        dispatch(getOrderMenuList(res.data))
      })
      .catch(() => {
        getMenuListFail(dispatch, "There was an error connection")
      })
  }
}

const getMenuListFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.GET_MENU_LIST_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getMenuListSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.GET_MENU_LIST_SUCCESS,
    payload: data,
  })
}

export const getMenuDetail = (id, type, date, table) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.GET_MENU_DETAIL_START,
    })
    const url = getOrderMenuByIdUrl(id, type, date, table)

    axiosInstance
      .get(url)
      .then((res) => {
        getMenuDetailSuccess(dispatch, res.data)
        dispatch(getOrderMenuList(res.data))
      })
      .catch(() => {
        getMenuDetailFail(dispatch, "There was an error connection")
      })
  }
}

export const setMenuLanguage = (source, target, q) => {
  return (dispatch) => {
    let _txtArr: string[] = []
    const _storeData = store.getState()
    const { menuTranslation = [] } = _storeData.menu || {}
    menuTranslation &&
      menuTranslation.length &&
      menuTranslation.map((item) => {
        _txtArr.push(item.translatedText)
      })

    const query = {
      format: "text",
      source,
      target,
      q: _txtArr.length ? _txtArr : q,
    }

    Axios.post(
      "https://translation.googleapis.com/language/translate/v2?key=AIzaSyDD3BEbF_rZGtnnM6uITB1irLf4qc4kbgM",
      query,
    )
      .then((res) => {
        if (res.data && res.data.data && res.data.data.translations) {
          setMenuLanguageSuccess(dispatch, res.data.data.translations)
        }
      })
      .catch((err) => console.log({ err }))
  }
}

const getMenuDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.GET_MENU_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getMenuDetailSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.GET_MENU_DETAIL_SUCCESS,
    payload: data,
  })
}

export const getAllergensList = () => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.GET_MENU_LIST_START,
    })

    const url = "/web/allergenes"

    axiosInstance
      .get(url)
      .then((res) => {
        getAllergensListSuccess(dispatch, res.data)
      })
      .catch(() => {
        getAllergensListFail(dispatch, "There was an error connection")
      })
  }
}

const getAllergensListFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.GET_ALLERGENS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getAllergensListSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.GET_ALLERGENS_LIST_SUCCESS,
    payload: data,
  })
}
const setMenuLanguageSuccess = (dispatch, data = []) => {
  dispatch({
    type: MenuActionTypes.MENU_LANGUAGE_TRANSLATION_SUCCESS,
    payload: data,
  })
}

//Get product Detail
export const getProductDetail = (id, type, date, table, categoryId) => {
  return (dispatch, getState) => {
    dispatch({
      type: MenuActionTypes.GET_PRODUCT_DETAIL_START,
    })
    dispatch({
      type: CartActionTypes.GET_PRODUCT_DETAIL_API_START,
    })
    const url = getProductDetailByIdUrl(id, type, date, table)

    axiosInstance
      .get(url)
      .then((res) => {
        const store = getState()
        const menu = store?.menu?.menuList || null
        const newItem = store?.cart?.newItem
        let newData = { ...menu }
        if (res.data?.data?.length) {
          newData = addExtras({
            apiData: res.data?.data,
            categoryId,
            id,
            key: "extraCategories",
            menu,
          })
        }
        getProductDetailSuccess(dispatch, newData, res.data?.data, newItem)
        dispatch(getOrderMenuList(res.data))
      })
      .catch(() => {
        getProductDetailFail(dispatch, "There was an error connection")
      })
  }
}
const getProductDetailSuccess = (dispatch, data, apiData, item) => {
  dispatch({
    type: MenuActionTypes.GET_PRODUCT_DETAIL_SUCCESS,
    payload: data,
  })
  const extraCategories = apiData?.length ? apiData : []

  dispatch({
    type: CartActionTypes.GET_PRODUCT_DETAIL_API_SUCCESS,
    payload: extraCategories,
  })
}
const getProductDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.GET_PRODUCT_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  })
}

//Get Combo Detail
export const getComboDetail = (id, type, date, table, categoryId) => {
  return (dispatch, getState) => {
    dispatch({
      type: MenuActionTypes.GET_COMBO_DETAIL_START,
    })
    const url = getComboDetailByIdUrl(id, type, date, table)

    axiosInstance
      .get(url)
      .then((res) => {
        const store = getState()
        const menu = store?.menu?.menuList || null
        let newData = { ...menu }

        if (res.data?.data?.length) {
          newData = addExtras({
            apiData: res.data?.data,
            categoryId,
            id,
            key: "comboCategories",
            menu,
          })
        }
        getComboDetailSuccess(dispatch, newData)
        dispatch(getOrderMenuList(res.data))
      })
      .catch(() => {
        getComboDetailFail(dispatch, "There was an error connection")
      })
  }
}
const getComboDetailSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.GET_COMBO_DETAIL_SUCCESS,
    payload: data,
  })
}
const getComboDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.GET_COMBO_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  })
}
