import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import TableIcon from "@material-ui/icons/LocalDining"
import { useHistory } from "react-router"
import { useStripe } from "@stripe/react-stripe-js"
import Button from "@components/buttons/Button"
import ItemCard from "./ItemCard"
import {
  userAddressList,
  getCardList,
  setLoyaltyClientSuccess,
} from "@store/user/UserActions"
import { cartCounter, getDiscountedPrice } from "@store/cart/cartHelpers"
import VenueCard from "./VenueCard"
import RadioGroupCard from "@components/radioGroupCard/RadioGroupCard"
import {
  applyDiscountVoucher,
  getRedsysParam,
  setCartPendingPayment,
  setShowStockModal,
  submitOrder,
  updateCartItems,
  validateStock,
} from "@store/cart/cartActions"
import { CartType, ItemOrderMenu } from "@models/Cart"
import { setSelectedAddress, selectCard } from "@store/cart/cartActions"
import { setSelectedOption } from "@store/venues/VenueActions"
import { Menu } from "@models/Menu"
import { clientSetting } from "config"
import RadioPayByCard from "@modules/address/components/payByCard"
import { useDebounce } from "@helpers/useDebounce"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import {
  getMinimunOrderPrice,
  venueOrderType,
  priceWithService,
  venueIsBusy,
  handleRouting,
  goToBasketPayment,
  getVenueProperties,
  checkPosTableStatus,
  getExistingLoyaltyName,
  validateEmail,
  checkClosedKitchenTime,
  checkClosedKitchen,
  checkAvailableTimeCheckout,
  arrangeCartForPromotions,
  checkAvailableTimeCheckoutV2,
  getTaxes,
  calculatePrices,
} from "@helpers/utilsHelper"
import { toast } from "react-toastify"
import TotalCard from "./components/TotalCard"
import { useBrand } from "@hooks/useBrand"
import { TextInput } from "@components/textInput/TextInput"
import { theme } from "config/theme"
import { LoyaltyClientState } from "@models/Location"
import ScheduleOrderModal from "@components/modals/ScheduleOrderModal"
import { HideMenuModal } from "@components/modals/HideMenuModal"
import { getClientDetail } from "@store/venues/VenueActions"
import { setProgrammedOrderDate } from "@store/orderFromTable/OrderFromTableActions"
import StockModal from "@components/StockModal/StockModal"
import { validateCartItemsAvailability } from "@store/cart/cartActions"

const MobileCheckoutForm = ({ venueId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { primaryColor, secondaryColor, thirdColor } = useBrand()
  const stripe = useStripe()
  const {
    discountApplicable,
    discountAmount,
    discountType,
    discountCode,
    addMoreItems,
    discountQuantity,
    invalidDiscountCode,
    alreadyUsed,
    showStockModal,
    loading,
  } = useSelector(({ cart }: RootState) => cart)
  const cart = useSelector(({ cart }: RootState) => cart.cart)
  let cartItems = arrangeCartForPromotions({
    cartItems: cart.items,
  })
  const taxDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const { taxId, venueService } = taxDetail || {}
  const { taxType, taxPercentage, totalTax } = getTaxes(taxId)
  const [closeKitchenCheck, setCloseKitchenCheck] =
    React.useState<boolean>(false)
  const { totalPrice, promotionPrice } = cartCounter(cartItems)
  const menuList: Menu = useSelector(({ menu }: RootState) => menu.menuList)
  const { addresses, cards, user } = useSelector(({ user }: RootState) => user)
  const loyaltyClientState = useSelector(
    ({ user }: RootState) => user.loyaltyClient,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const cartLoading = useSelector(({ cart }: RootState) => cart.loading)
  const currentLoyaltyEmail =
    loyaltyClientState && loyaltyClientState.email
      ? loyaltyClientState.email
      : null

  const orderFromTable = useSelector(
    ({ orderFromTable }: any) => orderFromTable.orderFromTable,
  )
  const selectedOption = useSelector(({ venues }: any) => venues.selectedOption)
  const cartDetail = useSelector(({ cart }: any) => cart)
  const {
    address: selectedAddress,
    selectedCard,
    discountVoucherId,
    isVoucherMatchWithOrderType = true,
  } = cartDetail
  const deliveryPrice = Number(selectedAddress.minDeliveryPrice)
  const { programmedOrderDate = "" } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const settings = useSelector(
    ({ venues }: RootState) => venues.restaurant.settings,
  )
  const hasRedsys = settings?.redsys ? settings?.redsys : false
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const { visibilityTime, visibilityRanges, visibility } = useSelector(
    ({ menu }: RootState) => menu.menuList,
  )

  const _minimumOrderPrice = Number(
    cartDetail && cartDetail.address && cartDetail.address.minOrderPrice
      ? cartDetail.address.minOrderPrice
      : 0,
  )

  const [comments, setComments] = useState("")
  const [selectedValue, setSelectedValue] = useState("withCard")
  const [openPhoneModal, setPhoneModal] = useState(false)
  const [minOrderPrice, setMinOrderPrice] = useState(0)
  const [discountVoucherCode, setDiscountVoucherCode] = useState("")
  const [total, setTotalPrice] = useState(parseInt(totalPrice))
  const [subTotal, setSubTotal] = useState(total)
  const [cartPrice, setCartPrice] = useState(0)
  const [loyaltyClient, setLoyaltyClient] = useState<LoyaltyClientState>({
    email: "",
    name: "",
  })
  const [openDateOrderModal, setOpenDateOrderModal] = useState(false)
  const [changeProgrammedTime, setChangeProgrammedTime] =
    useState<boolean>(false)
  const [timeUnavailable, setTimeUnavailable] = useState<boolean>(false)
  const { languageStrings } = useLanguageHelper()
  const { orderPrice = 0, serviceFee = 0 } = priceWithService(
    total,
    venueDetail && venueDetail.venueService ? venueDetail.venueService : 0,
    venueDetail,
  )
  const validName = loyaltyClient.name.length !== 0
  const isLoyaltyClient = venueDetail && venueDetail.isLoyaltyEnabled
  const validate =
    venueDetail && venueDetail.isLoyaltyEnabled
      ? validateEmail(loyaltyClient.email)
      : false
  const isScheduledOrder =
    programmedOrderDate && venueDetail && venueDetail.isScheduleOrdersEnabled
  const isClosedKitchen =
    (restaurant?.closeKitchenTime || closeKitchenCheck) && !programmedOrderDate
      ? checkClosedKitchenTime(restaurant?.closeKitchenTime)
      : false
  const { isVirtualBasket, autoServices } = venueOrderType(venueDetail)
  const { venueHasDiscountVoucher = false } = getVenueProperties(venueDetail)
  const typography = primaryColor
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    const payByCash = restaurant.settings && restaurant.settings.payByCash
    const payByCard = restaurant.settings && restaurant.settings.payByCard

    if (payByCash && !payByCard) {
      setSelectedValue("withCash")
    }
    if (!payByCash && payByCard) {
      setSelectedValue("withCard")
    }
  }, [restaurant])
  useEffect(() => {
    if (clientSetting.isCheck) {
      dispatch(setSelectedOption(CartType.ORDER_FROM_TABLE))
    }
  }, [])
  useEffect(() => {
    if (clientSetting.getCardList) {
      dispatch(getCardList())
    }
    if (discountVoucherId) {
      dispatch(applyDiscountVoucher(discountVoucherCode, selectedOption))
    }
  }, [])
  useEffect(() => {
    if (discountCode && !discountVoucherCode) {
      setDiscountVoucherCode(discountCode)
    }
  }, [discountCode])
  useEffect(() => {
    if (Object.keys(venueDetail) && venueDetail && venueDetail._id) {
      if (clientSetting.getCardList) {
        dispatch(
          userAddressList(localStorage.getItem("userId"), venueDetail._id),
        )
      }
    }
  }, [venueDetail])
  useEffect(() => {
    const _minimum = getMinimunOrderPrice(venueDetail, _minimumOrderPrice)
    setMinOrderPrice(_minimum)
  }, [venueDetail])

  useEffect(() => {
    if (
      selectedAddress &&
      Object.keys(selectedAddress).length < 1 &&
      addresses &&
      addresses.length > 0 &&
      selectedOption == "2"
    ) {
      const address = addresses.find((address) => {
        if (
          address &&
          Object.keys(address).length > 1 &&
          address.hasOwnProperty("isValidAddress") &&
          address["isValidAddress"] == true
        ) {
          return address
        }
      })
      address && dispatch(setSelectedAddress(address))
    }
  }, [addresses])
  useEffect(() => {
    if (cartItems) {
      let { totalPrice } = cartCounter(cartItems)
      const __totalPrice =
        totalPrice && Number(totalPrice) ? Number(totalPrice) : 0

      setCartPrice(__totalPrice)
      let discountedPrice = getDiscountedPrice(
        totalPrice,
        discountApplicable,
        discountType,
        discountAmount,
      )

      let grandTotal = Number(discountedPrice.toFixed(2))

      if (discountQuantity && discountQuantity > Number(totalPrice)) {
        dispatch(applyDiscountVoucher(discountVoucherCode, selectedOption))
      }
      setTotalPrice(grandTotal)
      setSubTotal(Number(totalPrice))
    }
  }, [cartItems, cart.deliveryPrice, discountApplicable])
  useEffect(() => {
    if (
      selectedCard == "" &&
      cards &&
      cards.length > 0 &&
      cards[0].id &&
      selectedValue == "withCard"
    )
      dispatch(selectCard(cards[0].id))
  }, [selectedCard, cards])

  useEffect(() => {
    const permalink = localStorage.getItem("clientPermalink")
    if (permalink) {
      dispatch(getClientDetail(permalink))
    }
    if (discountCode) {
      dispatch(applyDiscountVoucher(discountVoucherCode))
    }
  }, [])

  useEffect(() => {
    if (restaurant && restaurant?.closeKitchenTime) {
      setCloseKitchenCheck(true)
    }
  }, [restaurant])

  useEffect(() => {
    if (programmedOrderDate && visibilityRanges?.length) {
      const validDate = checkAvailableTimeCheckoutV2(
        programmedOrderDate,
        visibilityRanges,
        visibility,
        venueDetail?.numberOfFutureDays,
      )
      if (validDate === "unavailable") {
        return setTimeUnavailable(true)
      }
      if (
        moment(validDate)?.toISOString() !==
        moment(programmedOrderDate)?.toISOString()
      ) {
        dispatch(setProgrammedOrderDate(validDate))
        setChangeProgrammedTime(true)
      }
    }
  }, [dispatch, visibilityRanges, visibility])

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value)
    if (e.target.value == "withCash") {
      dispatch(selectCard(""))
    }
    if (
      e.target.value == "withCard" &&
      selectedCard == "" &&
      cards &&
      cards.length > 0 &&
      cards[0].id
    ) {
      dispatch(selectCard(cards[0].id))
    }
  }
  const getTotalOrderPrice = () => {
    let totalPrice = 0
    let discountVoucherAmount = 0
    let discountedPrice = 0
    cartItems.map((item: ItemOrderMenu) => {
      totalPrice =
        totalPrice +
        Number(item.price) * (item.quantity - (item.discountedQuantity || 0))
    })
    if (discountType === "percentage") {
      if (discountAmount === 100) {
        return { totalPrice, discountVoucherAmount: totalPrice }
      } else {
        return {
          totalPrice,
          discountVoucherAmount: (totalPrice * discountAmount) / 100,
        }
      }
    }
    if (discountAmount && Number(discountAmount)) {
      const _discountedPrice = getDiscountedPrice(
        totalPrice,
        discountApplicable,
        discountType,
        Number(discountAmount),
      )

      if (_discountedPrice && Number(_discountedPrice)) {
        discountedPrice = _discountedPrice
        discountVoucherAmount = Number(
          (totalPrice - Number(Number(_discountedPrice).toFixed(2))).toFixed(2),
        )
        totalPrice = Number(Number(_discountedPrice).toFixed(2))
      }
    }
    if (serviceFee) totalPrice += serviceFee
    return {
      totalPrice: totalPrice < discountVoucherAmount ? 0 : totalPrice,
      discountVoucherAmount,
      discountedPrice,
    }
  }
  const getDiscountAmount = () => {
    let _discountAmount = 0
    if (discountType === "percentage") {
      _discountAmount = (subTotal * Number(discountAmount)) / 100
    } else {
      _discountAmount = Number(discountAmount)
    }
    return Number(
      parseFloat(_discountAmount.toFixed(2)) < subTotal
        ? _discountAmount.toFixed(2)
        : subTotal,
    )
  }
  const getPrices = () => {
    const _discountAmount = getDiscountAmount()
    const prices = calculatePrices(
      promotionPrice,
      totalTax,
      venueService,
      taxDetail,
      _discountAmount,
      cartPrice,
    )
    return prices
  }
  const finalPriceWithDelivery = (
    getPrices()._total +
    deliveryPrice -
    getDiscountAmount()
  ).toFixed(2)
  const finalPrice = (getPrices()._total - getDiscountAmount()).toFixed(2)

  const finalAmount =
    deliveryPrice > 0 && selectedOption && selectedOption == "2"
      ? finalPriceWithDelivery
      : finalPrice
  const handleOrderSubmit = async () => {
    if (
      selectedOption === CartType.ORDER_FROM_TABLE &&
      orderFromTable &&
      !orderFromTable?._id
    ) {
      toast.error(
        languageStrings
          ? languageStrings["NO_TABLE_ID_ERROR"]
          : "Tuvimos un problema identificando tu mesa. Escanea el Codigo QR otra vez",
      )
      return
    }

    const { totalPrice, discountVoucherAmount = 0 } = getTotalOrderPrice()
    let deliveryPrice = 0
    if (
      selectedOption === CartType.DELIVERY &&
      selectedAddress &&
      selectedAddress.minDeliveryPrice
    ) {
      deliveryPrice = Number(selectedAddress.minDeliveryPrice)
    }
    let payload = {
      clientId:
        orderFromTable && orderFromTable.clientId
          ? orderFromTable.clientId
          : null,
      userId: user._id,
      price: Number(subTotal.toFixed(2)),
      discountVoucherAmount,
      items: cartItems,
      type: selectedOption,
      addressId: selectedAddress._id,
      venueId:
        orderFromTable && orderFromTable.venueId
          ? orderFromTable.venueId
          : null,
      deliveryPrice: selectedOption == "2" ? deliveryPrice : 0,
      orderTime: "",
      orderTable: orderFromTable._id,
      comments,
      card: selectedValue == "withCard" ? selectedCard : "",
      payByCard: selectedValue == "withCard" ? true : false,
      discountVoucherId,
      payByRedsys: hasRedsys && selectedValue == "withCard" ? true : false,
      isFromSinqro: menuList.isSinqro !== undefined && menuList.isSinqro,
      loyaltyClient: loyaltyClient,
      isScheduledOrder: false,
      scheduledDate: "",
      menuId: menuList._id,
    }

    if (
      !payload.clientId ||
      !payload.userId ||
      !payload.orderTable ||
      !payload.venueId
    ) {
      toast.error(languageStrings["NO_TABLE_ID_ERROR"])
      return
    }
    if (canBeSubmit()) {
      if (!user.phone || user.phone == "") {
        setPhoneModal(true)
        return
      }
      if (selectedOption === CartType.ORDER_FROM_TABLE) {
        if (
          discountVoucherAmount > 0 &&
          discountVoucherId &&
          Number(finalAmount) <= 0 &&
          !autoServices
        ) {
          payload.payByCard = false
          payload.payByRedsys = false
          payload.card = ""
          payload.price = subTotal
          dispatch(submitOrder(payload, stripe, autoServices, languageStrings))
          return
        } else if (isVirtualBasket) {
          payload.payByCard = false
          payload.payByRedsys = false
          payload.card = ""
          dispatch(submitOrder(payload, stripe, autoServices, languageStrings))
          return
        } else if (!isVirtualBasket) {
          const isVenuBusy = await venueIsBusy(venueId)
          if (isVenuBusy) {
            toast.error(languageStrings["BUSY_VENUE"])
            return
          }
          if (isLoyaltyClient) {
            dispatch(setLoyaltyClientSuccess(loyaltyClient))
          }
          if (autoServices) {
            const autoserviceData = {
              payload,
              totalPrice,
              tableId: orderFromTable._id,
              venueId,
              amount: finalAmount,
            }
            if (isScheduledOrder) {
              payload.isScheduledOrder = true
              payload.scheduledDate = programmedOrderDate
            }
            const venueDetails =
              venueDetail && venueDetail.notifications
                ? venueDetail.notifications
                : []
            const { routeData } = handleRouting(autoserviceData, venueDetails)
            history.push(routeData)
          } else {
            const __payload = {
              payload,
              tableId: orderFromTable._id,
              amount: finalAmount,
              venueId,
              total: subTotal,
            }
            goToBasketPayment(history, __payload)
          }
          return
        }

        if (payload.payByRedsys) {
          dispatch(setCartPendingPayment(payload))
          dispatch(getRedsysParam(payload))
        } else {
          dispatch(submitOrder(payload, stripe, autoServices, languageStrings))
        }
      }
    }
  }
  const handleOrderSubmitV2 = () => {
    if (
      selectedOption === CartType.ORDER_FROM_TABLE &&
      orderFromTable &&
      !orderFromTable._id
    ) {
      toast.error(
        languageStrings
          ? languageStrings["NO_TABLE_ID_ERROR"]
          : "Tuvimos un problema identificando tu mesa. Escanea el Codigo QR otra vez",
      )
      return
    }
    const { totalPrice, discountVoucherAmount = 0 } = getTotalOrderPrice()
    let payload = {
      clientId:
        orderFromTable && orderFromTable.clientId
          ? orderFromTable.clientId
          : null,
      userId: user._id,
      price: subTotal,
      discountVoucherAmount,
      items: cartItems,
      type: selectedOption,
      addressId: selectedAddress._id,
      venueId:
        orderFromTable && orderFromTable.venueId
          ? orderFromTable.venueId
          : null,
      deliveryPrice: 0,
      orderTime: "",
      orderTable: orderFromTable._id,
      comments,
      card: selectedValue == "withCard" ? selectedCard : "",
      payByCard: selectedValue == "withCard" ? true : false,
      discountVoucherId,
      payByRedsys: false,
      isFromSinqro: false,
      loyaltyClient: loyaltyClient,
      menuId: menuList._id,
    }

    if (
      !payload.clientId ||
      !payload.userId ||
      !payload.orderTable ||
      !payload.venueId
    ) {
      toast.error(languageStrings["NO_TABLE_ID_ERROR"])
      return
    }

    if (isLoyaltyClient) {
      dispatch(setLoyaltyClientSuccess(loyaltyClient))
    }

    if (canBeSubmit()) {
      payload.payByCard = false
      payload.payByRedsys = false
      if (totalPrice < 0 && discountVoucherAmount > 0 && discountVoucherId)
        payload.price = 0

      dispatch(
        submitOrder(payload, stripe, venueDetail.autoServices, languageStrings),
      )
      return
    }
  }
  const canBeSubmit = () => {
    const { totalPrice } = getTotalOrderPrice()
    if (
      venueDetail.orderType === "1" &&
      totalPrice === 0 &&
      cartItems?.length > 0 &&
      venueDetail?.byPassPayment
    ) {
      return true
    }

    const _isNegative = total + serviceFee <= 0 && !!discountVoucherId
    if (timeUnavailable) return false
    if (total <= 0 && !discountVoucherId) {
      return false
    }
    if (isLoyaltyClient && !currentLoyaltyEmail) {
      if (!validate) return false
      if (!validName) return false
    }

    if (!_isNegative && orderPrice < minOrderPrice) return false
    if (selectedOption === CartType.ORDER_FROM_TABLE && isVirtualBasket) {
      return true
    }
    if (isClosedKitchen) return false
    return true
  }
  const showPaymentMethods = () => {
    if (selectedOption === CartType.ORDER_FROM_TABLE && isVirtualBasket) {
      return false
    } else if (
      selectedOption === CartType.ORDER_FROM_TABLE &&
      !isVirtualBasket &&
      !clientSetting.showPayByCard
    ) {
      return false
    }
    return clientSetting.showPayByCard
  }

  const checkMinOrderPrice = () => {
    const _isNegative = total + serviceFee <= 0 && !!discountVoucherId
    if (cartItems?.length > 0 && orderPrice < minOrderPrice && !_isNegative) {
      return minOrderPrice
    } else {
      return 0
    }
  }

  const checkOrderMethod = () => {
    const integrationType =
      (restaurant?.settings && restaurant?.settings?.integrationType) || false
    dispatch(
      validateCartItemsAvailability(
        cartItems,
        orderFromTable?.clientId,
        programmedOrderDate,
        menuList._id,
        ({ isAvailable, message }) => {
          if (!isAvailable) {
            toast.error(message)
            return
          }

          if (
            (integrationType && integrationType != "revo") ||
            !restaurant.stockEnabled
          ) {
            proceedWithoutStockError()
          } else {
            dispatch(
              validateStock(
                cartItems,
                orderFromTable?.clientId,
                proceedWithoutStockError,
              ),
            )
          }
        },
      ),
    )
  }
  const isBtnDisabled =
    !canBeSubmit() ||
    cartItems?.length <= 0 ||
    loading ||
    isLoading ||
    cartLoading

  const handleLoyaltyInputs = async (e) => {
    const { name, value } = e.target
    setLoyaltyClient((prevData) => ({
      ...prevData,
      [name]: value,
    }))
    if (name === "email" && value.length) {
      debouncedLoyaltyInputs(value)
    }
  }

  const debouncedLoyaltyInputs = useDebounce(async (email) => {
    const clientName = await getExistingLoyaltyName(email)
    if (clientName && clientName !== loyaltyClient.name) {
      setLoyaltyClient((prevData) => ({
        ...prevData,
        name: clientName,
      }))
    }
  }, 800)

  const handleScheduleOrder = () => {
    setOpenDateOrderModal(!openDateOrderModal)
  }
  const handleOpenMenu = () => {
    const permalink = localStorage.getItem("clientPermalink")
    history.push(
      `/${permalink}/menu/${orderFromTable.venueId}/${menuList._id}/${orderFromTable._id}`,
    )
    dispatch(setShowStockModal(false))
  }
  const handleStockModal = (v) => {
    dispatch(setShowStockModal(v))
  }
  const proceedWithoutStockError = async () => {
    const isClosed = await checkClosedKitchen(restaurant._id)
    const posTable = await checkPosTableStatus(orderFromTable._id)
    if (venueDetail.orderType === "1" || venueDetail.orderType === "4") {
      if (!menuList._id) {
        toast.error(languageStrings["NO_MENU_ID_ERROR"])
        return
      }
      setIsLoading(true)

      if (isClosed) {
        setIsLoading(false)
        return setCloseKitchenCheck(true)
      }
      if (!isVirtualBasket) {
        setIsLoading(false)
        if (posTable === true) {
          toast.error(languageStrings["BUSY_TABLE"])
          return null
        }
      }

      if (venueDetail && venueDetail.orderType === "1") {
        setIsLoading(false)
        handleOrderSubmitV2()
        return
      }
      setIsLoading(false)
      handleOrderSubmit()
    } else {
      if (isClosed) {
        return setCloseKitchenCheck(true)
      }
      if (!isVirtualBasket) {
        if (posTable === true) {
          toast.error(languageStrings["BUSY_TABLE"])
          return null
        }
      }

      if (venueDetail && venueDetail.orderType === "1") {
        handleOrderSubmitV2()
        return
      }
      handleOrderSubmit()
    }
  }
  const onContinue = () => {
    const updatedCartItems = cartItems.filter((item) => !item.stockError)
    cartItems = updatedCartItems
    dispatch(updateCartItems(updatedCartItems))
    handleStockModal(false)
    if (updatedCartItems.length > 0) {
      proceedWithoutStockError()
    } else {
      toast.error(languageStrings["ANOTHER_PRODUCT_ERROR"])
      history.goBack()
    }
  }

  const totalPriceWithDiscount =
    discountAmount >= Number(totalPrice)
      ? 0
      : Number(totalPrice) - discountAmount

  return (
    <>
      {/* <UserDetailForm /> */}
      {isLoyaltyClient && !currentLoyaltyEmail && (
        <div className="flex flex-col justify-center items-center px-6 w-full self-center mb-6">
          <TextInput
            style={{
              width: "97%",
              height: "60px",
              minHeight: "60px",
              marginTop: "10px",
            }}
            label={languageStrings["SIGNUP.EMAIL"]}
            inputName="email"
            value={loyaltyClient.email}
            onValueChange={handleLoyaltyInputs}
          />
          {!validate && loyaltyClient.email.length !== 0 && (
            <div className="flex flex-col px-12 -ml-4 w-full items-start self-center md:w-6/12">
              <p className="text-red-light text-left -ml-8">
                {languageStrings["INVALID_EMAIL_ERROR"]}
              </p>
            </div>
          )}
          <TextInput
            style={{
              width: "97%",
              height: "60px",
              minHeight: "60px",
              marginTop: "10px",
            }}
            label={languageStrings["SIGNUP.NAME"]}
            inputName="name"
            value={loyaltyClient.name}
            onValueChange={handleLoyaltyInputs}
          />
        </div>
      )}
      <div className="flex w-full pl-8 justify-between">
        {orderFromTable &&
          selectedOption === CartType.ORDER_FROM_TABLE &&
          orderFromTable._id && (
            <VenueCard
              icon={
                <div
                  className="flex justify-center items-center ml-4 mr-6 rounded-full"
                  style={{
                    backgroundColor: thirdColor,
                    width: "2.3rem",
                    height: "2.3rem",
                  }}
                >
                  <TableIcon
                    fontSize="small"
                    style={{ color: secondaryColor }}
                  />
                </div>
              }
              venueName={orderFromTable.title}
              typography={typography}
            />
          )}
        <TotalCard total={Number(totalPriceWithDiscount).toFixed(2)} />
        <div className="px-6" />
      </div>
      {showPaymentMethods() ? (
        <RadioGroupCard
          typography={typography}
          selectedValue={selectedValue}
          onRadioChange={handleRadioChange}
        />
      ) : clientSetting.showPayByCard ? (
        <RadioPayByCard
          typography={typography}
          selectedValue={selectedValue}
          onRadioChange={handleRadioChange}
        />
      ) : null}
      <TextInput
        label={languageStrings["GENERAL.COMMENTS"]}
        style={{ width: "91%", alignSelf: "center", marginTop: "1.2rem" }}
        onValueChange={(v) => {
          setComments(v.currentTarget.value)
        }}
        value={comments}
      />
      {cartItems && cartItems.length > 0 && (
        <ItemCard
          items={cartItems}
          deliveryPrice={Number(selectedAddress.minDeliveryPrice)}
          selectedOption={selectedOption}
          setDiscountVoucherCode={setDiscountVoucherCode}
          discountVoucherCode={discountVoucherCode}
          subTotal={subTotal}
          total={total}
          invalidDiscountCode={invalidDiscountCode}
          discountQuantity={discountQuantity}
          alreadyUsed={alreadyUsed}
          addMoreItems={addMoreItems}
          discountAmount={discountAmount}
          discountType={discountType}
          discountApplicable={discountApplicable}
          hasDiscountVoucher={venueHasDiscountVoucher}
          isVoucherMatchWithOrderType={isVoucherMatchWithOrderType}
          minOrderPrice={checkMinOrderPrice()}
          cartPrice={cartPrice}
          setOpenProgramModal={setOpenDateOrderModal}
          showChangedOrderTime={changeProgrammedTime}
          promotionPrice={promotionPrice || total}
        />
      )}
      <Button
        title={languageStrings["GENERAL_PEDIR"]}
        backgroundColor={typography}
        txtColor={isBtnDisabled ? theme.main.black : theme.main.primary}
        classes={
          "flex items-center justify-center self-center w-11/12 h-32 text-white py-2 px-4 border border-transparent cursor-pointer outline-none fixed"
        }
        style={{
          borderRadius: "25px",
          bottom: 10,
        }}
        onClick={checkOrderMethod}
        disabled={isBtnDisabled}
      />
      <ScheduleOrderModal
        open={openDateOrderModal}
        close={handleScheduleOrder}
        confirm={handleScheduleOrder}
      />
      <HideMenuModal
        open={closeKitchenCheck}
        onClose={() => {
          setCloseKitchenCheck(false)
        }}
        onViewMenu={() => {
          history.goBack()
        }}
        isClosedKitchen={closeKitchenCheck}
        isMenuVisible={true}
        isCheckoutScreen={true}
      />
      {showStockModal && (
        <StockModal
          onClose={() => {
            handleStockModal(false)
          }}
          onOpenMenu={handleOpenMenu}
          onContinue={onContinue}
        />
      )}
    </>
  )
}

export default MobileCheckoutForm
