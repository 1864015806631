import { useLanguageHelper } from "@helpers/LanguageHelper"
import {
  maskCardNumber,
  PaymentMethods,
  PaymentType,
} from "@helpers/utilsHelper"
import { useBrand } from "@hooks/useBrand"
import React from "react"
import { useSelector } from "react-redux"
import FreedomPay from "./Freedompay"
import SquareForm from "./SquareForm"
import CreditCardInput from "react-credit-card-input"
import { Box } from "@material-ui/core"
import { Body13 } from "@components/Text/Text"
import { theme } from "config/theme"
import { PaymentMethodState } from "@models/Location"
import Redsys from "./Redsys"

interface Props {
  paymentMethod: PaymentMethodState
  autoServices?: boolean
  state: {
    cvc: string
    expiry: string
    name: string
    number: string
    spaces_number: string
    month: string
    year: string
  }
  handleCardChange: (event: any, key: string) => any
  showSaveCard?: boolean
  check?: boolean
  setCheck?: (val: boolean) => any
  setCard?: any
  setPayments?: any
  setFreedompayLoading?: (val: boolean) => any
  amount?: string
  isSetNewCard?: boolean
  merchantOrder?: string
}

const CardInputs = ({
  state,
  paymentMethod,
  handleCardChange,
  autoServices,
  showSaveCard,
  check,
  setCheck,
  setCard,
  setPayments,
  setFreedompayLoading,
  amount,
  isSetNewCard,
  merchantOrder,
}: Props) => {
  const { languageStrings } = useLanguageHelper()
  const userCards = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable.userCards,
  )
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const data = userCards && userCards.length ? userCards : []
  const { selectedPayment } = paymentMethod
  const { primaryColor } = useBrand()

  return (
    <div className="flex flex-col w-full my-8 px-8">
      {venueDetail?.paymentProvider === PaymentMethods.REDSYS ? (
        <Redsys merchantOrder={merchantOrder} amount={amount} />
      ) : venueDetail?.paymentProvider === PaymentMethods.FREEDOMPAY ? (
        <FreedomPay
          setFreedompayLoading={setFreedompayLoading}
          amount={amount}
        />
      ) : venueDetail?.paymentProvider === PaymentMethods.SQUARE &&
        venueDetail?.squareAccessToken ? (
        <SquareForm setCard={setCard} setPayments={setPayments} />
      ) : (
        <CreditCardInput
          fieldStyle={{
            width: "100%",
            height: "60px",
            minHeight: "60px",
            border:
              selectedPayment === PaymentType.CARD
                ? `2px solid ${primaryColor}`
                : `2px solid ${theme.main.black}`,
            borderRadius: "10px",
          }}
          cardNumberInputProps={{
            value: isSetNewCard
              ? maskCardNumber(state.spaces_number)
              : state.spaces_number,
            onChange: (event) => {
              const value = event.target.value
              handleCardChange(value, "number")
            },
            disabled: isSetNewCard,
          }}
          cardExpiryInputProps={{
            value: state.expiry,
            onChange: (event) => {
              const value = event.target.value
              handleCardChange(value, "expiry")
            },
          }}
          cardCVCInputProps={{
            value: state.cvc,
            onChange: (event) => {
              const value = event.target.value

              handleCardChange(value, "cvc")
            },
          }}
          customTextLabels={{
            invalidCardNumber: languageStrings["INVALID_CARD_NUMBER"],
            expiryError: {
              invalidExpiryDate: languageStrings["INVALID_EXPIRY_DATE"],
              monthOutOfRange: languageStrings["MONTH_RANGE"],
              yearOutOfRange: languageStrings["YEAR_RANGE"],
              dateOutOfRange: languageStrings["DATE_RANGE"],
            },
            invalidCvc: languageStrings["INVALID_CVV"],
            invalidZipCode: "El código postal es inválido",
            cardNumberPlaceholder: languageStrings["VIRTUAL_BASKET.NUMBER"],
            expiryPlaceholder: "MM/YY",
            cvcPlaceholder: languageStrings["VIRTUAL_BASKET.CVC"],
            zipPlaceholder: "C.P.",
          }}
          fieldClassName="input"
        />
      )}
      {((!isSetNewCard && showSaveCard) || (autoServices && !data.length)) &&
      !venueDetail?.squareAccessToken &&
      venueDetail?.paymentProvider !== PaymentMethods.FREEDOMPAY ? (
        <Box
          className={
            "flex flex-row items-center mt-2 md:mt-4 w-5/6 md:w-4/5 md:mr-40"
          }
        >
          <input
            style={{ backgroundColor: primaryColor }}
            className={"w-12 h-12 border border-white border-solid mr-6"}
            type="checkbox"
            onChange={(e) => {
              setCheck?.(!check)
            }}
          />
          <Body13>{languageStrings["SECURE_STORE_CARD"]}</Body13>
        </Box>
      ) : null}
    </div>
  )
}

export default CardInputs
