import React, { useEffect } from "react"
import { axiosInstance } from "@api/axios"
import { toast } from "react-toastify"
import { SocialPaymentEnum } from "@helpers/utilsHelper"

const FreedomPaySocial = ({
  amount,
  paymentType = SocialPaymentEnum.GOOGLE,
}) => {
  const socialPaymentRef = React.useRef(null)

  const getPaymentFrame = async () => {
    const cardPayment = socialPaymentRef.current
    cardPayment.innerHTML = ""
    try {
      const response = await axiosInstance.get(
        `freedompay/iframe?paymentType=${paymentType}&amount=${amount}`,
      )
      cardPayment.innerHTML += response.data?.data?.iframe
      const sessionKeyHeader = response?.data?.data?.sessionKey
      if (paymentType === SocialPaymentEnum.GOOGLE) {
        localStorage.setItem("freedomPaymentGoogleSessionKey", sessionKeyHeader)
      } else if (paymentType === "ApplePay") {
        localStorage.setItem("freedomPaymentAppleSessionKey", sessionKeyHeader)
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("401 Unauthorized: Credentials are invalid.")
      } else if (error.response?.data?.Message) {
        toast.error(JSON.parse(error.response.data.Message)[0])
      } else {
        toast.error("An unknown error occurred. Please try again later.")
      }
    }
  }

  useEffect(() => {
    getPaymentFrame()
  }, [])

  return <div ref={socialPaymentRef} />
}

export default FreedomPaySocial
